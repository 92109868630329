import React from 'react';
import { useSetRecoilState } from 'recoil';
import { overlayProppress } from '../../atoms/OverlayProgress';
import { flashMessageSuccess, flashMessageError } from '../../atoms/FlashMessage';
import { zshDialog } from '../../atoms/ZshDialog';
import { RouteComponentProps } from 'react-router-dom';
import DataTable, { DataTableRowsProps, DataTableBtnGroupProps, DataTableSearchParamsProps } from '../../components/View/DataTable';
import { searchFilterProps } from '../../components/View/SearchFilter';
import * as _data_actions from '../../helper/dataActions';

function UsersIndex({ history, location }: RouteComponentProps) {

  const setOverlayProppress = useSetRecoilState(overlayProppress);
  const setFlashMessageSuccess = useSetRecoilState(flashMessageSuccess);
  const setFlashMessageError = useSetRecoilState(flashMessageError);
  const setZshDialog = useSetRecoilState(zshDialog);

  const clickCell = (callbackParams: any) => {
    history.push(location.pathname + '/' + callbackParams.data.id);
  }

  const handleToAdd = () => {
    history.push('/users/add');
  }

  const confirmDelete = (params: any) => {
    if (params.selected.length > 0) {
      _data_actions.showConfirmDialog({
        dialogType: 'delete',
        funcAgree: () => {
          handleDelete(params);
        },
        setZshDialog
      });
    } else {
      setFlashMessageError('項目を選択してください');
    }
  }

  const handleDelete = (params: any) => {
    _data_actions.deleteData({
      deleteUrl: 'users/delete/',
      selected: params.selected,
      getData: params.getData,
      setOverlayProppress,
      setFlashMessageSuccess,
      setFlashMessageError
    });
  }

  const rows: DataTableRowsProps[] = [
    {
      label: 'ID',
      value: 'id',
      sortField: 'Users.id',
      align: 'left',
    },
    {
      label: 'ユーザー名',
      value: 'username_display',
      sortField: 'username',
      align: 'left'
    },
    {
      label: '氏名',
      value: 'full_name',
      align: 'left',
    },
    {
      label: 'ユーザー区分',
      value: 'authority_display',
      sortField: 'authority',
      align: 'left',
    },
    {
      label: 'ステータス',
      value: 'status_display',
      align: 'left',
    },
    {
      label: '登録日時',
      value: 'created',
      filter: 'YMDHm',
      sortField: 'Users.created',
      align: 'left',
    },
    {
      label: '更新日時',
      value: 'modified',
      filter: 'YMDHm',
      sortField: 'Users.modified',
      align: 'left',
    },
  ];

  const searchParams: DataTableSearchParamsProps[] = [
    {
      title: 'ユーザー名',
      text: '部分一致',
    },
    {
      title: '氏名',
      text: '部分一致',
    },
  ];

  const searchProps: searchFilterProps[] = [
    {
      key: 'search'
    },
  ];

  const btnGroup: DataTableBtnGroupProps[] = [
    {
      title: '新規追加',
      func: handleToAdd,
      color: 'primary',
      role: {
        name: 'Users',
        action: 'add',
      },
    },
    {
      title: '削除',
      func: confirmDelete,
      color: 'default',
      role: {
        name: 'Users',
        action: 'delete',
      },
    },
  ];

  return (
    <div className="content-1">
      <DataTable
        rows={rows}
        checkbox={true}
        dataKey='users'
        jsonPath='users/index/index.json'
        urlQuery={true}
        parentProps={{ history, location }}
        handleClickCell={clickCell}
        searchParams={searchParams}
        searchProps={searchProps}
        btnGroup={btnGroup}
      />
    </div>
  )
}

export default UsersIndex;