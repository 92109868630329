import React from 'react';
import { WithContext as ReactTags } from 'react-tag-input';
import FormControl from '@material-ui/core/FormControl';
import '../../scss/ReactTags.scss';

type ZshTagInput = {
  inputName: string;
  title?: string;
  placeholder?: string;
  note?: string;
  formData: any;
  setFormData: any;
}

function ZshTagInput({
  inputName,
  title,
  placeholder = '追加',
  note = '複数設定する場合は、カンマ区切りで入力してください。',
  formData,
  setFormData
}: ZshTagInput) {

  const KeyCodes = {
    comma: 188,
    enter: 13,
  };

  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  const setTags = () => {
    if (formData[inputName] !== undefined) {
      const formTags = formData[inputName].split(',');
      const tags: any[] = [];
      formTags.forEach((val: string) => {
        tags.push({ id: val, text: val });
      });
      return tags;
    } else {
      return [];
    }
  }

  const setValues = (tags:any) => {
    const results: any = [];
    tags.forEach((val: any) => {
      results.push(val.text);
    });
    return results.join(',');
  }

  const handleDelete = (i: number) => {
    const tags = setTags();
    setFormData({
      ...formData,
      [inputName]: setValues(tags.filter((tag: any, index: number) => index !== i))
    });
  }

  const handleAddition = (tag: any) => {
    const tags = setTags();
    const newTags = [...tags, tag];
    setFormData({
      ...formData,
      [inputName]: setValues(newTags)
    });
  }

  const handleDrag = (tag: any, currPos: number, newPos: number) => {
    const tags = setTags();
    tags.splice(currPos, 1);
    tags.splice(newPos, 0, tag);
    setFormData({
      ...formData,
      [inputName]: setValues(tags)
    });
  }

  return (
    <div>
      <FormControl fullWidth className="zsh-select-root">
        {
          title !== undefined &&
          <span className="ReactTagsTitle MuiFormLabel-root">{title}</span>
        }
        <ReactTags
        tags={setTags()}
        placeholder={placeholder}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        handleDrag={handleDrag}
        delimiters={delimiters}
        />
        {
          note !== undefined &&
          <div className="ReactTagsNote">
            {note}
          </div>
        }
      </FormControl>
    </div>
  )
}

export default ZshTagInput;