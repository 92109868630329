import * as _usr_const from '../config/usr-constant';
import * as _debug from '../helper/debug';
import axios from 'axios';

/**
 * setFormData
 * 
 * @param formData 
 * @param data
 */
export function setFormData(formData: any, data: any): any {
  let results: any = {};
  for (const key in formData) {
    if (data.hasOwnProperty(key)) {
      if (data[key] === null || data[key] === '') {
        if (data[key] === null) {
          results[key] = null;
        } else {
          results[key] = '';
        }
      } else {
        results[key] = data[key];
      }
    }
    if (key === 'pickers') {
      results['pickers'] = formData[key];
      for (const picker in formData[key]) {
        if (data.hasOwnProperty(picker)) {
          if (data[picker] === null) {
            results['pickers'][picker] = null;
          } else {
            results['pickers'][picker] = data[picker];
          }
        }
      }
    }
  }
  return results;
}

/**
 * setValue
 * 
 * @param obj 
 * @param name 
 * @param value 
 */
type setValueProps = {
  formData: any,
  name: string,
  value: any,
  setFormData: any
}
export function setValue({ formData, name, value, setFormData }: setValueProps): void {
  let tmpFormData: any = Object.assign({}, formData);
  if (tmpFormData[name] !== undefined && value !== undefined) {
    tmpFormData[name] = value;
    setFormData({ tmpFormData });
  }
}

/**
 * handleSubmit
 * 
 * @param params
 */
type handleSubmitParams = {
  action: string;
  formData: any;
  history?: any;
  callbackSuccess?: any;
  callbackError?: any;
  backActionName?: string;
  setOverlayProppress: any;
  setFlashMessageSuccess: any;
  setFlashMessageError: any;
  setValidateErrors?: any;
  successFlashMessage?: string;
  errorFlashMessage?: string;
}
export function handleSubmit({
  action,
  formData,
  history,
  callbackSuccess,
  callbackError,
  backActionName,
  setOverlayProppress,
  setFlashMessageSuccess,
  setFlashMessageError,
  setValidateErrors,
  successFlashMessage = '保存しました',
  errorFlashMessage = '保存できませんでした'
}: handleSubmitParams) {

  window.scrollTo(0, 0);

  setOverlayProppress(true);

  const tmpBackActionName: string = backActionName !== undefined ? backActionName : '';

  let formatAction: string = '';
  if (formatAction.indexOf('index.json') === -1) {
    if (action.slice(-1) === '/') {
      formatAction = action;
    } else {
      formatAction = action + '/';
    } 
    formatAction += 'index.json';
  } else {
    formatAction = action;
  }
  
  axios
    .post(
      _usr_const.ApiUrl + formatAction,
      formData,
    )
    .then((response: any) => {
      setFlashMessageSuccess(successFlashMessage);
      if (tmpBackActionName !== '' && history !== undefined) {
        history.push(tmpBackActionName);
      }
      if (callbackSuccess !== undefined) {
        callbackSuccess(response);
      }
    })
    .catch((error) => {
      _debug.debugAxiosError(error);
      let errorMessage: string[] = [];
      if (error.response) {
        // set error message
        if (error.response.data.message !== undefined) {
          const errorJson = error.response.data.message;
          try {
            const parseErrorJson = JSON.parse(errorJson);
            if (typeof parseErrorJson === 'object' &&  parseErrorJson.length > 0) {
              parseErrorJson.forEach((message: string) => {
                errorMessage.push(message);
              });
            }
          } catch (error) {
            console.log(error);
          }
        }
        // validate error
        if (error.response.status === 400 && setValidateErrors !== undefined) {
          setValidateErrors(formatValidateError(error));
        }
      }
      // set flash message
      if (errorMessage.length > 0) {
        setFlashMessageError(errorMessage.join('¥n'));
      } else {
        setFlashMessageError(errorFlashMessage);
      }
      // console.log(error.config);
      if (callbackError !== undefined) {
        callbackError(error);
      }
    })
    .finally(() => {
      setOverlayProppress(false);
    });
}

/**
 * formatISO8601
 * 
 * @param date 
 */
export function formatISO8601(date: any): string {
  const offset = (function (d) {
    var o = d.getTimezoneOffset() / -60;
    return ((0 < o) ? '+' : '-') + ('00' + Math.abs(o)).substr(-2) + ':00';
  })(date);
  return [
    [
      date.getFullYear(),
      ('00' + (date.getMonth() + 1)).substr(-2),
      ('00' + date.getDate()).substr(-2),
    ].join('-'),
    'T',
    [
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
    ].join(':'),
    offset
  ].join('');
}

/**
 * formatDate
 * 
 * @param date 
 */
export function formatDate(date: any): string {
  return [
    [
      date.getFullYear(),
      ('00' + (date.getMonth() + 1)).substr(-2),
      ('00' + date.getDate()).substr(-2),
    ].join('-')
  ].join('');
}

/**
 * formatDateTime
 * 
 * @param date 
 */
export function formatDateTime(date: any): string {
  return [
    [
      date.getFullYear(),
      ('00' + (date.getMonth() + 1)).substr(-2),
      ('00' + date.getDate()).substr(-2),
    ].join('-'),
    ' ',
    [
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
    ].join(':')
  ].join('');
}

/**
 * handleDatePickerChange
 * 
 * @param formData 
 * @param date 
 * @param name 
 */
export function handleDatePickerChange(formData: any, date: any, name: string) {
  let tmpFormData: any = Object.assign({}, formData);
  let dateObj = null;
  if (date !== null) {
    dateObj = new Date(date);
    tmpFormData[name] = formatISO8601(dateObj);
  } else {
    tmpFormData[name] = dateObj;
  }
  tmpFormData['pickers'][name] = dateObj;
  return tmpFormData;
}

/**
 * setDatePickerValue
 * 
 */
export type setDatePickerValueProps = {
  formData: any;
  date: any;
  name: string;
  type: "date" | "datetime" | "iso" | string;
}

export function setDatePickerValue({
  formData,
  date,
  name,
  type = 'iso'
}: setDatePickerValueProps) {
  let tmpFormData: any = Object.assign({}, formData);
  let dateObj = null;
  if (date !== null) {
    dateObj = new Date(date);
    if (type === 'date') {
      tmpFormData[name] = formatDate(dateObj); 
    }
    if (type === 'datetime') {
      tmpFormData[name] = formatDateTime(dateObj); 
    }
    if (type === 'iso') {
      tmpFormData[name] = formatISO8601(dateObj); 
    }
  } else {
    tmpFormData[name] = dateObj;
  }
  tmpFormData['pickers'][name] = dateObj;
  return tmpFormData;
}

/**
 * formatValidateError
 * @param error 
 */
export function formatValidateError(error: any): { [key: string]: string[] } {
  let errors: { [key: string]: string[] } = {};
  if (error.response.data !== undefined && typeof error.response.data === 'object') {
    Object.keys(error.response.data).forEach((name: any) => {
      // set error message
      const errorMessage: string[] = Object.values(error.response.data[name]);
      errors[name] = errorMessage;
    });
  }
  return errors;
}

/**
 * checkUploadCsvFile
 */
type checkUploadCsvFileResponce = {
  file: any;
  er: boolean;
  errorMessage: string[];
}

export function checkUploadCsvFile(event: any): checkUploadCsvFileResponce {
  let results: checkUploadCsvFileResponce = {
    file: {},
    er: false,
    errorMessage: []
  }
  const file_size_limit = 20971520;
  if (event === undefined || event.target === undefined || event.target.files[0] === undefined) {
    results.errorMessage.push('ファイル対象を取得できません');
  } else {
    results.file = event.target.files[0];
    if (results.file.length === 0) {
      results.errorMessage.push('ファイルを読み込めません');
    } else {
      //check file format
      var file_types = ['text/csv', 'application/vnd.ms-excel'];
      if (file_types.indexOf(results.file.type) === -1) {
        results.errorMessage.push('CSVファイルではありません');
      }
      //check file size
      if (results.file.size > file_size_limit) {
        results.errorMessage.push('インポート出来るファイルサイズは20MBまでです');
      }
    }
  }
  if (results.errorMessage.length > 0) {
    results.er = true;
  }
  return results;
}