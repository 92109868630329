import React, { useState, useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom'
import * as _usr_const from '../../config/usr-constant';
import * as _view_action from '../../helper/viewAction';
import * as _debug from '../../helper/debug';
import axios from 'axios';
import Paper from '@material-ui/core/Paper';
import Loading from '../../components/View/Loading';
import HeadButtonGroups, { HeadButtonGroupBtnActionsProps } from '../../components/View/HeadButtonGroups';
import ZshLogs from '../../components/View/ZshLogs';
import ErrorView from '../../components/View/ErrorView';

type MailFromDataProps = {
  id: number;
  common_parent_id: number;
  email: string;
  common_parent: any;
}
function MailFromsView({ history, match }: RouteComponentProps<{ id: string }>) {

  const unmounted = useRef(false);
  const source = useRef(axios.CancelToken.source());

  const [isInit, setInit] = useState(true);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<MailFromDataProps>({
    id: 0,
    common_parent_id: 0,
    email: '',
    common_parent: {}
  });
  const [dataMounted, setDataMounted] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [errorStatus, setErrorStatus] = useState(0);

  // clean up
  useEffect(() => {
    const clSource = Object.assign({}, source.current);
    return () => {
      // cancel axios get
      clSource.cancel('cancel get mail from');
      unmounted.current = true;
    }
  }, []);

  useEffect(() => {
    if (isInit) {
      setLoading(true);
      _view_action.getViewData<MailFromDataProps>({
        unmounted,
        source,
        dataKey: 'mailFrom',
        path: _usr_const.ApiUrl + 'mail-froms/view/' + match.params.id + '/index.json',
        setData,
        setDataMounted,
        setErrorStatus,
        setErrorMsg,
        setLoading
      })
      .catch((error) => {
        _debug.debugAxiosError(error);
      });
      setInit(false);
    }
  }, [isInit, match.params.id]);

  const btnActions: HeadButtonGroupBtnActionsProps[] = [
    {
      type: 'edit',
      func: (): void => {
        history.push('/mail-froms/' + match.params.id + '/edit');
      },
      role: {
        name: 'MailFroms',
        action: 'edit',
      },
    },
  ];

  return (
    <div className="content-1">
      <Loading loading={loading} />
      {
        dataMounted &&
        <div>
          <HeadButtonGroups
            history={history}
            btnActions={btnActions}
          />
          <Paper>
            <div className="view-content-in">
              <table className="table-1">
                <tbody>
                  <tr>
                    <th>From</th>
                    <td>{data.email}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Paper>
          <ZshLogs logs={data.common_parent.logs} />
        </div>
      }
      {
        errorMsg !== '' &&
        <div>
          <ErrorView
            history={history}
            errorStatus={errorStatus}
            errorMsg={errorMsg}
          />
        </div>
      }
    </div>
  )
}

export default MailFromsView;