import React, { useState, useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import { overlayProppress } from '../../atoms/OverlayProgress';
import { flashMessageSuccess, flashMessageError } from '../../atoms/FlashMessage';
import { RouteComponentProps } from 'react-router-dom';
import * as _form from '../../helper/form';
import AttributeGroupCategorySelect from '../../components/Form/AttributeGroupCategorySelect';
import AttributeGroupConditionInputs, { AttributeGroupConditionProps } from '../../components/Form/AttributeGroupConditionInputs';
import AttributeGroupConditionPreview from '../../components/Form/AttributeGroupConditionPreview';
import Paper from '@material-ui/core/Paper';
import Loading from '../../components/View/Loading';
import HeadButtonGroups, { HeadButtonGroupBtnActionsProps } from '../../components/View/HeadButtonGroups';
import ZshTextField from '../../components/Form/ZshTextField';
import axios from 'axios';

type formDataProps = {
  attribute_group_category_id: string;
  name: string;
  memo: string;
  conditions: AttributeGroupConditionProps[];
}

function AttributeGroupsAdd({ history }: RouteComponentProps) {

  const setOverlayProppress = useSetRecoilState(overlayProppress);
  const setFlashMessageSuccess = useSetRecoilState(flashMessageSuccess);
  const setFlashMessageError = useSetRecoilState(flashMessageError);

  const actionName = 'attribute-groups/add/';
  const backActionName = '/attribute-groups';

  const unmounted = useRef(false);
  const source = useRef(axios.CancelToken.source());

  const [isInit, setInit] = useState(true);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState<formDataProps>({
    attribute_group_category_id: '',
    name: '',
    memo: '',
    conditions: []
  });
  const [validateErrors, setValidateErrors] = useState({});

  const handleChange = (event: any) => {
    const inputName = event.target.name;
    const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    setFormData({ ...formData, [inputName]: value });
  };

  const handleChangeConditions = (conditions: AttributeGroupConditionProps[]) => {
    setFormData({
      ...formData,
      conditions
    });
  }

  const handleSubmit = (event: any) => {
    event.preventDefault();
    _form.handleSubmit({
      action: actionName,
      formData,
      history,
      backActionName,
      setOverlayProppress,
      setFlashMessageSuccess,
      setFlashMessageError,
      setValidateErrors
    });
  }

  const handleSelectAttributeGroupCategory = (node: any) => {
    setFormData({ ...formData, attribute_group_category_id: node.id });
  }

  // clean up
  useEffect(() => {
    const clSource = Object.assign({}, source.current);
    return () => {
      // cancel axios get
      clSource.cancel('cancel get attribute groups form attribute group categories');
      unmounted.current = true;
    }
  }, []);

  useEffect(() => {
    if (isInit) {
      const initFunc = async () => {
        await ((): any => {
          setLoading(false);
          return Promise.resolve(1);
        })();
      }
      initFunc();
      setInit(false);
    }
  }, [isInit]);

  const btnActions: HeadButtonGroupBtnActionsProps[] = [
    {
      type: 'save',
    }
  ];

  return (
    <div className="content-1">
      <form onSubmit={handleSubmit}>
        <Loading loading={loading} />
        {
          loading === false &&
          <div>
            <HeadButtonGroups
              history={history}
              btnActions={btnActions}
            />
            <Paper>
              <div className="view-content-in form-content">
                <table className="form-table">
                  <tbody>
                    <tr>
                      <th>カテゴリ</th>
                      <td>
                        <div className="form-input-group">
                          <AttributeGroupCategorySelect
                          name="attribute_group_category_id"
                          handleSelectAttributeGroupCategory={handleSelectAttributeGroupCategory}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th className="required-th">グループ名称</th>
                      <td>
                        <div className="form-input-group">
                          <ZshTextField
                            value={formData.name}
                            name="name"
                            onChange={handleChange}
                            required
                            validateErrors={validateErrors}
                            fullWidth
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>メモ</th>
                      <td>
                        <div className="form-input-group">
                          <ZshTextField
                            value={formData.memo}
                            name="memo"
                            onChange={handleChange}
                            validateErrors={validateErrors}
                            fullWidth
                            multiline={true}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Paper>
            <AttributeGroupConditionInputs
            conditions={formData.conditions}
            onChange={handleChangeConditions}
            />
            <AttributeGroupConditionPreview
            conditions={formData.conditions}
            />
            <HeadButtonGroups
              history={history}
              btnActions={btnActions}
            />
          </div>
        }
      </form>
    </div>
  )
}

export default AttributeGroupsAdd;