import React, { useState, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as _filter from '../../helper/filter';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
    },
    details: {
      padding: '0',
      overflow: 'auto',
      maxHeight: '300px',
    },
    span: {
      display: 'inline-block',
      paddingRight: '10px',
    },
    spanFirst: {
      display: 'inline-block',
    },
    logTitle: {
      padding: '0px 10px',
    },
    logUl: {
      width: '100%',
      margin: '0',
      padding: '0',
    },
    logLi: {
      listStyle: 'none',
      padding: '10px',
      borderBottom: '1px solid #EEE',
      '&:last-child': {
        borderBottom: 'none',
      }
    }
  }));

type Props = {
  logs: any;
}

type ZshLogsProps = Props & RouteComponentProps;

function ZshLogs({ logs, history }: ZshLogsProps) {

  const [isInit, setInit] = useState(true);
  const [lastUpdateDatetime, setLastUpdateDatetime] = useState('');
  const [lastUpdateUserName, setLastUpdateUserName] = useState('');

  const handleClickView = (data: any) => {
    history.push('/logs/' + data.id);
  }

  useEffect(() => {
    if (isInit) {
      if (logs[0] !== undefined) {
        setLastUpdateDatetime(logs[0].created);
        if (logs[0].user !== null) {
          setLastUpdateUserName(logs[0].user.full_name);
        }
      }
      setInit(false);
    }
  }, [isInit, logs]);

  const classes = useStyles();

  const logsStyle = {
    margin: '30px 0',
  };

  logs.forEach((log: any) => {
    log.editType = log.is_new ? '登録' : '更新';
    if (log.user === null) {
      log.user = {
        full_name: '',
      }
    }
  });

  return (
    <div className="zsh-logs" style={logsStyle}>
      <ExpansionPanel className={classes.root}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.logTitle}>
          <span className={classes.spanFirst}>最終更新：</span>
          <span className={classes.span}>{_filter.ShFilter(lastUpdateDatetime, 'YMDHms')}</span>
          <span className={classes.span}>{lastUpdateUserName}</span>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          <ul className={classes.logUl}>
            {
              logs.map((log: any) => (
                <li
                  key={log.id}
                  className={classes.logLi}
                  onClick={() => handleClickView(log)}
                >
                  <span className={classes.spanFirst}>
                    {log.editType}：
                    </span>
                  <span className={classes.span}>
                    {_filter.ShFilter(log.created, 'YMDHms')}
                  </span>
                  <span className={classes.span}>
                    {log.user.full_name}
                  </span>
                </li>
              ))
            }
          </ul>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  )
}

export default withRouter(ZshLogs);