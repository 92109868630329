import React, { useRef, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { overlayProppress } from '../../atoms/OverlayProgress';
import { flashMessageSuccess, flashMessageError } from '../../atoms/FlashMessage';
import { zshDialog } from '../../atoms/ZshDialog';
import { RouteComponentProps } from 'react-router-dom';
import DataTable, { DataTableRowsProps, DataTableBtnGroupProps, DataTableSearchParamsProps } from '../../components/View/DataTable';
import { searchFilterProps } from '../../components/View/SearchFilter';
import * as _data_actions from '../../helper/dataActions';
import axios from 'axios';

function CustomersIndex({ history, location }: RouteComponentProps) {

  const setOverlayProppress = useSetRecoilState(overlayProppress);
  const setFlashMessageSuccess = useSetRecoilState(flashMessageSuccess);
  const setFlashMessageError = useSetRecoilState(flashMessageError);
  const setZshDialog = useSetRecoilState(zshDialog);

  const sourceDownloadCsv = useRef(axios.CancelToken.source());

  const clickCell = (callbackParams: any) => {
    history.push(location.pathname + '/' + callbackParams.data.id);
  }

  const handleToAdd = () => {
    history.push('/customers/add');
  }

  const confirmDelete = (params: any) => {
    if (params.selected.length > 0) {
      _data_actions.showConfirmDialog({
        dialogType: 'delete',
        funcAgree: () => {
          handleDelete(params);
        },
        setZshDialog
      });
    } else {
      setFlashMessageError('項目を選択してください');
    }
  }

  const handleDelete = (params: any) => {
    _data_actions.deleteData({
      deleteUrl: 'customers/delete/',
      selected: params.selected,
      getData: params.getData,
      setOverlayProppress,
      setFlashMessageSuccess,
      setFlashMessageError
    });
  }

  const handleDownloadCsv = (params: any) => {
    _data_actions.downloadCsv({
      downloadUrl: 'customers/download-csv/',
      fileName: 'customers',
      source: sourceDownloadCsv,
      queryParams: params.query,
      setOverlayProppress,
      setFlashMessageError
    });
  }

  const handleImportCsv = (params: any) => {
    history.push('/customers/import-csv');
  }

  // clean up
  useEffect(() => {
    const clSource = Object.assign({}, sourceDownloadCsv.current);
    return () => {
      // cancel axios get
      clSource.cancel('cancel customer download csv');
    }
  }, []);

  const rows: DataTableRowsProps[] = [
    {
      label: 'No',
      value: 'primary_number',
      sortField: 'Customers.primary_number',
      align: 'left',
    },
    {
      label: '顧客番号',
      value: 'customer_number',
      sortField: 'Customers.customer_number',
      align: 'left'
    },
    {
      label: '氏名',
      value: 'full_name',
      align: 'left',
    },
    {
      label: 'ステータス',
      value: 'status',
      align: 'left',
    },
    {
      label: '登録日時',
      value: 'created',
      filter: 'YMDHm',
      sortField: 'Customers.created',
      align: 'left',
    },
    {
      label: '更新日時',
      value: 'modified',
      filter: 'YMDHm',
      sortField: 'Customers.modified',
      align: 'left',
    },
  ];

  const searchParams: DataTableSearchParamsProps[] = [
    {
      title: 'No',
      text: '完全一致',
    },
    {
      title: '顧客番号',
      text: '部分一致',
    },
    {
      title: '氏名',
      text: '部分一致',
    },
    {
      title: 'メールアドレス',
      text: '完全一致',
    },
  ];

  const searchProps: searchFilterProps[] = [
    {
      key: 'search'
    },
    {
      key: 'status',
      dataKey: 'customer_statuses',
      title: 'ステータス',
      type: 'select',
      getOptionUrl: 'customer-statuses/get-customer-statuses/index.json',
      optionValue: 'id',
      optionText: 'name',
    },
  ];

  const btnGroup: DataTableBtnGroupProps[] = [
    {
      title: '新規追加',
      func: handleToAdd,
      color: 'primary',
      role: {
        name: 'Customers',
        action: 'add',
      },
    },
    {
      title: 'CSV出力(条件一致分)',
      func: handleDownloadCsv,
      color: 'default',
      role: {
        name: 'Customers',
        action: 'csv_download',
      },
    },
    {
      title: 'CSV取込',
      func: handleImportCsv,
      color: 'default',
      role: {
        name: 'Customers',
        action: 'csv_import',
      },
    },
    {
      title: '削除',
      func: confirmDelete,
      color: 'default',
      role: {
        name: 'Customers',
        action: 'delete',
      },
    },
  ];

  return (
    <div className="content-1">
      <DataTable
        rows={rows}
        checkbox={true}
        dataKey='customers'
        jsonPath='customers/index/index.json'
        urlQuery={true}
        parentProps={{ history, location }}
        handleClickCell={clickCell}
        searchParams={searchParams}
        searchProps={searchProps}
        btnGroup={btnGroup}
      />
    </div>
  )
}

export default CustomersIndex;