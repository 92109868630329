import React from 'react';
import { useSetRecoilState } from 'recoil';
import { overlayProppress } from '../../atoms/OverlayProgress';
import { flashMessageSuccess, flashMessageError } from '../../atoms/FlashMessage';
import { zshDialog } from '../../atoms/ZshDialog';
import { RouteComponentProps } from 'react-router-dom';
import DataTable, { DataTableRowsProps, DataTableBtnGroupProps } from '../../components/View/DataTable';
import * as _data_actions from '../../helper/dataActions';

function MailMagazineGroupsIndex({ history, location }: RouteComponentProps) {

  const setOverlayProppress = useSetRecoilState(overlayProppress);
  const setFlashMessageSuccess = useSetRecoilState(flashMessageSuccess);
  const setFlashMessageError = useSetRecoilState(flashMessageError);
  const setZshDialog = useSetRecoilState(zshDialog);

  const clickCell = (callbackParams: any) => {
    history.push(location.pathname + '/' + callbackParams.data.id);
  }

  const handleToAdd = () => {
    history.push('/mail-magazine-groups/add');
  }

  const confirmDelete = (params: any) => {
    if (params.selected.length > 0) {
      _data_actions.showConfirmDialog({
        dialogType: 'delete',
        funcAgree: () => {
          handleDelete(params);
        },
        setZshDialog
      });
    } else {
      setFlashMessageError('項目を選択してください');
    }
  }

  const handleDelete = (params: any) => {
    _data_actions.deleteData({
      deleteUrl: 'mail-magazine-groups/delete/',
      selected: params.selected,
      getData: params.getData,
      setOverlayProppress,
      setFlashMessageSuccess,
      setFlashMessageError
    });
  }

  const rows: DataTableRowsProps[] = [
    {
      label: 'ID',
      value: 'id',
      sortField: 'MailMagazineGroups.id',
      align: 'left',
    },
    {
      label: 'メルマガグループ名',
      value: 'name',
      align: 'left'
    },
    {
      label: '属性グループ',
      value: 'attribute_group_name',
      align: 'left',
    },
    {
      label: 'From',
      value: 'mail_from_email',
      align: 'left',
    },
    {
      label: '更新日時',
      value: 'modified',
      filter: 'YMDHm',
      sortField: 'MailMagazineGroups.modified',
      align: 'left',
    },
  ];

  const btnGroup: DataTableBtnGroupProps[] = [
    {
      title: '新規追加',
      func: handleToAdd,
      color: 'primary',
      role: {
        name: 'MailMagazineGroups',
        action: 'add',
      },
    },
    {
      title: '削除',
      func: confirmDelete,
      color: 'default',
      role: {
        name: 'MailMagazineGroups',
        action: 'delete',
      },
    },
  ];

  return (
    <div className="content-1">
      <DataTable
        rows={rows}
        checkbox={true}
        dataKey='mailMagazineGroups'
        jsonPath='mail-magazine-groups/index/index.json'
        urlQuery={true}
        parentProps={{ history, location }}
        handleClickCell={clickCell}
        btnGroup={btnGroup}
      />
    </div>
  )
}

export default MailMagazineGroupsIndex;