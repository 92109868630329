import React, { useState, useRef, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { overlayProppress } from '../../atoms/OverlayProgress';
import { flashMessageSuccess, flashMessageError } from '../../atoms/FlashMessage';
import { zshDialog } from '../../atoms/ZshDialog';
import axios from 'axios';
import Paper from '@material-ui/core/Paper';
import * as _usr_const from '../../config/usr-constant';
import * as _filter from '../../helper/filter';
import * as _form from '../../helper/form';
import * as _data_actions from '../../helper/dataActions';
import * as _debug from '../../helper/debug';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import ZshTooltip from '../../components/View/ZshTooltip';
import DataTable, { DataTableRowsProps, DataTableBtnGroupProps } from '../../components/View/DataTable';
import { searchFilterProps } from '../../components/View/SearchFilter';
import AjaxSelect from '../../components/Form/AjaxSelect';
import ZshTextField from '../../components/Form/ZshTextField';
import ZshDateTimePicker from '../../components/Form/ZshDateTimePicker';

type InquiryDataProps = {
  id: number;
  object_common_parent_id: number;
  customer_id: number;
  activity_type_id: number;
  activity_status_id: number;
  inquiry_datetime: string;
  content: string;
  model_display: string;
  object_view_url: string;
  activity_status: {
    color: string;
    name: string;
  };
  registration_information_type: string;
  registration_information_source: string;
}

type InquiriesProps = {
  objectCommonParentId: number;
  customerId: number;
  activityTypeId?: number;
  findType: string;
  title: string;
}

function Inquiries({
  objectCommonParentId,
  customerId,
  activityTypeId,
  findType,
  title
}: InquiriesProps) {

  const defaultData: InquiryDataProps = {
    id: 0,
    object_common_parent_id: 0,
    customer_id: 0,
    activity_type_id: 0,
    activity_status_id: 0,
    inquiry_datetime: '',
    content: '',
    model_display: '',
    object_view_url: '',
    activity_status: {
      color: '',
      name: '',
    },
    registration_information_type: '',
    registration_information_source: '',
  };

  const defaultFormData = {
    id: '',
    object_common_parent_id: '',
    customer_id: '',
    activity_type_id: '',
    activity_status_id: '',
    content: '',
    inquiry_datetime: null,
    pickers: {
      inquiry_datetime: null,
    },
  };

  const setOverlayProppress = useSetRecoilState(overlayProppress);
  const setFlashMessageSuccess = useSetRecoilState(flashMessageSuccess);
  const setFlashMessageError = useSetRecoilState(flashMessageError);
  const setZshDialog = useSetRecoilState(zshDialog);

  const source = useRef(axios.CancelToken.source());
  const unmounted = useRef(false);

  const [isInit, setInit] = useState(true);
  const [selectedData, setSelectedData] = useState<InquiryDataProps>(defaultData);
  const [modal, setModal] = useState('');
  const [formData, setFormData] = useState(defaultFormData);
  const [validateErrors, setValidateErrors] = useState({});
  const [activityStatuses, setActivityStatuses] = useState([]);
  const [allActivityStatuses, setAllActivityStatuses] = useState([]);
  const [activityStatusesMounted, setActivityStatusesMounted] = useState(false);
  const [activityStatusesError, setActivityStatusesError] = useState(false);
  const [dataTableCallbackParams, setDataTableCallbackParams] = useState({
    getData: () => {},
    query: {},
  });

  const handleChange = (event: any) => {
    const inputName = event.target.name;
    const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    setFormData({ ...formData, [inputName]: value });
  };

  const handleClose = () => {
    setSelectedData(defaultData);
    setFormData(defaultFormData);
    setValidateErrors({});
    setModal('');
  }

  const handleView = (params: any) => {
    setDataTableCallbackParams(params);
    const selecttedData = Object.assign({}, params.data);
    setSelectedData({ ...selecttedData });
    setFormData({
      id: selecttedData.id,
      object_common_parent_id: selecttedData.object_common_parent_id,
      customer_id: selecttedData.customer_id,
      activity_type_id: selecttedData.activity_type_id,
      activity_status_id: selecttedData.activity_status_id,
      content: selecttedData.content,
      inquiry_datetime: selecttedData.inquiry_datetime,
      pickers: {
        inquiry_datetime: selecttedData.inquiry_datetime,
      },
    });
    setModal('view');
    // get activity statuses
    let activityStatusesPath = 'activity-statuses/get-activity-statuses/index.json'
    if (selecttedData.activity_type_id !== null) {
      activityStatusesPath = `activity-types/get-activity-status/${selecttedData.activity_type_id}/index.json`
    }
    axios.get(
      _usr_const.ApiUrl + activityStatusesPath,
      {
        cancelToken: source.current.token
      }
    )
    .then((results: any) => {
      if (results.data.activity_statuses !== undefined) {        
        if (!unmounted.current) {
          let tmpActivityStatuses: any = {};
          results.data.activity_statuses.forEach((obj: any) => {
            tmpActivityStatuses[obj.id] = obj.name;
          });          
          setActivityStatuses(tmpActivityStatuses);
          setActivityStatusesError(false);
        }
      }
    })
    .catch((error: any) => {
      _debug.debugAxiosError(error);
      if (!unmounted.current) {
        setActivityStatusesError(true);
      }
    })
    .finally(() => {
      setActivityStatusesMounted(true);
    });
  }

  const handleAdd = (params: any) => {
    setDataTableCallbackParams(params);
    setFormData({
      ...formData,
      object_common_parent_id: objectCommonParentId.toString(),
      customer_id: customerId.toString(),
      pickers: {
        inquiry_datetime: null,
      }
    });
    setModal('add');
  }

  const handleEdit = () => {
    setModal('edit');
  }

  const handleDatePickerChange = (date: any, name: string): void => {
    const tmpFormData = _form.handleDatePickerChange(formData, date, name);
    setFormData(tmpFormData);
  }

  const formFinished = () => {
    setOverlayProppress(false);
    dataTableCallbackParams.getData();
    handleClose();
  }

  const handleSubmit = () => {
    let postUrl = '';
    if (modal === 'edit') {
      postUrl = `inquiries/edit/${formData.id}`;      
    }
    if (modal === 'add') {
      postUrl = 'inquiries/add'
    }
    if (postUrl !== '') {
      _form.handleSubmit(
        {
          action: postUrl,
          formData,
          callbackSuccess: formFinished,
          setOverlayProppress,
          setFlashMessageSuccess,
          setFlashMessageError,
          setValidateErrors
        },
      ); 
    }
  }

  const setActivityStatusMenuItems = () => {
    if (modal === 'add') {
      return allActivityStatuses;
    }
    if (modal === 'edit') {
      if (activityStatusesMounted) {
        return activityStatuses;
      }
    }
    return [];
  }

  const handleDeletePost = (params: any) => {
    if (params.data !== undefined && params.data.id) {
      const selected = [params.data.id];
      _data_actions.deleteData({
        deleteUrl: 'inquiries/isdelete/',
        selected: selected,
        getData: params.getData,
        setOverlayProppress,
        setFlashMessageSuccess,
        setFlashMessageError
      });
    } else {
      setFlashMessageError('削除対象を取得できませんでした');
    }
  }

  const handleDelete = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, params: any) => {
    event.stopPropagation();
    _data_actions.showConfirmDialog({
      message: '削除してよろしいですか？¥nこの操作は元に戻せません。',
      closeText: 'キャンセル',
      agreeText: '削除する',
      funcAgree: () => {
        handleDeletePost(params);
      },
      setZshDialog
    });
  }

  const deleteNode = (params: any) => {
    return (
      <span>
        <IconButton aria-label="Delete" onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleDelete(event, params)}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </span>
    )
  }

  // clean up
  useEffect(() => {
    const clSource = Object.assign({}, source.current);
    return () => {
      // cancel axios get
      clSource.cancel('cancel inquiries get activity statuses');
      unmounted.current = true;
    }
  }, []);

  useEffect(() => {
    if (isInit) {
      const initFunc = async() => {
        // get activity statuses
        let allActivityStatusesPath = 'activity-statuses/get-activity-statuses/index.json'
        if (activityTypeId !== undefined) {
          allActivityStatusesPath = `activity-types/get-activity-status/${activityTypeId}/index.json`
        }
        await axios.get(
          _usr_const.ApiUrl + allActivityStatusesPath,
          {
            cancelToken: source.current.token
          }
        )
        .then((results: any) => {          
          if (results.data.activity_statuses !== undefined) {
            if (!unmounted.current) {
              let tmpActivityStatuses: any = {};
              results.data.activity_statuses.forEach((obj: any) => {
                tmpActivityStatuses[obj.id] = obj.name;
              });
              setAllActivityStatuses(tmpActivityStatuses);
              setActivityStatusesError(false);
            }
          }
        })
        .catch((error: any) => {
          _debug.debugAxiosError(error);
          if (!unmounted.current) {
            setActivityStatusesError(true);
          }
        })
        .finally(() => {
         return Promise.resolve(1);
        });
      }
      initFunc();
      setInit(false);
    }
  }, [isInit, activityTypeId]);

  let getAllActiviryStatusesPath = `activity-statuses/get-activity-statuses/index.json`
  if (activityTypeId !== undefined) {
    getAllActiviryStatusesPath = `activity-types/get-activity-status/${activityTypeId}/index.json`
  }

  const rows: DataTableRowsProps[] = [
    {
      label: '登録日時',
      value: 'created',
      filter: 'YMDHm',
      sortField: 'Inquiries.created',
      align: 'left',
    },
    {
      label: '種別',
      value: 'model_display',
      align: 'left'
    },
    {
      label: 'ステータス',
      value: 'activity_status_name',
      align: 'left',
    },
    {
      label: '問合せ',
      value: 'content',
      align: 'left',
      maxLength: 20
    },
    {
      label: '',
      align: 'right',
      node: deleteNode
    }
  ];

  let searchProps: searchFilterProps[] = [];

  if (findType === 'customer') {
    searchProps.push({
      key: 'model',
      dataKey: 'activity_types',
      title: '種別',
      type: 'select',
      getOptionUrl: 'activity-types/get_activity_types/add/index.json',
      optionValue: 'model',
      optionText: 'name',
    });
  }

  searchProps.push({
    key: 'status',
    dataKey: 'activity_statuses',
    title: 'ステータス',
    type: 'select',
    getOptionUrl: getAllActiviryStatusesPath,
    optionValue: 'id',
    optionText: 'name',
  });

  const btnGroup: DataTableBtnGroupProps[] = [
    {
      title: '登録',
      func: handleAdd,
      color: 'primary',
    },
  ];

  return (
    <Paper className="content-2">
      <h2 className="h-2">{title}</h2>
      <DataTable
        rows={rows}
        checkbox={false}
        dataKey='inquiries'
        jsonPath={'inquiries/index/' + objectCommonParentId + '/index.json'}
        urlQuery={false}
        handleClickCell={handleView}
        limit={5}
        size="small"
        searchProps={searchProps}
        btnGroup={btnGroup}
        filterGroupsInline={true}
        defaultQueryValues={
          {
            find_type: findType,
            customer_id: customerId
          }
        }
      />
      <Dialog
        fullWidth={true}
        open={modal === 'view' ? true : false}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">問合せ詳細</DialogTitle>
        <DialogContent>
          <table className="table-1">
            <tbody>
              <tr>
                <th>種別</th>
                <td>
                  {selectedData.model_display}
                </td>
              </tr>
              <tr>
                <th>ステータス</th>
                <td>
                  {
                    selectedData.activity_status.color !== null &&
                    <span style={{ color: selectedData.activity_status.color }}>
                      {selectedData.activity_status.name}
                    </span>
                  }
                  {
                    selectedData.activity_status.color === null &&
                    <span>
                      {selectedData.activity_status.name}
                    </span>
                  }
                </td>
              </tr>
              <tr>
                <th>日時</th>
                <td className="pre-line">
                  {_filter.ShFilter(selectedData.inquiry_datetime, 'YMDHm')}
                </td>
              </tr>
              <tr>
                <th>内容</th>
                <td className="pre-line">
                  {selectedData.content}
                </td>
              </tr>
              <tr>
                <th>
                  登録方法
                  <ZshTooltip text="このデータがどのようにして登録されたかの区分です。" />
                </th>
                <td>
                  {selectedData.registration_information_type}
                </td>
              </tr>
              <tr>
                <th>
                  データソース
                  <ZshTooltip text="このデータがどこから登録されたかの区分です。" />
                </th>
                <td>
                  {selectedData.registration_information_source}
                </td>
              </tr>
            </tbody>
          </table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            閉じる
            </Button>
          <Button onClick={handleEdit} color="primary" autoFocus>
            編集
            </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        open={(() => {
          if (modal === 'edit' || modal === 'add') {
            return true;
          }
          return false;
        })()}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {
            modal === 'add' &&
            <span>問合せ登録</span>
          }
          {
            modal === 'edit' &&
            <span>問合せ編集</span>
          }
        </DialogTitle>
        <DialogContent>
          <form className="form-content dialog-form">
            <div className="view-content-in form-content">
              <div className="form-input-group">
                <AjaxSelect
                name="activity_status_id"
                label="ステータス"
                menuItems={setActivityStatusMenuItems()}
                formData={formData}
                handleChange={handleChange}
                validateErrors={validateErrors}
                getError={activityStatusesError}
                required
              />
              </div>
              <div className="form-input-group">
                <ZshDateTimePicker
                  label="日時"
                  name="inquiry_datetime"
                  value={formData.pickers.inquiry_datetime}
                  onChange={(date: any) => handleDatePickerChange(date, 'inquiry_datetime')}
                  format="yyyy/MM/dd HH:mm"
                  views={['date', 'hours']}
                  validateErrors={validateErrors}
                  required
                />
              </div>
              <div className="form-input-group">
                <ZshTextField
                  name="content"
                  label="内容"
                  value={formData.content}
                  onChange={handleChange}
                  margin="normal"
                  className="form-input form-textarea"
                  multiline={true}
                  fullWidth
                  required
                  validateErrors={validateErrors}
                />
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            閉じる
          </Button>
          <Button
          onClick={handleSubmit}
          color="primary"
          >
            保存
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  )
}

export default Inquiries;