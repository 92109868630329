import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useSetRecoilState } from 'recoil';
import { overlayProppress } from '../../atoms/OverlayProgress';
import { flashMessageSuccess, flashMessageError } from '../../atoms/FlashMessage';
import { zshDialog } from '../../atoms/ZshDialog';
import { RouteComponentProps } from 'react-router-dom';
import * as _usr_const from '../../config/usr-constant';
import * as _form from '../../helper/form';
import * as _data_actions from '../../helper/dataActions';
import * as _roleHelper from '../../helper/role';
import * as _debug from '../../helper/debug';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import HeadButtonGroups, { HeadButtonGroupBtnActionsProps } from '../../components/View/HeadButtonGroups';
import Loading from '../../components/View/Loading';
import axios from 'axios';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@material-ui/icons/Create';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import ZshTextField from '../../components/Form/ZshTextField';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: '5px',
    }
  })
);

type AttributeGroup = {
  id: string;
  attribute_group_category_id: number;
  name: string;
}

type AttributeGroups = {
  id: string;
  name: string;
  level: number;
  children?: AttributeGroups[];
  attribute_groups?: AttributeGroup[];
}

type AttributeGroupFormDataProps = {
  id: string;
  name: string;
  level: number;
  parent_id?: string;
}

function AttributeGroupsIndex({ history, location }: RouteComponentProps) {

  const classes = useStyles();

  const setOverlayProppress = useSetRecoilState(overlayProppress);
  const setFlashMessageSuccess = useSetRecoilState(flashMessageSuccess);
  const setFlashMessageError = useSetRecoilState(flashMessageError);
  const setZshDialog = useSetRecoilState(zshDialog);

  const unmounted = useRef(false);
  const source = useRef(axios.CancelToken.source());

  const AuthRoles: any = useSelector(state => state.AuthRoles);

  const [isInit, setInit] = useState(true);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<{ category_none_group: any[], list: AttributeGroups[] }>({
    category_none_group: [],
    list: []
  });
  const [selected, setSelected] = useState<any>([]);
  const [selectedFolder, setSelectedFolder] = useState('');
  const [selectedData, setSelectedData] = useState<AttributeGroups>({
    id: '',
    name: '',
    level: 0,
    children: [],
    attribute_groups: []    
  });
  const [showDialog, setShowDialog] = useState('');
  const [formData, setFormData] = useState<AttributeGroupFormDataProps>({
    id: '',
    name: '',
    level: 0,
    parent_id: ''
  });
  const [validateErrors, setValidateErrors] = useState({});

  const handleChange = (event: any) => {
    const inputName = event.target.name;
    const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    setFormData({ ...formData, [inputName]: value });
  };

  const clickCell = (node: AttributeGroup) => {
    history.push(location.pathname + '/' + node.id);
  }

  const handleClickTree = (node: AttributeGroups) => {
    setSelectedFolder(node.id);
    setSelectedData(node);
  }

  const handleToAdd = () => {
    history.push('/attribute-groups/add');
  }

  const confirmDelete = () => {
    if (selected.length > 0) {
      _data_actions.showConfirmDialog({
        dialogType: 'delete',
        funcAgree: () => {
          handleDelete();
        },
        setZshDialog
      });
    } else {
      setFlashMessageError('項目を選択してください');
    }
  }

  const handleDelete = () => {
    _data_actions.deleteData({
      deleteUrl: 'attribute-groups/delete/',
      selected: selected,
      getData: getData,
      setOverlayProppress,
      setFlashMessageSuccess,
      setFlashMessageError
    });
  }

  const handleCheck = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
    event.stopPropagation();
    if (unmounted.current) {
      return;
    }
    const selectedIndex = selected.indexOf(id);
    let newSelected: any = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (id: string) => {
    return selected.indexOf(id) !== -1;
  }

  const handleEditTreeFolder = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, nodes: any): any => {
    event.stopPropagation();
    setSelectedData(nodes);
    setFormData({
      id: nodes.id,
      name: nodes.name,
      level: nodes.level
    });
    setShowDialog('edit');
  }

  const handleCreateTreeFolder = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, nodes: any): any => {
    event.stopPropagation();
    setSelectedData(nodes);
    setFormData({
      id: '',
      name: '',
      level: 0,
      parent_id: ''
    });
    setShowDialog('add');
  }

  const handleDeleteTreeFolder = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, nodes: any): any => {
    event.stopPropagation();
    const selectedId: string = nodes.id;
    _data_actions.showConfirmDialog({
      dialogType: 'delete',
      message: '削除してよろしいですか？',
      funcAgree: () => {
        setOverlayProppress(true);
        axios
          .post(
            _usr_const.ApiUrl + 'attribute-group-categories/delete/' + selectedId,
            {
              id: selectedId,
            },
          )
          .then(() => {
            getData();
            setFlashMessageSuccess('削除しました');
          })
          .catch((error) => {
            _debug.debugAxiosError(error);
            let erMsg = '';
            if (error.response) {
              erMsg = error.response.data.message;
            }
            if (erMsg === '') {
              setFlashMessageError('削除できませんでした');
            } else {
              setFlashMessageError(erMsg);
            }
          })
          .finally(() => {
            setOverlayProppress(false);
          });
      },
      setZshDialog
    });
  }

  const treeFolderLabel = (nodes: any) => {
    return (
      <div className="tree-folder-label">
        <div className="tree-folder-">{nodes.name}</div>
        {
          selectedFolder === nodes.id &&
          <div className="tree-folder-actions">
            {
              _roleHelper.checkRole({ name: 'AttributeGroups', action: 'edit' }, AuthRoles) &&
              <IconButton
              aria-label="Edit Folder"
              onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleEditTreeFolder(event, nodes)}
              className="tree-folder-action"
              >
                <CreateIcon fontSize="small" />
              </IconButton>
            }
            {
              _roleHelper.checkRole({ name: 'AttributeGroups', action: 'add' }, AuthRoles) &&
              <IconButton
              aria-label="Create Folder"
              onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleCreateTreeFolder(event, nodes)}
              className="tree-folder-action"
              >
                <CreateNewFolderIcon fontSize="small" />
              </IconButton>
            }
            {
              _roleHelper.checkRole({ name: 'AttributeGroups', action: 'delete' }, AuthRoles) &&
              <IconButton
              aria-label="Delete Folder"
              onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleDeleteTreeFolder(event, nodes)}
              className="tree-folder-action"
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            }
          </div>
        }
      </div>
    );
  }

  const renderTree = (nodes: AttributeGroups) => {
    const nodeID: string = nodes.id.toString();
    return (
      <TreeItem
      key={nodes.id}
      nodeId={nodeID}
      label={treeFolderLabel(nodes)}
      className={classes.root}
      onClick={(event: any)=> handleClickTree(nodes)}
      >
        {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
        {
          Array.isArray(nodes.attribute_groups) &&
          nodes.attribute_groups.map((agNode: AttributeGroup) => (
            <TreeItem
            key={'ag-' + agNode.id}
            nodeId={'ag-' + agNode.id.toString()}
            label={(() => {
              return (
                <span
                onClick={(event: any) => clickCell(agNode)}
                >
                  {
                    _roleHelper.checkRole({ name: 'AttributeGroups', action: 'delete' }, AuthRoles) &&
                    <Checkbox
                    checked={isSelected(agNode.id)}
                    onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleCheck(event, agNode.id)}
                    />
                  }
                  {agNode.name}
                </span>
              )
            })()}
            className={classes.root}
            >
            </TreeItem>
          ))
        }
      </TreeItem>
    )
  }

  const getData = useCallback(async () => {
    setLoading(true);
    await axios
      .get(
        _usr_const.ApiUrl + 'attribute-groups/index.json',
        {
          cancelToken: source.current.token
        }
      )
      .then((response: any) => {
        if (typeof response.data !== 'undefined') {
          if (!unmounted.current) {
            setData({
              category_none_group: response.data.category_none_group,
              list: response.data.list
            });
          }
      }
      })
      .catch((error) => {
        _debug.debugAxiosError(error);
        setFlashMessageError('データを取得できませんでした');
      })
      .finally(() => {
        setLoading(false);
        return Promise.resolve(1);
      });
  }, [setFlashMessageError, setLoading]);

  const handleViewClose = () => {
    setShowDialog('');
  }

  const dialogAgreeFuncFirstParentAdd = () => {
    setOverlayProppress(true);
    _form.handleSubmit({
      action: 'attribute-group-categories/add/',
      formData: {
        name: formData.name,
        level: 0
      },
      setOverlayProppress,
      setFlashMessageSuccess,
      setFlashMessageError,
      setValidateErrors,
      callbackSuccess: () => {
        setShowDialog('');
        getData();
      }
    });
  }

  const dialogAgreeFuncAdd = () => {
    setOverlayProppress(true);
    _form.handleSubmit({
      action: 'attribute-group-categories/add/',
      formData: {
        parent_id: selectedData.id,
        name: formData.name,
        level: selectedData.level + 1
      },
      setOverlayProppress,
      setFlashMessageSuccess,
      setFlashMessageError,
      setValidateErrors,
      callbackSuccess: () => {
        setShowDialog('');
        getData();
      }
    });
  }

  const dialogAgreeFuncEdit = () => {
    setOverlayProppress(true);
    _form.handleSubmit({
      action: 'attribute-group-categories/edit/',
      formData: {
        id: formData.id,
        name: formData.name,
        level: formData.level
      },
      setOverlayProppress,
      setFlashMessageSuccess,
      setFlashMessageError,
      setValidateErrors,
      callbackSuccess: () => {
        setShowDialog('');
        getData();
      }
    });
  }

  const handleClickFirstParentAdd = () => {
    setShowDialog('add_first_parent');
  }

  useEffect(() => {
    const clSource = Object.assign({}, source.current);
    return () => {
      // cancel axios get
      clSource.cancel('cancel attribute groups get');
      unmounted.current = true;
    }
  }, []);

  useEffect(() => {
    if (isInit) {
      const initFunc = async () => {
        await getData();
        await (() => {
          setInit(false);
          return Promise.resolve(1);
        })();
      }
      initFunc();
    }
  }, [isInit, getData]);

  const btnActions: HeadButtonGroupBtnActionsProps[] = [
    {
      text: '新規追加',
      func: handleToAdd,
      color: 'primary',
      role: {
        name: 'AttributeGroups',
        action: 'add',
      },
    },
    {
      text: '削除',
      func: confirmDelete,
      color: 'default',
      role: {
        name: 'AttributeGroups',
        action: 'delete',
      },
    },
  ];

  return (
    <div id="attribute-groups" className="content-1">
      <Loading loading={loading} />
      {
        loading === false &&
        <div>
          <HeadButtonGroups
            history={history}
            btnActions={btnActions}
            hideBack
          />
          <div
          className="attribute-groups-first-parent-add-wr"
          onClick={(event: any) => handleClickFirstParentAdd()}
          >
            <span><CreateNewFolderIcon color="primary" /></span>
            <span>カテゴリ追加</span>
          </div>
          <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          >
            {
              data.list.length > 0 &&
              data.list.map((node: AttributeGroups) => (
                renderTree(node)
              ))
            }
          </TreeView>
          {
            data.category_none_group.length > 0 &&
            <TreeView className="attribute-groups-none">
              {
                data.category_none_group.map((noneNode: AttributeGroup) => (
                  <TreeItem
                  key={'ag-' + noneNode.id}
                  nodeId={'ag-' + noneNode.id.toString()}
                  label={(() => {
                    return (
                      <span
                      onClick={(event: any) => clickCell(noneNode)}
                      >
                        {
                          _roleHelper.checkRole({ name: 'AttributeGroups', action: 'delete' }, AuthRoles) &&
                          <Checkbox
                          checked={isSelected(noneNode.id)}
                          onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleCheck(event, noneNode.id)}
                          />
                        }
                        {noneNode.name}
                      </span>
                    )
                  })()}
                  className={classes.root}
                  >
                  </TreeItem>
                ))
              }
            </TreeView>
          }
        </div>
      }
      <Dialog
        fullWidth={true}
        open={showDialog !== ''}
        onClose={handleViewClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {
            showDialog === 'add_first_parent' &&
            <span>カテゴリ追加</span>
          }
          {
            showDialog === 'add' &&
            <span>子カテゴリ追加</span>
          }
          {
            showDialog === 'edit' &&
            <span>編集</span>
          }
        </DialogTitle>
        <DialogContent>
          <div className="form-content">
            {
              showDialog === 'add_first_parent' &&
              <div>
                <div className="form-input-group">
                  <ZshTextField
                    value={formData.name}
                    name="name"
                    onChange={handleChange}
                    required
                    validateErrors={validateErrors}
                    fullWidth
                  />
                </div>
              </div>
            }
            {
              showDialog === 'add' &&
              <div>
                <p>{selectedData.name} &gt;</p>
                <div className="form-input-group">
                  <ZshTextField
                    value={formData.name}
                    name="name"
                    onChange={handleChange}
                    required
                    validateErrors={validateErrors}
                    fullWidth
                  />
                </div>
              </div>
            }
            {
              showDialog === 'edit' &&
              <div>
                <div className="form-input-group">
                  <ZshTextField
                    value={formData.name}
                    name="name"
                    onChange={handleChange}
                    required
                    validateErrors={validateErrors}
                    fullWidth
                  />
                </div>
              </div>
            }
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleViewClose} color="default">
            閉じる
          </Button>
          <Button
          onClick={(event: any) => {
            if (showDialog === 'add_first_parent') {
              dialogAgreeFuncFirstParentAdd();
            }
            if (showDialog === 'add') {
              dialogAgreeFuncAdd();
            }
            if (showDialog === 'edit') {
              dialogAgreeFuncEdit();
            }
          }}
          color="primary"
          autoFocus>
            保存
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AttributeGroupsIndex;