import * as _usr_const from '../config/usr-constant';
import axios from 'axios'

export function getAuthRolesRequest() {
  return {
    type: 'GET_AUTH_ROLES_REQUEST'
  }
}

export function getAuthRolesSuccess(json: any) {
  return {
    type: 'GET_AUTH_ROLES_SUCCESS',
    data: json,
    receivedAt: Date.now()
  }
}

export function getAuthRolesFailure(error: any) {
  return {
    type: 'GET_AUTH_ROLES_FAILURE',
    error
  }
}

export function getAuthRoles() {
  const apiPath = _usr_const.ApiUrl + 'users/get-auth-roles/index.json';
  return (dispatch: any) => {
    dispatch(getAuthRolesRequest())
    return axios.get(apiPath)
      .then(res =>
        dispatch(getAuthRolesSuccess(res.data))
      ).catch(err => {
        dispatch(getAuthRolesFailure(err))
      }
    )
  }
}