import React, { useState, useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import { overlayProppress } from '../../atoms/OverlayProgress';
import { flashMessageSuccess, flashMessageError } from '../../atoms/FlashMessage';
import { RouteComponentProps } from 'react-router-dom';
import * as _form from '../../helper/form';
import * as _rolesFormFunc from './func/RolesForm'
import * as _usr_const from '../../config/usr-constant';
import Paper from '@material-ui/core/Paper';
import Loading from '../../components/View/Loading';
import HeadButtonGroups, { HeadButtonGroupBtnActionsProps } from '../../components/View/HeadButtonGroups';
import axios from 'axios';
import RoleActionInputs from './RoleActionInputs';
import ZshTextField from '../../components/Form/ZshTextField';

type roleNamesProps = {
  title: string;
  actions: any;
}

type roleFormDataProps = {
  name: string;
  role: { [key: string]: string[] };
}

function RolesEdit({ history, match }: RouteComponentProps<{ id: string }>) {

  const setOverlayProppress = useSetRecoilState(overlayProppress);
  const setFlashMessageSuccess = useSetRecoilState(flashMessageSuccess);
  const setFlashMessageError = useSetRecoilState(flashMessageError);

  const actionName = 'roles/edit/';
  const backActionName = '/roles';

  const unmounted = useRef(false);
  const source = useRef(axios.CancelToken.source());
  const dataSource = useRef(axios.CancelToken.source());

  const [isInit, setInit] = useState(true);
  const [dataMounted, setDataMounted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState<roleFormDataProps>({
    name: '',
    role: {}
  });
  const [validateErrors, setValidateErrors] = useState({});
  const [roleNames, setRoleNames] = useState<{ [key: string]: roleNamesProps }>({});
  const [roleNamesError, setRoleNamesError] = useState(false);
  const [loadings, setLoadings] = useState({
    roleNames: true
  });

  const handleChange = (event: any) => {
    const inputName = event.target.name;
    const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    setFormData({ ...formData, [inputName]: value });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    _form.handleSubmit({
      action: actionName + match.params.id,
      formData,
      history,
      backActionName,
      setOverlayProppress,
      setFlashMessageSuccess,
      setFlashMessageError,
      setValidateErrors
    });
  }

  const handleChangeRoleAction = (event?: React.ChangeEvent<HTMLInputElement>, checked?: boolean, roleKey?: string) => {
    let role: { [key: string]: string[] } = _rolesFormFunc.handleChangeRoleAction({
      event,
      checked,
      roleKey,
      formData
    });
    setFormData({ ...formData, role });
  }

  // clean up
  useEffect(() => {
    const clSource = Object.assign({}, source.current);
    const clDSource = Object.assign({}, dataSource.current);
    return () => {
      // cancel axios get
      clSource.cancel('cancel roles names get');
      clDSource.cancel('cancel roles form data get');
      unmounted.current = true;
    }
  }, []);

  useEffect(() => {
    if (isInit) {
      const initFunc = async () => {
        await _rolesFormFunc.getRoleNames({
          unmounted: unmounted.current,
          setRoleNames,
          setLoadings,
          setRoleNamesError,
          source
        });
        if (dataMounted === false) {
          const tepFormData = Object.assign({}, formData);
          await axios
            .get(_usr_const.ApiUrl + actionName + match.params.id + '/index.json', {
              cancelToken: dataSource.current.token
            })
            .then((results: any) => {
              if (!unmounted.current) {
                const formData = _form.setFormData(tepFormData, results.data.role);
                setFormData(formData);
              }
            })
            .finally(() => {
              if (!unmounted.current) {
                setLoading(false);
                setDataMounted(true);
              }
              return Promise.resolve(1);
            });
        }
        await ((): any => {
          if (!unmounted.current) {
            setLoading(false);
          }
          return Promise.resolve(1);
        })();
      }
      initFunc();
      setInit(false);
    }
  }, [isInit, dataMounted, formData, match.params.id]);

  const btnActions: HeadButtonGroupBtnActionsProps[] = [
    {
      type: 'save',
    }
  ];

  return (
    <div className="content-1">
      <form onSubmit={handleSubmit}>
        <Loading loading={loading} />
        {
          dataMounted &&
          <div>
            <HeadButtonGroups
              history={history}
              btnActions={btnActions}
            />
            <Paper>
              <div className="view-content-in form-content">
                {
                  loadings.roleNames === false &&
                  roleNamesError === false &&
                  <div>
                    <table className="form-table">
                      <tbody>
                        <tr>
                          <th className="required-th">権限グループ名</th>
                          <td>
                            <div className="form-input-group">
                              <ZshTextField
                                value={formData.name}
                                name="name"
                                onChange={handleChange}
                                required
                                fullWidth
                                validateErrors={validateErrors}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>権限設定</th>
                          <td>
                            <div>
                              {
                                Object.keys(roleNames).length > 0 &&
                                Object.keys(roleNames).map((roleKey: string) => (
                                  <div key={roleKey}>
                                    <p className="role-key-name">{roleNames[roleKey].title}</p>
                                    <RoleActionInputs
                                      roleKey={roleKey}
                                      actions={roleNames[roleKey].actions}
                                      formDataRole={formData.role}
                                      handleChangeRoleAction={handleChangeRoleAction}
                                    />
                                  </div>
                                ))
                              }
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                }
                {
                  loadings.roleNames === false &&
                  roleNamesError === true &&
                  <div>
                    データを取得できませんでした。<br />ページリロードをお願いします。
                </div>
                }
              </div>
            </Paper>
            <HeadButtonGroups
              history={history}
              btnActions={btnActions}
            />
          </div>
        }
      </form>
    </div>
  )
}

export default RolesEdit;