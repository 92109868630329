import React, { useState, useRef, useCallback, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import GroupIcon from '@material-ui/icons/Group';
import TreeItem from '@material-ui/lab/TreeItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import * as _usr_const from '../../config/usr-constant';
import * as _debug from '../../helper/debug';
import Loading from '../../components/View/Loading';
import axios from 'axios';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: '5px',
    }
  })
);

type AttributeGroup = {
  id: string;
  attribute_group_category_id: number;
  name: string;
}

type RenderTree = {
  id: string;
  name: string;
  children?: RenderTree[];
  attribute_groups?: AttributeGroup[];
}

type AttributeGroupSelectProps = {
  title?: string;
  name: string;
  handleSelectAttributeGroup: ((node: AttributeGroup) => void)
  required?: boolean;
  buttonText?: string;
  validateErrors?:  {[key: string]: string[]};
  value?: number;
}

function AttributeGroupSelect({
  title,
  name,
  handleSelectAttributeGroup,
  required,
  buttonText = '選択',
  validateErrors,
  value
}: AttributeGroupSelectProps) {

  const classes = useStyles();

  const unmounted = useRef(false);
  const source = useRef(axios.CancelToken.source());
  const refValue = useRef(value);

  const [isInit, setInit] = useState(true);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [attributeGroups, setAttributeGroups] = useState([]);
  const [attributeGroupsError, setAttributeGroupsError] = useState(false);
  const [selectedName, setSelectedName] = useState('');
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const isRequired: boolean = required === undefined ? false : required;
  
  let isTitle: string = '';
  if (title !== undefined) {
    isTitle = isRequired ? title + ' *' : title; 
  }

  const valueAttribute = useCallback((list: any) => {
    let isSetValue = false;
    const callbackFunc = (group: any) => {
      if (Array.isArray(group.attribute_groups)) {
        group.attribute_groups.forEach((ag: any) => {
          if (isSetValue === false && ag.id === refValue.current) {
            setSelectedName(ag.name);
            isSetValue = true;
          }
        });
      }
      if (isSetValue === false) {
        if (Array.isArray(group.children)) {
          group.children.forEach((children: any) => {
            callbackFunc(children);
          });
        }
      }
    }
    if (Array.isArray(list)) {
      list.forEach((group: any) => {
        if (isSetValue === false) {
          callbackFunc(group); 
        }
      });
    }
  }, [setSelectedName]);

  const getAttributeGroups = useCallback(async() => {
    await axios
      .get(
        _usr_const.ApiUrl + 'attribute-groups/index.json', {
        cancelToken: source.current.token
      }
      )
      .then((response) => {
        if (typeof response.data === 'object') {
          if (!unmounted.current) {
            setAttributeGroups(response.data.list);
            if (refValue.current !== undefined) {
              valueAttribute(response.data.list);
            }
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        _debug.debugAxiosError(error);
        if (!unmounted.current) {
          setAttributeGroupsError(true);
        }
      })
      .finally(() => {
        setLoading(false);
        return Promise.resolve(1);
      });
  }, [source, unmounted, valueAttribute]);

  const handleClose = () => {
    setShowModal(false);
  }

  const handleClickAttributeGroup = (node: AttributeGroup) => {
    setShowModal(false);
    handleSelectAttributeGroup(node);
    setSelectedName(node.name);
  }

  const renderTree = (nodes: RenderTree) => {
    const nodeID: string = nodes.id.toString();
    return (
      <TreeItem
      key={nodes.id}
      nodeId={nodeID}
      label={nodes.name}
      className={classes.root}
      >
        {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
        {
          Array.isArray(nodes.attribute_groups) &&
          nodes.attribute_groups.map((agNode: AttributeGroup) => (
            <TreeItem
            key={'ag-' + agNode.id}
            nodeId={'ag-' + agNode.id.toString()}
            label={agNode.name}
            icon={<GroupIcon />}
            onClick={() => handleClickAttributeGroup(agNode)}
            className={classes.root}
            >
            </TreeItem>
          ))
        }
      </TreeItem>
    )
  }

  const getRootClassNames = (): string => {
    if (errorMessages.length > 0) {
      return "attribute-group-select-root attribute-group-select-root-er";
    }
    return "attribute-group-select-root";
  }

  // clean up
  useEffect(() => {
    const clSource = Object.assign({}, source.current);
    return () => {
      // cancel axios get
      clSource.cancel('cancel attribute groups get');
      unmounted.current = true;
    }
  }, []);

  useEffect(() => {
    if (isInit) {
      const initFunc = async () => {
        await getAttributeGroups();
      }
      initFunc();
      setInit(false);
    }
  }, [isInit, getAttributeGroups]);

  useEffect(() => {
    if (validateErrors !== undefined && validateErrors[name] !== undefined) {
      setErrorMessages(validateErrors[name]);
    } else {
      setErrorMessages([]);
    }
  }, [validateErrors, name]);

  return (
    <div>
      <FormControl
        fullWidth
        className={getRootClassNames()}
        required={isRequired}
        error={errorMessages.length > 0}
      >
        {
          isTitle !== '' &&
          <span className="attribute-group-select-title MuiFormLabel-root">{isTitle}</span>
        }
        <div className="attribute-group-select-toggle-btn">
          <Button
            variant="outlined"
            size="small"
            onClick={() => { setShowModal(true) }}
          >
            {buttonText}
          </Button>
          <span className="attribute-group-select-selected-name">{selectedName}</span>
        </div>
        {
        errorMessages.length > 0 &&
        <span className="zsh-ttribute-group-select-er-wr">
        {
          errorMessages.map((msg: string, index: number) => (
            <span key={name + '-erSeMsg-' + index} className="zsh-ttribute-group-select-er-msg input-er-msg">
              <FormHelperText>{msg}</FormHelperText><br/>
            </span>
          ))
        }
        </span>
      }
      </FormControl>
      <Dialog
        open={showModal}
        onClose={handleClose}
      >
        <DialogTitle>属性グループ</DialogTitle>
        <DialogContent>
          <div className="attribute-group-select-content">
            <Loading loading={loading} />
            {
              attributeGroupsError === false &&
              <TreeView
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              >
                {
                  attributeGroups.length > 0 &&
                  attributeGroups.map((node: RenderTree) => (
                    renderTree(node)
                  ))
                }
              </TreeView>
            }
            {
              attributeGroupsError &&
              <div>
                <p>データ取得に失敗しました。ページのリロードをお願いします。</p>
              </div>
            }
          </div>
          <DialogActions>
            <Button onClick={handleClose} color="primary">閉じる</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default AttributeGroupSelect;