import React from 'react';
import Button from '@material-ui/core/Button';

type Props = {
  history: any;
  errorStatus: number;
  errorMsg: string;
}

function ErrorView({ errorStatus, errorMsg, history }: Props) {

  const historyBack = () => {
    history.goBack();
  }

  return (
    <div className="error-view">
      <p>{errorMsg}</p>
      <Button variant="contained" className="btn" onClick={historyBack}>戻る</Button>
    </div>
  )
}

export default ErrorView;