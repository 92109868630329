import React, { useState, useEffect } from 'react';
import FormControl from '@material-ui/core/FormControl';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import FormHelperText from '@material-ui/core/FormHelperText';

type ZshEditor = {
  name: string;
  onChange: (editorContent: string) => void;
  title?: string;
  value?: string | undefined;
  note?: string;
  required?: boolean;
  validateErrors?: {[key: string]: string[]};
}

function ZshEditor({
  name,
  onChange,
  title,
  value,
  note,
  required = false,
  validateErrors
}: ZshEditor) {

  const [isInit, setInit] = useState(true);
  const [data, setData] = useState(EditorState.createEmpty());
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const handleChange = (editorState:any) => {
    setData(editorState);
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    if (onChange !== undefined) {
      onChange(draftToHtml(rawContentState));
    }
  }

  useEffect(() => {    
    if (value !== undefined && isInit === true) {
      const blocksFromHtml = htmlToDraft(value);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      const editorState = EditorState.createWithContent(contentState);
      setData(editorState)
      setInit(false);
    } 
  }, [value, isInit, setData, setInit]);

  useEffect(() => {
    if (validateErrors !== undefined && validateErrors[name] !== undefined) {
      setErrorMessages(validateErrors[name]);
    } else {
      setErrorMessages([]);
    }
  }, [validateErrors, name]);

  return (
    <div>
      <FormControl
      fullWidth
      className="zsh-select-root"
      required={required}
      >
        {
          title !== undefined &&
          <span className="editor-title MuiFormLabel-root">{title}</span>
        }
        {
          data !== undefined &&
          <div>
            <Editor
            editorState={data}
            onEditorStateChange={handleChange}
            wrapperClassName="zsh-editor-wr"
            editorClassName="zsh-editor"
            toolbarClassName="zsh-editor-toolbar"
            />
          </div>
        }
        {
          note !== undefined &&
          <p className="editor-note">{note}</p>
        }
        {
          errorMessages.length > 0 &&
          <span className="zsh-editor-field-er-wr">
            {
              errorMessages.map((msg: string, index: number) => (
                <span key={name + '-erSeMsg-' + index} className="zsh-editor-field-er-msg">
                  <FormHelperText className="error">{msg}</FormHelperText><br />
                </span>
              ))
            }
          </span>
        }
      </FormControl>
    </div>
  )
}

export default ZshEditor;