import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps, Link } from 'react-router-dom'
import { useSetRecoilState } from 'recoil';
import { overlayProppress } from '../../atoms/OverlayProgress';
import { flashMessageSuccess, flashMessageError } from '../../atoms/FlashMessage';
import * as _usr_const from '../../config/usr-constant';
import * as _view_action from '../../helper/viewAction';
import * as _filter from '../../helper/filter';
import * as _form from '../../helper/form';
import * as _debug from '../../helper/debug';
import { mailMagazineStatusIcon } from './func/MailMagazinesCommon';
import axios from 'axios';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Loading from '../../components/View/Loading';
import HeadButtonGroups, { HeadButtonGroupBtnActionsProps } from '../../components/View/HeadButtonGroups';
import ZshLogs from '../../components/View/ZshLogs';
import ErrorView from '../../components/View/ErrorView';
import MailLogs from '../../components/View/MailLogs';
import EmailContent from '../../components/View/EmailContent';
import ZshTagInput from '../../components/Form/ZshTagInput';

type MailMagazineDataProps = {
  id: number;
  common_parent_id: number;
  mail_magazine_group_id: number;
  status: string;
  status_display: string;
  subject: string;
  content: string;
  mail_magazine_group: {
    name?: string;
  };
  send_date: string;
  objects_number: number;
  sent_number: number;
  sent_error_number: number;
  sent_open_number_display: string;
  is_change: boolean;
  displays: {
    status: string;
  };
  common_parent: any;
}
function MailMagazinesView({ history, match }: RouteComponentProps<{ id: string }>) {

  const setOverlayProppress = useSetRecoilState(overlayProppress);
  const setFlashMessageSuccess = useSetRecoilState(flashMessageSuccess);
  const setFlashMessageError = useSetRecoilState(flashMessageError);

  const authInformationsSelector: any = useSelector(state => state.AuthInformations);

  const unmounted = useRef(false);
  const source = useRef(axios.CancelToken.source());

  const [isInit, setInit] = useState(true);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<MailMagazineDataProps>({
    id: 0,
    common_parent_id: 0,
    mail_magazine_group_id: 0,
    status: '',
    status_display: '',
    subject: '',
    content: '',
    mail_magazine_group: {
      name: ''
    },
    send_date: '',
    objects_number: 0,
    sent_number: 0,
    sent_error_number: 0,
    sent_open_number_display: '',
    is_change: false,
    displays: {
      status: ''
    },
    common_parent: {}
  });
  const [dataMounted, setDataMounted] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [errorStatus, setErrorStatus] = useState(0);
  const [btnActions, setBtnActions] = useState<HeadButtonGroupBtnActionsProps[]>([]);
  const [formData, setFormData] = useState({
    to_emails: ''
  });

  const handleTestMailSubmit = (event: any) => {
    event.preventDefault();
    _form.handleSubmit({
      action: `mail-magazines/send-test-mail/${match.params.id}`,
      formData,
      history,
      backActionName: `/mail-magazines/${match.params.id}`,
      setOverlayProppress,
      setFlashMessageSuccess,
      setFlashMessageError,
      successFlashMessage: 'テストメールを送信しました',
      errorFlashMessage: 'テストメールを送信出来ませんでした'
    });
  }

  const getData = useCallback(() => {
    _view_action.getViewData<MailMagazineDataProps>({
      unmounted,
      source,
      dataKey: 'mailMagazine',
      path: _usr_const.ApiUrl + 'mail-magazines/view/' + match.params.id + '/index.json',
      setData,
      setDataMounted,
      setErrorStatus,
      setErrorMsg,
      setLoading,
      successCallbackAftereSetData: (results: any) => {
        let tmpBtnActions: HeadButtonGroupBtnActionsProps[] = [];
        if (results.data['mailMagazine'] !== undefined && results.data['mailMagazine'].is_change === true) {
          tmpBtnActions.push({
            type: 'edit',
            func: (): void => {
              history.push('/mail-magazines/' + match.params.id + '/edit');
            },
            role: {
              name: 'MailMagazines',
              action: 'edit',
            },
          });
        }
        tmpBtnActions.push({
          text: 'コピー',
          func: () => {
            setOverlayProppress(true);
            axios
              .post(
                _usr_const.ApiUrl + 'mail-magazines/copy/' + match.params.id,
                {},
              )
              .then((response: any) => {
                setFlashMessageSuccess('コピーしました');
                getData()
              })
              .catch((error) => {
                _debug.debugAxiosError(error);
                if (error.response.data[0] !== undefined) {
                  setFlashMessageError(error.response.data[0]);
                } else {
                  setFlashMessageError('コピー出来ませんでした');
                }
              })
              .finally(() => {
                setOverlayProppress(false);
                history.push('/mail-magazines');
              });
          },
          role: {
            name: 'MailMagazines',
            action: 'add',
          },
        });
        setBtnActions(tmpBtnActions);
      }
    })
    .catch((error) => {
      _debug.debugAxiosError(error);
    });
  },
  [
    unmounted,
    source,
    match.params.id,
    history,
    setFlashMessageError,
    setFlashMessageSuccess,
    setOverlayProppress
  ]);

  // clean up
  useEffect(() => {
    const clSource = Object.assign({}, source.current);
    return () => {
      // cancel axios get
      clSource.cancel('cancel mail magazine get');
      unmounted.current = true;
    }
  }, []);

  useEffect(() => {
    if (isInit) {
      setLoading(true);
      getData();
      setInit(false);
    }
  }, [isInit, match.params.id, getData]);

  useEffect(() => {
    if (authInformationsSelector !== undefined) {
      authInformationsSelector.forEach((element: any) => {
        if (element.isLoded) {
          if (element.error === undefined && element.data.auth_informations !== undefined) {
            setFormData({ to_emails: element.data.auth_informations.username });
          }
        }
      });
    }
  }, [authInformationsSelector]);

  return (
    <div className="content-1">
      <Loading loading={loading} />
      {
        dataMounted &&
        <div>
          <HeadButtonGroups
            history={history}
            btnActions={btnActions}
          />
          <Paper>
            <div className="view-content-in">
              <table className="table-1">
                <tbody>
                  <tr>
                    <th>ID</th>
                    <td>{data.id}</td>
                  </tr>
                  <tr>
                    <th>状況</th>
                    <td>
                      <span>
                        <span>
                          {mailMagazineStatusIcon({ status: data.status })}
                        </span>
                        {data.displays.status}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th>メールタイトル</th>
                    <td>{data.subject}</td>
                  </tr>
                  <tr>
                    <th>メール本文</th>
                    <td>
                      <EmailContent content={data.content} />
                    </td>
                  </tr>
                  <tr>
                    <th>メルマガグループ</th>
                    <td>
                      {
                        data.mail_magazine_group !== null && data.mail_magazine_group.name !== undefined &&
                        <Link
                          to={`/mail-magazine-groups/${data.mail_magazine_group_id}`}
                        >
                          {data.mail_magazine_group.name}
                        </Link>
                      }
                    </td>
                  </tr>
                  <tr>
                    <th>配信日時</th>
                    <td>{_filter.ShFilter(data.send_date, 'YMDHm')}</td>
                  </tr>
                  {
                    data.status === 'finished' &&
                    <tr>
                      <th>配信対象数</th>
                      <td>{data.objects_number.toString()}</td>
                    </tr>
                  }
                  {
                    data.status === 'finished' &&
                    <tr>
                      <th>配信数</th>
                      <td>{data.sent_number.toString()}</td>
                    </tr>
                  }
                  {
                    data.status === 'finished' &&
                    <tr>
                      <th>配信エラー数</th>
                      <td>{data.sent_error_number.toString()}</td>
                    </tr>
                  }
                  {
                    data.status === 'finished' &&
                    <tr>
                      <th>開封数</th>
                      <td>{data.sent_open_number_display}</td>
                    </tr>
                  }
                </tbody>
              </table>
              {
                data.is_change &&
                <form onSubmit={handleTestMailSubmit}>
                  <div
                  style={{ marginTop: '40px' }}
                  className="form-content"
                  >
                      <div className="form-input-group">
                        <ZshTagInput
                          inputName="to_emails"
                          title="テストメール送信先"
                          placeholder="メールアドレス追加"
                          formData={formData}
                          setFormData={setFormData}
                        />
                      </div>
                  </div>
                  <Button
                  type="submit"
                  variant="contained"
                  className="btn"
                  color="default"
                  >
                    テストメールを送信
                  </Button>
                </form>
              }
            </div>
          </Paper>
          <MailLogs
            objectCommonParentId={data.common_parent_id}
            history={history}
            type="MailMagazines"
          />
          <ZshLogs logs={data.common_parent.logs} />
        </div>
      }
      {
        errorMsg !== '' &&
        <div>
          <ErrorView
            history={history}
            errorStatus={errorStatus}
            errorMsg={errorMsg}
          />
        </div>
      }
    </div>
  )
}

export default MailMagazinesView;