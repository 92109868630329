import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl     from '@material-ui/core/FormControl';
import OutlinedInput   from '@material-ui/core/OutlinedInput';
import IconButton      from '@material-ui/core/IconButton';
import InputAdornment  from '@material-ui/core/InputAdornment';
import AssignmentIcon  from '@material-ui/icons/Assignment';
import Tooltip         from '@material-ui/core/Tooltip';
import CopyToClipBoard from 'react-copy-to-clipboard';

type ReplaceParamsProps = {
  display: string;
}

type CustomerReplaceParamsProps = {
  replace_params: {
    [key: string]: ReplaceParamsProps
  };
  leftReplaceSymbol: string;
  rightReplaceSymbol: string;
}

function CustomerReplaceParams({
  replace_params,
  leftReplaceSymbol,
  rightReplaceSymbol
}: CustomerReplaceParamsProps) {

  const [showModal, setShowModal] = useState<boolean>(false);
  const [tips, setTips] = useState<{[key: string]: boolean}>({});

  const handleCloseTip = (field: string): void => {
    setTips({ ...tips, [field]: false });
  };

  const handleClickButton = (field: string): void => {
    setTips({ ...tips, [field]: true });
  };

  useEffect(() => {
    let tmpTips: {[key: string]: boolean} = {};
    Object.keys(replace_params).forEach((field: string) => {
      tmpTips[field] = false;
    });
    setTips(tmpTips);
  }, [replace_params]);

  return (
    <div style={{ marginBottom: '20px' }}>
      <Button
      variant="contained"
      className="btn"
      onClick={() => setShowModal(true)}
      >
        埋込凡例
      </Button>
      <Dialog
        fullWidth={true}
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">埋込凡例</DialogTitle>
        <DialogContent>
          <table className="table-1">
            <tbody>
              {
                Object.keys(replace_params).map((field: string, index: number) => (
                  <tr key={`customerParams-${field}`}>
                    <th>{replace_params[field].display}</th>
                    <td>
                      <FormControl
                        variant='outlined'
                      >
                        <OutlinedInput
                          type='text'
                          value={leftReplaceSymbol + field + rightReplaceSymbol}
                          readOnly
                          endAdornment={
                            <InputAdornment position="end">
                              <Tooltip
                                arrow
                                open={tips[field] === undefined ? false : tips[field]}
                                onClose={() => handleCloseTip(field)}
                                disableHoverListener
                                placement='top'
                                title='Copied!'
                              >
                                <CopyToClipBoard text={leftReplaceSymbol + field + rightReplaceSymbol}>
                                  <IconButton
                                    onClick={() => handleClickButton(field)}
                                  >
                                    <AssignmentIcon />
                                  </IconButton>
                                </CopyToClipBoard>
                              </Tooltip>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowModal(false)} color="default">
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default CustomerReplaceParams;