import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import HeaderAuthInformations from '../components/Layout/HeaderAuthInformations';

const lightColor: string = 'rgba(255, 255, 255, 0.7)';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    secondaryBar: {
      zIndex: 0,
    },
    menuButton: {
      marginLeft: -theme.spacing(1),
    },
    iconButtonAvatar: {
      padding: 4,
    },
    link: {
      textDecoration: 'none',
      color: lightColor,
      '&:hover': {
        color: theme.palette.common.white,
      },
    },
    button: {
      borderColor: lightColor,
    },
  })
);

type Props = {
  onDrawerToggle: any;
  pageTitle: string;
}

function Header({ onDrawerToggle, pageTitle }: Props) {

  const classes = useStyles();

  return (
    <React.Fragment>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Hidden smUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <h1 className="h-1">{pageTitle}</h1>
              </Grid>
            </Hidden>
            <Grid item xs />
            <Grid item>
              <HeaderAuthInformations />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Hidden xlUp smDown>
        <AppBar
          component="div"
          className={classes.secondaryBar}
          color="primary"
          position="static"
          elevation={0}
        >
          <Toolbar>
            <Grid container alignItems="center" spacing={8}>
              <Grid item xs>
                <h1 className="h-1">{pageTitle}</h1>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Hidden>
    </React.Fragment>
  );
}

export default Header;
