import * as _usr_const from '../config/usr-constant';
import axios from 'axios'

export function getAuthRequest() {
  return {
    type: 'GET_AUTH_REQUEST'
  }
}

export function getAuthSuccess(json: any) {
  return {
    type: 'GET_AUTH_SUCCESS',
    data: json,
    receivedAt: Date.now()
  }
}

export function getAuthFailure(error: any) {
  return {
    type: 'GET_AUTH_FAILURE',
    error
  }
}

export function getAuth() {
  const apiPath = _usr_const.ApiUrl + 'users/get-auth-informations/index.json';
  return (dispatch: any) => {
    dispatch(getAuthRequest())
    return axios.get(apiPath)
      .then(res =>
        dispatch(getAuthSuccess(res.data))
      ).catch(err => {
        dispatch(getAuthFailure(err))
      }
    )
  }
}