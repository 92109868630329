import React from 'react';
import { Route, Switch } from 'react-router-dom';
import * as _const from '../config/constant';
import { router } from '../router';
import NotRole from '../view/notRole';
import NotFound from '../view/notFound';

type Props = {
  authRoles: any;
}

function Content({ authRoles }: Props) {
  const routeComponets = router.map((roteObject: any) => {
    // check role
    let thisAllow = false;
    if (_const.RoleAllow[roteObject.name] !== undefined && _const.RoleAllow[roteObject.name] === true) {
      thisAllow = true;
    } else {
      if (authRoles !== undefined && authRoles[roteObject.name] !== undefined) {
        if (authRoles[roteObject.name].indexOf(roteObject.roleAction) !== -1) {
          thisAllow = true;
        }
      }
    }
    // set component
    let roteComponent = roteObject.component;
    if (thisAllow === false) {
      roteComponent = NotRole;
    }
    return (
      <Route exact path={roteObject.path} component={roteComponent} key={roteObject.path} />
    );
  });

  return (
    <Switch>
      {routeComponets}
      <Route path="*" component={NotFound} />
    </Switch>
  )
}

export default Content;