import React, { useState, useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom'
import * as _usr_const from '../../config/usr-constant';
import * as _view_action from '../../helper/viewAction';
import axios from 'axios';
import Paper from '@material-ui/core/Paper';
import Loading from '../../components/View/Loading';
import HeadButtonGroups, { HeadButtonGroupBtnActionsProps } from '../../components/View/HeadButtonGroups';
import ZshLogs from '../../components/View/ZshLogs';
import ErrorView from '../../components/View/ErrorView';
import EmailContent from '../../components/View/EmailContent';

type AppSettingProps = {
  account_lock: number;
  page_limit: number;
  password_expiration_date: boolean;
  password_expiration_date_num: string;
  password_expiration_date_type: string;
  password_min_length: number;
  add_customer_notice_emails: string;
  tax_rate: number;
  email_notification: boolean;
  mail_from: string;
  mail_from_name: string;
  mail_footer: string;
  mail_stop_number: number;
  common_parent: any;
}

function AppSettingsView({ history, match }: RouteComponentProps<{ id: string }>) {

  const unmounted = useRef(false);
  const source = useRef(axios.CancelToken.source());

  const [isInit, setInit] = useState(true);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<AppSettingProps>({
    account_lock: 0,
    page_limit: 0,
    password_expiration_date: false,
    password_expiration_date_num: '',
    password_expiration_date_type: '',
    password_min_length: 0,
    add_customer_notice_emails: '',
    tax_rate: 0,
    email_notification: false,
    mail_from: '',
    mail_from_name: '',
    mail_footer: '',
    mail_stop_number: 0,
    common_parent: {},
  });
  const [dataMounted, setDataMounted] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [errorStatus, setErrorStatus] = useState(0);

  const formatAddCustomerNoticeEmails = (add_customer_notice_emails: string) => {
    return (
      <ul className="ul-1">
        {
          add_customer_notice_emails.split(',').map((email: string, index: number) => (
            <li key={'noticeEmail' + index}>{email}</li>
          ))
        }
      </ul>
    );
  }

  // clean up
  useEffect(() => {
    const clSource = Object.assign({}, source.current);
    return () => {
      // cancel axios get
      clSource.cancel('cancel app-settings get');
      unmounted.current = true;
    }
  }, []);

  useEffect(() => {
    if (isInit) {
      setLoading(true);
      _view_action.getViewData<AppSettingProps>({
        unmounted,
        source,
        dataKey: 'appSetting',
        path: _usr_const.ApiUrl + 'app-settings/index.json',
        setData,
        setDataMounted,
        setErrorStatus,
        setErrorMsg,
        setLoading
      })
      .catch((error: any) => {
        console.log(error);
      });
      setInit(false);
    }
  }, [isInit, match.params.id]);

  const btnActions: HeadButtonGroupBtnActionsProps[] = [
    {
      type: 'edit',
      func: (): void => {
        history.push('/app-settings/edit');
      }
    },
  ];

  return (
    <div className="content-1">
      <Loading loading={loading} />
      {
        dataMounted &&
        <div>
          <HeadButtonGroups
            history={history}
            btnActions={btnActions}
            hideBack={true}
          />
          <Paper>
            <div className="view-content-in">
              <table className="table-1">
                <tbody>
                  <tr>
                    <th>ロックアウト回数</th>
                    <td>{data.account_lock}</td>
                  </tr>
                  <tr>
                    <th>ページ毎の表示件数</th>
                    <td>{data.page_limit}</td>
                  </tr>
                  <tr>
                    <th>パスワード有効期限</th>
                    <td>
                      {
                        data.password_expiration_date &&
                        <span>
                          期限を設定：{data.password_expiration_date_num}
                          {
                            data.password_expiration_date_type === 'months' &&
                            <span>ヶ月</span>
                          }
                          {
                            data.password_expiration_date_type === 'years' &&
                            <span>年</span>
                          }
                        </span>
                      }
                      {
                        data.password_expiration_date === false &&
                        <span>
                          無期限
                        </span>
                      }
                    </td>
                  </tr>
                  <tr>
                    <th>パスワード文字数</th>
                    <td>英数字 {data.password_min_length} 文字以上</td>
                  </tr>
                  <tr>
                    <th>顧客登録通知先</th>
                    <td>{formatAddCustomerNoticeEmails(data.add_customer_notice_emails)}</td>
                  </tr>
                  <tr>
                    <th>税率</th>
                    <td>{data.tax_rate * 100}%</td>
                  </tr>
                  <tr>
                    <th>メール通知機能</th>
                    <td>{data.email_notification ? 'オン' : 'オフ'}</td>
                  </tr>
                </tbody>
              </table>
              <h2 className="h-3">メール設定</h2>
              <table className="table-1">
                <tbody>
                  <tr>
                    <th>From</th>
                    <td>{data.mail_from}</td>
                  </tr>
                  <tr>
                    <th>送信元名</th>
                    <td>{data.mail_from_name}</td>
                  </tr>
                  <tr>
                    <th>署名</th>
                    <td>
                      <EmailContent content={data.mail_footer} />
                    </td>
                  </tr>
                  <tr>
                    <th>停止回数</th>
                    <td>{data.mail_stop_number.toLocaleString()}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Paper>
          <ZshLogs logs={data.common_parent.logs} />
        </div>
      }
      {
        errorMsg !== '' &&
        <div>
          <ErrorView
            history={history}
            errorStatus={errorStatus}
            errorMsg={errorMsg}
          />
        </div>
      }
    </div>
  )
}

export default AppSettingsView;