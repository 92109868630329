import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { green } from '@material-ui/core/colors';

/**
 * mailMagazineStatusIcon
 * 
 */
type mailMagazineStatusIconProps = {
  status: string;
}
export function mailMagazineStatusIcon({ status }: mailMagazineStatusIconProps) {
  if (status === 'finished') {
    return (
      <span style={{ verticalAlign: 'middle' }}>
        <CheckCircleIcon style={{ color: green[500], fontSize: 15 }} />
      </span>
    );
  }
  if (status === 'stop') {
    return (
      <span style={{ verticalAlign: 'middle' }}>
        <RemoveCircleIcon style={{ fontSize: 15 }} />
      </span>
    );
  }
  if (status === 'error') {
    return (
      <span style={{ verticalAlign: 'middle' }}>
        <HighlightOffIcon color="secondary" style={{ fontSize: 15 }} />
      </span>
    );
  }
  return (
    <span></span>
  );
}