import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useSetRecoilState } from 'recoil';
import { overlayProppress } from '../../atoms/OverlayProgress';
import { flashMessageError, flashMessageSuccess } from '../../atoms/FlashMessage';
import * as _usr_const from '../../config/usr-constant';
import * as _form from '../../helper/form';
import * as _debug from '../../helper/debug';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import axios from 'axios'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ZshTextField from '../../components/Form/ZshTextField';

type AuthInformationsProps = {
  id: number | undefined;
  authority_display: string;
  full_name: string;
}
function HeaderAuthInformations() {

  const setOverlayProppress = useSetRecoilState(overlayProppress);
  const setFlashMessageSuccess = useSetRecoilState(flashMessageSuccess);
  const setFlashMessageError = useSetRecoilState(flashMessageError);

  const authInformationsSelector: any = useSelector(state => state.AuthInformations);

  const [isInit, setInit] = useState(true);
  const [authInformations, setAuthInformations] = useState<AuthInformationsProps>({
    id: undefined,
    authority_display: '',
    full_name: '',
  });
  const [error, setError] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [openEditPassword, setOpenEditPassword] = useState(false);
  const [editPasswordData, setEditPasswordData] = useState({
    new_password: '',
    new_password_confirm: ''
  });
  const [editPasswordErrors, setEditPasswordErrors] = useState<{[key: string]: string[]}>({
    new_password: [],
    new_password_confirm: []
  });

  const handleClickToggle = () => {
    setShowMore(showMore ? false : true);
  }

  const handleHeaderAuthMoreClose = (element: any) => {
    const headerElements = [
      'header-auth-informations-display',
      'header-auth-informations-more-ul'
    ];
    if (headerElements.indexOf(element.srcElement.parentElement.id) === -1) {
      setShowMore(false);
    }
  }

  const handleLogout = () => {
    setOverlayProppress(true);
    axios
      .get(
        _usr_const.ApiUrl + 'users/logout'
      )
      .then((response: any) => {
        localStorage.removeItem('shift_token');
        window.location.href = '/users/login';
      })
      .catch((error) => {
        _debug.debugAxiosError(error);
        setOverlayProppress(false);
        setFlashMessageError('エラーが発生しました');
      });
  }

  const handleCloseEditPassword = () => {
    setOpenEditPassword(false);
    setEditPasswordData({
      new_password: '',
      new_password_confirm: ''
    });
    setEditPasswordErrors({
      new_password: [],
      new_password_confirm: []
    });
  }

  const setInitAuthInformations = useCallback(() => {
    if (authInformationsSelector !== undefined) {
      authInformationsSelector.forEach((element: any) => {
        if (element.isLoded) {
          if (element.error === undefined && element.data.auth_informations !== undefined) {
            if (authInformations !== element.data.auth_informations) {
              setAuthInformations(element.data.auth_informations);
              setError(false);
            }
          } else {
            console.log(element.error);
            if (error === false) {
              if (typeof element.error.message !== 'undefined') {
                setFlashMessageError(element.error.message);
              }
              setError(true);
            }
          }
        }
      });
    }
  }, [authInformationsSelector, authInformations, error, setFlashMessageError]);

  const handleChangeEditPassword = (event: any) => {
    const inputName = event.target.name;
    setEditPasswordData({ ...editPasswordData, [inputName]: event.target.value });
  }

  const handleSubmitEditPassword = () => {
    let errors: {[key: string]: string[]} = {
      new_password: [],
      new_password_confirm: []
    };
    // check local
    if (editPasswordData.new_password === '') {
      errors.new_password.push('パスワードを入力して下さい');
    }
    if (editPasswordData.new_password_confirm === '') {
      errors.new_password_confirm.push('パスワード（確認）を入力して下さい');
    } else {
      if (editPasswordData.new_password !== editPasswordData.new_password_confirm) {
        errors.new_password_confirm.push('パスワードを確認して下さい');
      }
    }
    // post
    if (errors.new_password.length === 0 && errors.new_password_confirm.length === 0) {
      setOverlayProppress(true);    
      axios
      .post(
        _usr_const.ApiUrl + 'users/edit-password/' + authInformations.id,
        editPasswordData,
      )
      .then((response: any) => {
        setFlashMessageSuccess('パスワードを変更しました');
        handleCloseEditPassword();
      })
      .catch((error) => {
        _debug.debugAxiosError(error);
        if (error.response) {
          // validate error
          if (error.response.status === 400) {
            setEditPasswordErrors(_form.formatValidateError(error));
          }
        }
        setFlashMessageError('パスワードを変更出来ませんでした');
      })
      .finally(() => {
        setOverlayProppress(false);
      });
    } else {
      setEditPasswordErrors(errors);
    }
  }

  useEffect(() => {
    return () => {
      document.body.removeEventListener('click', handleHeaderAuthMoreClose);
    }
  }, []);

  useEffect(() => {
    if (isInit) {
      document.body.addEventListener('click', handleHeaderAuthMoreClose);
      setInit(false);
    }
  }, [isInit, setInitAuthInformations]);

  useEffect(() => {
    setInitAuthInformations();
  }, [setInitAuthInformations, authInformationsSelector]);

  let authority_display: string = '';
  if (authInformations.authority_display !== undefined) {
    authority_display = authInformations.authority_display;
  }

  let full_name: string = '';
  if (authInformations.full_name !== undefined) {
    full_name = authInformations.full_name;
  }

  const moreClassName: string = showMore ? 'showMore' : 'hideMore';

  return (
    <div id="header-auth-informations">
      {
        error &&
        <div>
          Connect Error
        </div>
      }
      {
        error === false &&
        <div id="header-auth-informations-in">
          <div id="header-auth-informations-display" onClick={handleClickToggle}>
            <span id="Hai-authority-display">{authority_display}</span>
            <span id="Hai-fullname">{full_name}</span>
            <span id="Hai-showMore">
              {
                showMore &&
                <ExpandLess />
              }
              {
                showMore === false &&
                <ExpandMore />
              }
            </span>
          </div>
          <div id="header-auth-informations-more" className={moreClassName}>
            <ul id="header-auth-informations-more-ul">
              <li><span onClick={() => { setOpenEditPassword(true) }}>パスワード変更</span></li>
              <li>
                <span onClick={handleLogout}>ログアウト</span>
              </li>
            </ul>
          </div>
        </div>
      }
      <Dialog
        open={openEditPassword}
        onClose={handleCloseEditPassword}
      >
        <DialogTitle>パスワード変更</DialogTitle>
        <DialogContent className="header-auth-edit-password">
          <div className="form-content">
            <div className="form-input-group">
              <ZshTextField
                margin="dense"
                name="new_password"
                value={editPasswordData.new_password}
                label="パスワード"
                type="password"
                fullWidth
                onChange={handleChangeEditPassword}
                validateErrors={editPasswordErrors}
              />
            </div>
            <div className="form-input-group">
              <ZshTextField
                margin="dense"
                name="new_password_confirm"
                value={editPasswordData.new_password_confirm}
                label="パスワード（確認）"
                type="password"
                fullWidth
                onChange={handleChangeEditPassword}
                validateErrors={editPasswordErrors}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditPassword} color="default">閉じる</Button>
          <Button onClick={handleSubmitEditPassword} color="primary" autoFocus>変更</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default HeaderAuthInformations;