import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Help from '@material-ui/icons/Help';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const useStyles = makeStyles(() =>
  createStyles({
    tooltip: {
      // backgroundColor: theme.palette.common.black,
      fontSize: '12px',
    },
    icon: {
      fontSize: '17px',
      cursor: 'pointer',
    }
  })
);

type Props = {
  text: any;
}

function ZshTooltip({ text }: Props) {

  let showText: any = text;

  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  if (text.indexOf('¥n') !== -1) {
    showText = text.split('¥n').map((line: string, index: number) => (
      <span key={index}>
        {line}<br />
      </span>
    ));
  }

  const classes = useStyles();

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div className="zshTooltip">
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={
            <React.Fragment>
              {showText}
            </React.Fragment>
          }
          placement="bottom-start"
          classes={{
            tooltip: classes.tooltip,
          }}
        >
          <Help
          className={classes.icon}
          onClick={handleTooltipOpen}
          />
        </Tooltip>
      </div>
    </ClickAwayListener>
  )
}

export default ZshTooltip;