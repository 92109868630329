import React, { useState, useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import { overlayProppress } from '../../atoms/OverlayProgress';
import { flashMessageSuccess, flashMessageError } from '../../atoms/FlashMessage';
import { RouteComponentProps } from 'react-router-dom';
import * as _form from '../../helper/form';
import * as _mailMagazineGroupsForm from './func/MailMagazineGroupsForm';
import * as _usr_const from '../../config/usr-constant';
import AttributeGroupSelect from '../../components/Form/AttributeGroupSelect';
import Paper from '@material-ui/core/Paper';
import Loading from '../../components/View/Loading';
import HeadButtonGroups, { HeadButtonGroupBtnActionsProps } from '../../components/View/HeadButtonGroups';
import ZshTextField from '../../components/Form/ZshTextField';
import AjaxSelect from '../../components/Form/AjaxSelect';
import axios from 'axios';

function MailMagazineGroupsEdit({ history, match }: RouteComponentProps<{ id: string }>) {

  const setOverlayProppress = useSetRecoilState(overlayProppress);
  const setFlashMessageSuccess = useSetRecoilState(flashMessageSuccess);
  const setFlashMessageError = useSetRecoilState(flashMessageError);

  const actionName = 'mail-magazine-groups/edit/';
  const backActionName = '/mail-magazine-groups';

  const unmounted = useRef(false);
  const source = useRef(axios.CancelToken.source());
  const dataSource = useRef(axios.CancelToken.source());

  const [isInit, setInit] = useState(true);
  const [dataMounted, setDataMounted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    name: '',
    attribute_group_id: 0,
    from_name: '',
    mail_from_id: '',
    signature_id: '',
    reply_to: ''
  });
  const [validateErrors, setValidateErrors] = useState({});
  const [formParams, setFormParams] = useState({
    mailFroms: {},
    signatures: {}
  });
  const [formParamsError, setFormParamsError] = useState(false);

  const handleChange = (event: any) => {
    const inputName = event.target.name;
    const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    setFormData({ ...formData, [inputName]: value });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    _form.handleSubmit({
      action: actionName + match.params.id,
      formData,
      history,
      backActionName,
      setOverlayProppress,
      setFlashMessageSuccess,
      setFlashMessageError,
      setValidateErrors
    });
  }

  const handleSelectAttributeGroup = (node: any) => {
    setFormData({ ...formData, attribute_group_id: node.id });
  }

  // clean up
  useEffect(() => {
    const clSource = Object.assign({}, source.current);
    const clDSource = Object.assign({}, dataSource.current);
    return () => {
      // cancel axios get
      clSource.cancel('cancel mail magazine groups form attribute groups get');
      clDSource.cancel('cancel mail magazine groups form data get');
      unmounted.current = true;
    }
  }, []);

  useEffect(() => {
    if (isInit) {
      const initFunc = async () => {
        await _mailMagazineGroupsForm.getMailMagazineGroupsFormParams({
          unmounted: unmounted.current,
          setFormParams,
          setFormParamsError,
          source
        });
        if (dataMounted === false) {
          const tepFormData = Object.assign({}, formData);
          await axios
            .get(_usr_const.ApiUrl + actionName + match.params.id + '/index.json', {
              cancelToken: dataSource.current.token
            })
            .then((results: any) => {
              if (!unmounted.current) {
                const formData = _form.setFormData(tepFormData, results.data.mailMagazineGroup);
                setFormData(formData);
              }
            })
            .finally(() => {
              if (!unmounted.current) {
                setLoading(false);
                setDataMounted(true);
              }
              return Promise.resolve(1);
            });
        }
        await ((): any => {
          setLoading(false);
          return Promise.resolve(1);
        })();
      }
      initFunc();
      setInit(false);
    }
  }, [isInit, dataMounted, formData, match.params.id]);

  const btnActions: HeadButtonGroupBtnActionsProps[] = [
    {
      type: 'save',
    }
  ];

  return (
    <div className="content-1">
      <form onSubmit={handleSubmit}>
        <Loading loading={loading} />
        {
          dataMounted &&
          <div>
            <HeadButtonGroups
              history={history}
              btnActions={btnActions}
            />
            <Paper>
              <div className="view-content-in form-content">
              <table className="form-table">
                  <tbody>
                    <tr>
                      <th className="required-th">メルマガグループ名</th>
                      <td>
                        <div className="form-input-group">
                          <ZshTextField
                            value={formData.name}
                            name="name"
                            onChange={handleChange}
                            required
                            validateErrors={validateErrors}
                            fullWidth
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th className="required-th">配信先の属性グループ</th>
                      <td>
                        <div className="form-input-group">
                          <AttributeGroupSelect
                            name="attribute_group_id"
                            handleSelectAttributeGroup={handleSelectAttributeGroup}
                            validateErrors={validateErrors}
                            required
                            value={formData.attribute_group_id}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th className="required-th">From</th>
                      <td>
                        <div className="form-input-group">
                          <AjaxSelect
                            name="mail_from_id"
                            menuItems={formParams.mailFroms}
                            formData={formData}
                            handleChange={handleChange}
                            getError={formParamsError}
                            validateErrors={validateErrors}
                            required
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th className="required-th">送信者名</th>
                      <td>
                        <div className="form-input-group">
                          <ZshTextField
                            value={formData.from_name}
                            name="from_name"
                            onChange={handleChange}
                            required
                            validateErrors={validateErrors}
                            fullWidth
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>署名</th>
                      <td>
                        <div className="form-input-group">
                          <AjaxSelect
                            name="signature_id"
                            menuItems={formParams.signatures}
                            formData={formData}
                            handleChange={handleChange}
                            getError={formParamsError}
                            validateErrors={validateErrors}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Reply To</th>
                      <td>
                        <div className="form-input-group">
                          <ZshTextField
                            value={formData.reply_to}
                            name="reply_to"
                            onChange={handleChange}
                            validateErrors={validateErrors}
                            fullWidth
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Paper>
            <HeadButtonGroups
              history={history}
              btnActions={btnActions}
            />
          </div>
        }
      </form>
    </div>
  )
}

export default MailMagazineGroupsEdit;