import React, { useState, useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import { overlayProppress } from '../../atoms/OverlayProgress';
import { flashMessageSuccess, flashMessageError } from '../../atoms/FlashMessage';
import { RouteComponentProps } from 'react-router-dom';
import * as _form from '../../helper/form';
import Paper from '@material-ui/core/Paper';
import Loading from '../../components/View/Loading';
import HeadButtonGroups, { HeadButtonGroupBtnActionsProps } from '../../components/View/HeadButtonGroups';
import ZshTextField from '../../components/Form/ZshTextField';

function MailFromsAdd({ history }: RouteComponentProps) {

  const setOverlayProppress = useSetRecoilState(overlayProppress);
  const setFlashMessageSuccess = useSetRecoilState(flashMessageSuccess);
  const setFlashMessageError = useSetRecoilState(flashMessageError);

  const actionName = 'mail-froms/add/';
  const backActionName = '/mail-froms';

  const unmounted = useRef(false);

  const [isInit, setInit] = useState(true);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    email: '',
  });
  const [validateErrors, setValidateErrors] = useState({});

  const handleChange = (event: any) => {
    const inputName = event.target.name;
    const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    setFormData({ ...formData, [inputName]: value });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    _form.handleSubmit({
      action: actionName,
      formData,
      history,
      backActionName,
      setOverlayProppress,
      setFlashMessageSuccess,
      setFlashMessageError,
      setValidateErrors
    });
  }

  // clean up
  useEffect(() => {
    return () => {
      unmounted.current = true;
    }
  }, []);

  useEffect(() => {
    if (isInit) {
      const initFunc = async () => {
        await ((): any => {
          setLoading(false);
          return Promise.resolve(1);
        })();
      }
      initFunc();
      setInit(false);
    }
  }, [isInit]);

  const btnActions: HeadButtonGroupBtnActionsProps[] = [
    {
      type: 'save',
    }
  ];

  return (
    <div className="content-1">
      <form onSubmit={handleSubmit}>
        <Loading loading={loading} />
        {
          loading === false &&
          <div>
            <HeadButtonGroups
              history={history}
              btnActions={btnActions}
            />
            <Paper>
              <div className="view-content-in form-content">
                <table className="form-table">
                  <tbody>
                    <tr>
                      <th className="required-th">From</th>
                      <td>
                        <div className="form-input-group">
                          <ZshTextField
                            value={formData.email}
                            name="email"
                            onChange={handleChange}
                            required
                            validateErrors={validateErrors}
                            fullWidth
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Paper>
            <HeadButtonGroups
              history={history}
              btnActions={btnActions}
            />
          </div>
        }
      </form>
    </div>
  )
}

export default MailFromsAdd;