import { DataTableRowsProps } from '../components/View/DataTable';

export const ApiUrl = process.env.REACT_APP_API_URL;
export const UserRoleAllow = {};
export const UserMenus = [];
export const setloginRedirect = '';

export const customerRows: DataTableRowsProps[] = [
  {
    label: 'No',
    value: 'primary_number',
    sortField: 'Customers.primary_number',
    align: 'left',
  },
  {
    label: '顧客番号',
    value: 'customer_number',
    sortField: 'Customers.customer_number',
    align: 'left'
  },
  {
    label: '氏名',
    value: 'full_name',
    align: 'left',
  },
  {
    label: 'ステータス',
    value: 'status',
    align: 'left',
  },
  {
    label: '登録日時',
    value: 'created',
    filter: 'YMDHm',
    sortField: 'Customers.created',
    align: 'left',
  },
  {
    label: '更新日時',
    value: 'modified',
    filter: 'YMDHm',
    sortField: 'Customers.modified',
    align: 'left',
  },
];