import React from 'react';
import '../../scss/NormalizeEmail.scss';

type EmailContentProps = {
  content: string;
}

function EmailContent({ content }: EmailContentProps) {
  return (
    <div className="email-content">
      <span dangerouslySetInnerHTML={{ __html: content }}></span>
    </div>
  )
}

export default EmailContent;