import React, { useState, useEffect, useCallback, createContext } from 'react';
import { useSetRecoilState } from 'recoil';
import { flashMessageError } from '../atoms/FlashMessage';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import * as _const from '../config/constant';
import * as AuthInformationsActions from '../actions/AuthInformations';
import Paperbase from './Paperbase';
import UsersLogin from '../view/Users/UsersLogin';
import CircularProgress from '@material-ui/core/CircularProgress';
import FlashMessage from '../components/FlashMessage';

let theme = createMuiTheme({
  typography: {
    // useNextVariants: true,
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  palette: {
    primary: {
      light: '#63ccff',
      main: '#009be5',
      dark: '#006db3',
    },
  },
  shape: {
    borderRadius: 8,
  },
});

const loginPath: string = '/users/login';
export const LoginPathContext = createContext('/users/login');

type ParentParams = {
  init?: boolean;
}

type ParentComponentProps = RouteComponentProps & ParentParams;

const Parent: React.FC<ParentComponentProps> = ({ history, location, init = true }) => {

  const dispatch = useDispatch();

  const setFlashMessageError = useSetRecoilState(flashMessageError);

  const AuthInformations: any = useSelector(state => state.AuthInformations);
  const AuthRoles: any = useSelector(state => state.AuthRoles);

  const [isInit, setInit] = useState(init);

  const [authState, setAuthState] = useState({
    setAuthInformations: false,
    isAuth: false
  });

  const checkAuth = useCallback(() => {
    if (authState.setAuthInformations === false) {
      // init check auth
      if (AuthInformations !== undefined) {
        let isAuth = false;
        AuthInformations.forEach((element: any) => {
          if (element.isLoded) {
            if (element.error === undefined) {
              isAuth = true;
            } else {
              if (element.error.response !== undefined) {
                if (element.error.response.status >= 500) {
                  setFlashMessageError('ネットワークエラーが発生しました');
                }
              }
            }
            setAuthState({
              setAuthInformations: true,
              isAuth
            });
            if (isAuth === false) {
              const allowAuthPath: string[] = [loginPath, '/users/password-reset/', '/users/password-reset'];
              if (location.pathname !== undefined) {
                if (allowAuthPath.indexOf(location.pathname) === -1) {
                  history.push(loginPath);
                } 
              }
            } else {
              if (location.pathname === '') {
                history.push(_const.loginRedirect);
              }
            }
          }
        });
      }
    } else {
      if (AuthRoles !== undefined) {
        AuthRoles.forEach((element: any) => {
          if (element.isLoded) {
            let Auth = false;
            if (element.error === undefined) {
              Auth = true;
            } else {
              if (element.error.response !== undefined) {
                if (element.error.response.status >= 500) {
                  Auth = true;
                  setFlashMessageError('ネットワークエラーが発生しました');
                }
              }
            }
            if (Auth === false) {
              if (location.pathname !== loginPath) {
                window.location.href = loginPath;
              }
            } else {
              if (location.pathname === loginPath) {
                history.push(_const.loginRedirect);
              }
            }
          }
        });
      }
    }
  }, [
    location,
    history,
    AuthRoles,
    AuthInformations,
    authState,
    setFlashMessageError
  ]);

  useEffect(() => {    
    if (isInit) {
      dispatch(AuthInformationsActions.getAuth());
      setInit(false);
    }
  }, [isInit, dispatch]);

  useEffect(() => {
    checkAuth();
  }, [authState.setAuthInformations, checkAuth, location]);

  return(
    <div>
      <FlashMessage />
      {
        authState.setAuthInformations === true && authState.isAuth === true &&
        <Paperbase />
      }
      {
        authState.setAuthInformations === true && authState.isAuth === false &&
        <LoginPathContext.Provider value={loginPath}>
          <UsersLogin />
        </LoginPathContext.Provider>
      }
      {
        authState.setAuthInformations === false &&
        <MuiThemeProvider theme={theme}>
          <div className="auth-loading-progress">
            <CircularProgress />
          </div>
        </MuiThemeProvider>
      }
    </div>
  )
};

export default withRouter(Parent);