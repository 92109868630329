import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import DataTable, { DataTableRowsProps, DataTableSearchParamsProps } from '../../components/View/DataTable';
import { searchFilterProps } from '../../components/View/SearchFilter';

function LogsIndex({ history, location }: RouteComponentProps) {

  const clickCell = (callbackParams: any) => {
    history.push(location.pathname + '/' + callbackParams.data.id);
  }

  const rows: DataTableRowsProps[] = [
    {
      label: 'ログ日時',
      value: 'created',
      filter: 'YMDHm',
      sortField: 'created',
      align: 'left',
    },
    {
      label: 'ログメッセージ',
      value: 'message',
      align: 'left'
    },
    {
      label: 'ログレベル',
      value: 'level',
      sortField: 'level',
      align: 'left',
    },
    {
      label: '入力ID',
      value: 'param_1',
      align: 'left',
    },
    {
      label: 'ユーザー名',
      value: 'user_name',
      sortField: 'user_name',
      align: 'left',
    },
  ];

  const searchParams: DataTableSearchParamsProps[] = [
    {
      title: '入力ID',
      text: '部分一致',
    },
  ];

  const searchProps: searchFilterProps[] = [
    {
      key: 'search'
    },
    {
      key: 'level',
      title: 'ログレベル',
      type: 'select',
      emptyTitle: 'ALL',
      options: [
        {
          text: 'INFO',
          value: 'info',
        },
        {
          text: 'WARN',
          value: 'warning',
        },
      ],
    },
  ];

  return (
    <div className="content-1">
      <DataTable
        rows={rows}
        checkbox={true}
        dataKey='Logs'
        jsonPath='system-logs/index/index.json'
        urlQuery={true}
        parentProps={{ history, location }}
        handleClickCell={clickCell}
        searchParams={searchParams}
        searchProps={searchProps}
      />
    </div>
  )
}

export default LogsIndex;