import { atom } from 'recoil';

type zshDialogProps = {
    open: boolean;
    title?: string;
    message?: string;
    closeText?: string;
    agreeText?: string;
    funcAgree?: (() => void);
}

export const zshDialog = atom<zshDialogProps>({
    key: 'zshDialog',
    default: {
        open: false,
        title: '',
        message: '',
        closeText: '',
        agreeText: '',
        funcAgree: () => {}
    },
});