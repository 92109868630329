import React, { useState, useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import { overlayProppress } from '../../atoms/OverlayProgress';
import { flashMessageSuccess, flashMessageError } from '../../atoms/FlashMessage';
import { RouteComponentProps } from 'react-router-dom';
import * as _usr_const from '../../config/usr-constant';
import * as _form from '../../helper/form';
import * as _mailMagazinesForm from './func/MailMagazinesForm';
import Paper from '@material-ui/core/Paper';
import Loading from '../../components/View/Loading';
import HeadButtonGroups, { HeadButtonGroupBtnActionsProps } from '../../components/View/HeadButtonGroups';
import ZshTextField from '../../components/Form/ZshTextField';
import ZshEditor from '../../components/Form/ZshEditor';
import AjaxSelect from '../../components/Form/AjaxSelect';
import ZshRadio from '../../components/Form/ZshRadio';
import ZshDateTimePicker from '../../components/Form/ZshDateTimePicker';
import ZshTooltip from '../../components/View/ZshTooltip';
import CustomerReplaceParams from '../../components/Form/CustomerReplaceParams';
import axios from 'axios';

type  MailMagazineData = {
  status: string;
  subject: string;
  content: string | undefined;
  mail_magazine_group_id: string;
  send_date: any;
  pickers: {
    send_date: any;
  }
}

function MailMagazinesEdit({ history, match }: RouteComponentProps<{ id: string }>) {

  const setOverlayProppress = useSetRecoilState(overlayProppress);
  const setFlashMessageSuccess = useSetRecoilState(flashMessageSuccess);
  const setFlashMessageError = useSetRecoilState(flashMessageError);

  const actionName = 'mail-magazines/edit/';
  const backActionName = '/mail-magazines';

  const unmounted = useRef(false);
  const source = useRef(axios.CancelToken.source());
  const dataSource = useRef(axios.CancelToken.source());

  const [isInit, setInit] = useState(true);
  const [dataMounted, setDataMounted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState<MailMagazineData>({
    status: 'stop',
    subject: '',
    content: undefined,
    mail_magazine_group_id: '',
    send_date: null,
    pickers: {
      send_date: null,
    },
  });
  const [validateErrors, setValidateErrors] = useState({});
  const [formParams, setFormParams] = useState({
    replace_params: {},
    mail_magazine_groups: {},
    left_replace_symbol: '',
    right_replace_symbol: ''
  });
  const [formParamsError, setFormParamsError] = useState(false);
  const [sendDateRquired, setSendDateRquired] = useState(false);

  const handleChange = (event: any) => {
    const inputName = event.target.name;
    const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    setFormData({ ...formData, [inputName]: value });
  };

  const handleDatePickerChange = (date: any, name: string): void => {
    const tmpFormData = _form.handleDatePickerChange(formData, date, name);
    setFormData(tmpFormData);
  }

  const handleChangeContent = (editorContent: string): void => {
    setFormData({ ...formData, content: editorContent });
  }

  const handleSubmit = (event: any) => {
    event.preventDefault();
    _form.handleSubmit({
      action: actionName + match.params.id,
      formData,
      history,
      backActionName,
      setOverlayProppress,
      setFlashMessageSuccess,
      setFlashMessageError,
      setValidateErrors
    });
  }

  // clean up
  useEffect(() => {
    const clSource = Object.assign({}, source.current);
    const clDSource = Object.assign({}, dataSource.current);
    return () => {
      // cancel axios get
      clSource.cancel('cancel mail magazines form params get');
      clDSource.cancel('cancel mail magazines form data get');
      unmounted.current = true;
    }
  }, []);

  useEffect(() => {
    if (isInit) {
      const initFunc = async () => {
        await _mailMagazinesForm.getMailMagazinesFormParams({
          unmounted: unmounted.current,
          setFormParams,
          setFormParamsError,
          source
        });
        if (dataMounted === false) {
          const tepFormData = Object.assign({}, formData);
          await axios
            .get(_usr_const.ApiUrl + actionName + match.params.id + '/index.json', {
              cancelToken: dataSource.current.token
            })
            .then((results: any) => {
              if (!unmounted.current) {
                let mailMagazine = results.data.mailMagazine;
                const formData = _form.setFormData(tepFormData, mailMagazine);
                setDataMounted(true);
                setFormData(formData);
              }
            })
            .finally(() => {
              return Promise.resolve(1);
            });
        }
        await ((): any => {
          if (!unmounted.current) {
            setLoading(false);
          }
          return Promise.resolve(1);
        })();
      }
      initFunc();
      setInit(false);
    }
  }, [isInit, dataMounted, formData, match.params.id]);

  useEffect(() => {
    if (formData.status === 'wait') {
      setSendDateRquired(true);
    } else {
      setSendDateRquired(false);
    }
  }, [formData]);

  const btnActions: HeadButtonGroupBtnActionsProps[] = [
    {
      type: 'save',
    }
  ];

  return (
    <div className="content-1">
      <form onSubmit={handleSubmit}>
        <Loading loading={loading} />
        {
          dataMounted &&
          <div>
            <HeadButtonGroups
              history={history}
              btnActions={btnActions}
            />
            <Paper>
              <div className="view-content-in form-content">
                <table className="form-table">
                  <tbody>
                    <tr>
                      <th>状況</th>
                      <td>
                        <div className="form-input-group">
                          <ZshRadio
                          name="status"
                          value={formData.status}
                          onChange={handleChange}
                          options={
                            {
                              '下書き': 'stop',
                              '配信待ち': 'wait'
                            }
                          }
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th className="required-th">メールタイトル</th>
                      <td>
                        <div className="form-input-group">
                          <ZshTextField
                            value={formData.subject}
                            name="subject"
                            onChange={handleChange}
                            required
                            validateErrors={validateErrors}
                            fullWidth
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>メール本文</th>
                      <td>
                        <div className="form-input-group">
                          <CustomerReplaceParams
                          replace_params={formParams.replace_params}
                          leftReplaceSymbol={formParams.left_replace_symbol}
                          rightReplaceSymbol={formParams.right_replace_symbol}
                          />
                          <ZshEditor
                          name="content"
                          value={formData.content}
                          onChange={handleChangeContent}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th className="required-th">メルマガグループ</th>
                      <td>
                        <div className="form-input-group">
                          <AjaxSelect
                            name="mail_magazine_group_id"
                            menuItems={formParams.mail_magazine_groups}
                            formData={formData}
                            handleChange={handleChange}
                            getError={formParamsError}
                            validateErrors={validateErrors}
                            style={{ minWidth: '200px' }}
                            required
                            fullWidth
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th className={sendDateRquired ? 'required-th' : ''}>
                        配信日時
                        <ZshTooltip
                        text="配信処理は10分間隔で実行され、設定した日時を過ぎると配信されます。¥n（過去日付を設定しても送信されます）"
                        />
                      </th>
                      <td>
                        <div className="form-input-group">
                          <ZshDateTimePicker
                          value={formData.pickers.send_date}
                          name="send_date"
                          required={sendDateRquired}
                          onChange={(date: any) => handleDatePickerChange(date, 'send_date')}
                          format="yyyy/MM/dd HH:mm"
                          views={['date', 'hours', 'minutes']}
                          validateErrors={validateErrors}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Paper>
            <HeadButtonGroups
              history={history}
              btnActions={btnActions}
            />
          </div>
        }
      </form>
    </div>
  )
}

export default MailMagazinesEdit;