import React, { useState, useEffect } from 'react';
import FormControl from '@material-ui/core/FormControl';
import jaLocale from "date-fns/locale/ja";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';

type ZshDateTimePickerProps = {
  label?: string;
  value: any;
  name: string;
  onChange: (date: any) => void
  format?: 'yyyy/MM/dd HH:mm' | 'yyyy/MM/dd HH' | 'yyyy/MM/dd' | undefined;
  views?: ("date" | "hours" | "year" | "month" | "minutes")[];
  required?: boolean;
  validateErrors?: { [key: string]: string[] };
  type?: 'date' | 'datetime';
}

function ZshDateTimePicker({
  label = '',
  value,
  name,
  onChange,
  format = 'yyyy/MM/dd HH:mm',
  views = ['date', 'hours'],
  required = false,
  validateErrors,
  type = 'datetime'
}: ZshDateTimePickerProps) {

  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const isRequired: boolean = required === undefined ? false : required;

  useEffect(() => {
    if (validateErrors !== undefined && validateErrors[name] !== undefined) {
      setErrorMessages(validateErrors[name]);
    } else {
      setErrorMessages([]);
    }
  }, [validateErrors, name]);

  return (
    <FormControl
      error={errorMessages.length > 0}
      className="zsh-datetime-field"
      required={isRequired}
    >
      <div className="zsh-datetime-wr">
        {
          type === 'datetime' &&
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={jaLocale}>
            <DateTimePicker
              label={label}
              value={value}
              onChange={(date: any) => onChange(date)}
              format={format}
              views={views}
              required={isRequired}
            />
          </MuiPickersUtilsProvider>
        }
        {
          type === 'date' &&
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={jaLocale}>
            <DatePicker
              label={label}
              value={value}
              onChange={(date: any) => onChange(date)}
              format={format}
              required={isRequired}
            />
          </MuiPickersUtilsProvider>
        }
        <Button
        size="small"
        variant="outlined"
        className="zsh-datetime-clear-btn"
        onClick={() => onChange(null)}
        >
          クリア
        </Button>
      </div>
      {
        errorMessages.length > 0 &&
        <span className="zsh-datetime-field-er-wr">
          {
            errorMessages.map((msg: string, index: number) => (
              <span key={name + '-erSeMsg-' + index} className="zsh-datetime-field-er-msg">
                <FormHelperText>{msg}</FormHelperText><br />
              </span>
            ))
          }
        </span>
      }
    </FormControl>
  )
}

export default ZshDateTimePicker;