import React, { useState, useEffect, useRef } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom'
import * as _usr_const from '../../config/usr-constant';
import * as _view_action from '../../helper/viewAction';
import axios from 'axios';
import Paper from '@material-ui/core/Paper';
import Loading from '../../components/View/Loading';
import HeadButtonGroups, { HeadButtonGroupBtnActionsProps } from '../../components/View/HeadButtonGroups';
import ZshLogs from '../../components/View/ZshLogs';
import ErrorView from '../../components/View/ErrorView';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

type UserDataProps = {
  id: string;
  username_display: string;
  authority_display: string;
  status_display: string;
  roles: any;
  account_locked: boolean;
  last_name: string;
  first_name: string;
  last_name_kana: string;
  first_name_kana: string;
  common_parent: any;
  user_roles: any;
}

function UsersView({ history, match }: RouteComponentProps<{ id: string }>) {

  const unmounted = useRef(false);
  const source = useRef(axios.CancelToken.source());

  const [isInit, setInit] = useState(true);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<UserDataProps>({
    id: '',
    username_display: '',
    authority_display: '',
    status_display: '',
    roles: {},
    account_locked: false,
    last_name: '',
    first_name: '',
    last_name_kana: '',
    first_name_kana: '',
    common_parent: {},
    user_roles: {}
  });
  const [dataMounted, setDataMounted] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [errorStatus, setErrorStatus] = useState(0);
  const [roleActions, setRoleActions] = useState<{ [key: string]: string; }>({});
  const [roleNames, setRoleNames] = useState<{ [key: string]: string }>({});
  const [openRoles, setOpenRoles] = useState(false);

  const handleRolesClose = () => {
    setOpenRoles(false);
  }

  const handleRolesOpen = (event: any) => {
    event.preventDefault();
    setOpenRoles(true);
  }

  // clean up
  useEffect(() => {
    const clSource = Object.assign({}, source.current);
    return () => {
      // cancel axios get
      clSource.cancel('cancel customer get');
      unmounted.current = true;
    }
  }, []);

  useEffect(() => {
    if (isInit) {
      setLoading(true);
      _view_action.getViewData<UserDataProps>({
        unmounted,
        source,
        dataKey: 'user',
        path: _usr_const.ApiUrl + 'users/view/' + match.params.id + '/index.json',
        setData,
        setDataMounted,
        setErrorStatus,
        setErrorMsg,
        setLoading,
        successCallbackBeforeSetData: (results) => {
          setRoleActions(results.data.role_actions);
          setRoleNames(results.data.role_names);
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
      setInit(false);
    }
  }, [isInit, match.params.id]);

  const btnActions: HeadButtonGroupBtnActionsProps[] = [
    {
      type: 'edit',
      func: (): void => {
        history.push('/users/' + match.params.id + '/edit');
      },
      role: {
        name: 'Users',
        action: 'edit',
      },
    },
  ];

  const hrefLink: string = '#';

  return (
    <div className="content-1">
      <Loading loading={loading} />
      {
        dataMounted &&
        <div>
          <HeadButtonGroups
            history={history}
            btnActions={btnActions}
          />
          <Paper>
            <div className="view-content-in">
              <table className="table-1">
                <tbody>
                  <tr>
                    <th>ID</th>
                    <td>{data.id}</td>
                  </tr>
                  <tr>
                    <th>ユーザー名</th>
                    <td>{data.username_display}</td>
                  </tr>
                  <tr>
                    <th>ユーザー区分</th>
                    <td>{data.authority_display}</td>
                  </tr>
                  <tr>
                    <th>ステータス</th>
                    <td>{data.status_display}</td>
                  </tr>
                  <tr>
                    <th>権限グループ</th>
                    <td>
                      <ul className="ul-1">
                        {
                          data.roles &&
                          data.roles.map((role: any) => (
                            <li key={role.id}>
                              <Link to={"/roles/" + role.id}>{role.name}</Link>
                            </li>
                          ))
                        }
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th>権限設定</th>
                    <td>
                      <a href={hrefLink} onClick={handleRolesOpen}>表示</a>
                    </td>
                  </tr>
                  <tr>
                    <th>アカウントロック</th>
                    <td>
                      {
                        data.account_locked &&
                        <span className="red">ロック</span>
                      }
                      {
                        data.account_locked === false &&
                        <span>正常</span>
                      }
                    </td>
                  </tr>
                  <tr>
                    <th>姓</th>
                    <td>{data.last_name}</td>
                  </tr>
                  <tr>
                    <th>名</th>
                    <td>{data.first_name}</td>
                  </tr>
                  <tr>
                    <th>姓（カナ）</th>
                    <td>{data.last_name_kana}</td>
                  </tr>
                  <tr>
                    <th>名（カナ）</th>
                    <td>{data.first_name_kana}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Paper>
          <ZshLogs logs={data.common_parent.logs} />
          <Dialog
            onClose={handleRolesClose}
            open={openRoles}
            fullWidth
          >
            <DialogContent>
              <div>
                {
                  Object.keys(roleNames).map((k: string) => (
                    <div key={k}>
                      <h3>{roleNames[k]}</h3>
                      <table className="table-1">
                        <tbody>
                          {
                            Object.keys(roleActions).map((ak: string) => (
                              <tr key={ak}>
                                <th>{roleActions[ak]}</th>
                                <td>
                                  {(() => {
                                    if (
                                      data.user_roles[k] !== undefined &&
                                      data.user_roles[k].indexOf(ak) !== -1
                                    ) {
                                      return <span>許可</span>
                                    } else {
                                      return <span>拒否</span>
                                    }
                                  })()}
                                </td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    </div>
                  ))
                }
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleRolesClose} color="primary">
                閉じる
                  </Button>
            </DialogActions>
          </Dialog>
        </div>
      }
      {
        errorMsg !== '' &&
        <div>
          <ErrorView
            history={history}
            errorStatus={errorStatus}
            errorMsg={errorMsg}
          />
        </div>
      }
    </div>
  )
}

export default UsersView;