import React, { useState, useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import { overlayProppress } from '../../atoms/OverlayProgress';
import { flashMessageSuccess, flashMessageError } from '../../atoms/FlashMessage';
import { RouteComponentProps } from 'react-router-dom';
import * as _form from '../../helper/form';
import * as _usr_const from '../../config/usr-constant';
import AttributeGroupCategorySelect from '../../components/Form/AttributeGroupCategorySelect';
import AttributeGroupConditionInputs, { AttributeGroupConditionProps }from '../../components/Form/AttributeGroupConditionInputs';
import AttributeGroupConditionPreview from '../../components/Form/AttributeGroupConditionPreview';
import Paper from '@material-ui/core/Paper';
import Loading from '../../components/View/Loading';
import HeadButtonGroups, { HeadButtonGroupBtnActionsProps } from '../../components/View/HeadButtonGroups';
import ZshTextField from '../../components/Form/ZshTextField';
import axios from 'axios';

type AttributeGroupApiConditionDate = {
  date: string;
  hour?: string;
  minute?: string;
}

type AttributeGroupApiCondition = {
  field: string;
  input: string;
  conditions: string;
  value: string | number | boolean | AttributeGroupApiConditionDate;
  match_conditions: string | null;
  OR?: AttributeGroupApiCondition[] | undefined;
}

type formDataProps = {
  attribute_group_category_id: string;
  name: string;
  memo: string;
  conditions: AttributeGroupConditionProps[];
}

function AttributeGroupsEdit({ history, match }: RouteComponentProps<{ id: string }>) {

  const setOverlayProppress = useSetRecoilState(overlayProppress);
  const setFlashMessageSuccess = useSetRecoilState(flashMessageSuccess);
  const setFlashMessageError = useSetRecoilState(flashMessageError);

  const actionName = 'attribute-groups/edit/';
  const backActionName = '/attribute-groups';

  const unmounted = useRef(false);
  const source = useRef(axios.CancelToken.source());
  const dataSource = useRef(axios.CancelToken.source());

  const [isInit, setInit] = useState(true);
  const [dataMounted, setDataMounted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState<formDataProps>({
    attribute_group_category_id: '',
    name: '',
    memo: '',
    conditions: []
  });
  const [validateErrors, setValidateErrors] = useState({});

  const handleChange = (event: any) => {
    const inputName = event.target.name;
    const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    setFormData({ ...formData, [inputName]: value });
  };

  const handleChangeConditions = (conditions: AttributeGroupConditionProps[]) => {
    setFormData({
      ...formData,
      conditions
    });
  }

  const handleSubmit = (event: any) => {
    event.preventDefault();
    _form.handleSubmit({
      action: actionName + match.params.id,
      formData,
      history,
      backActionName,
      setOverlayProppress,
      setFlashMessageSuccess,
      setFlashMessageError,
      setValidateErrors
    });
  }

  const handleSelectAttributeGroupCategory = (node: any) => {
    setFormData({ ...formData, attribute_group_category_id: node.id });
  }

  // clean up
  useEffect(() => {
    const clSource = Object.assign({}, source.current);
    const clDSource = Object.assign({}, dataSource.current);
    return () => {
      // cancel axios get
      clSource.cancel('cancel get attribute groups form attribute group categories');
      clDSource.cancel('cancel get attribute groups form data');
      unmounted.current = true;
    }
  }, []);

  useEffect(() => {
    if (isInit) {

      const formatConditions = (
        condition: AttributeGroupApiCondition
      ): AttributeGroupConditionProps => {

        let pickerType: 'date' | 'datetime' = 'date';
        let pickerDate: any = null;
        let matchConditions = condition.match_conditions;

        let thisValue = condition.value;

        if (typeof condition.value === 'object') {
          if (typeof condition.value.date !== 'undefined') {
            const dateObj = new Date(condition.value.date);
            if (
              typeof condition.value.hour !== 'undefined' &&
              typeof condition.value.minute !== 'undefined'
            ) {
              pickerType = 'datetime';
              pickerDate = new Date(
                dateObj.getFullYear(),
                dateObj.getMonth(),
                dateObj.getDate(),
                Number(condition.value.hour),
                Number(condition.value.minute)
              );
            } else {
              pickerDate = new Date(
                dateObj.getFullYear(),
                dateObj.getMonth(),
                dateObj.getDate()
              );
            }
            thisValue = _form.formatISO8601(pickerDate);
          }
        }
        return {
          field: condition.field,
          input: condition.input,
          match_conditions: matchConditions,
          operator: condition.conditions,
          value: thisValue,
          picker: {
            type: pickerType,
            date: pickerDate
          }
        }
      }

      const initFunc = async () => {
        if (dataMounted === false) {
          const tepFormData = Object.assign({}, formData);
          await axios
            .get(_usr_const.ApiUrl + actionName + match.params.id + '/index.json', {
              cancelToken: dataSource.current.token
            })
            .then((results: any) => {
              if (!unmounted.current) {
                const formData = _form.setFormData(tepFormData, results.data.attributeGroup);
                let conditions: AttributeGroupConditionProps[] = [];
                if (results.data.attributeGroup.conditions !== undefined) {
                  const conditionsJson: AttributeGroupApiCondition[] = JSON.parse(results.data.attributeGroup.conditions);
                  conditionsJson.forEach((condition: AttributeGroupApiCondition) => {
                    let thisCondition: AttributeGroupConditionProps = formatConditions(condition);
                    if (condition.OR !== undefined) {
                      let thisORCondition:AttributeGroupConditionProps[] = [];
                      condition.OR.forEach((or: AttributeGroupApiCondition) => {
                        thisORCondition.push(formatConditions(or));
                      });
                      thisCondition.OR = thisORCondition;
                    }
                    conditions.push(thisCondition);
                  });
                }
                setDataMounted(true);
                setFormData({
                  ...formData,
                  conditions
                });
              }
            })
            .finally(() => {
              return Promise.resolve(1);
            });
        }

        await ((): any => {
          setLoading(false);
          return Promise.resolve(1);
        })();

      }
      initFunc();
      setInit(false);
    }
  }, [isInit, dataMounted, formData, match.params.id]);

  const btnActions: HeadButtonGroupBtnActionsProps[] = [
    {
      type: 'save',
    }
  ];

  return (
    <div className="content-1">
      <form onSubmit={handleSubmit}>
        <Loading loading={loading} />
        {
          loading === false &&
          <div>
            <HeadButtonGroups
              history={history}
              btnActions={btnActions}
            />
            <Paper>
              <div className="view-content-in form-content">
                <table className="form-table">
                  <tbody>
                    <tr>
                      <th>カテゴリ</th>
                      <td>
                        <div className="form-input-group">
                          <AttributeGroupCategorySelect
                          name="attribute_group_category_id"
                          handleSelectAttributeGroupCategory={handleSelectAttributeGroupCategory}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th className="required-th">グループ名称</th>
                      <td>
                        <div className="form-input-group">
                          <ZshTextField
                            value={formData.name}
                            name="name"
                            onChange={handleChange}
                            required
                            validateErrors={validateErrors}
                            fullWidth
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>メモ</th>
                      <td>
                        <div className="form-input-group">
                          <ZshTextField
                            value={formData.memo}
                            name="memo"
                            onChange={handleChange}
                            validateErrors={validateErrors}
                            fullWidth
                            multiline={true}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Paper>
            <AttributeGroupConditionInputs
            conditions={formData.conditions}
            onChange={handleChangeConditions}
            />
            <AttributeGroupConditionPreview
            conditions={formData.conditions}
            />
            <HeadButtonGroups
              history={history}
              btnActions={btnActions}
            />
          </div>
        }
      </form>
    </div>
  )
}

export default AttributeGroupsEdit;