export function formatCssStyleToReactStyle(style: string): any {
  if (style === '' || style === null) {
    return {};
  }
  let results: any = {};
  const styleSplit = style.split(';');
  styleSplit.forEach((prop: string) => {
    const propSplit: any = prop.split(':');
    let styleName: string = propSplit[0];
    const styleNameIndex: number = propSplit[0].indexOf('-');
    if (styleNameIndex !== -1) {
      let formatStyleName = styleName.slice(styleNameIndex);
      formatStyleName = formatStyleName.replace('-', '');
      formatStyleName = formatStyleName.slice(0, 1).toUpperCase() + formatStyleName.slice(1);      
      styleName = styleName.slice(0, styleNameIndex) + formatStyleName;
    }
    styleName = styleName.replace(' ', '');
    results[styleName] = propSplit[1];
  });
  return results;
}