import React, { useState, useEffect, useRef, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom'
import { useSetRecoilState } from 'recoil';
import { overlayProppress } from '../../atoms/OverlayProgress';
import { flashMessageSuccess, flashMessageError } from '../../atoms/FlashMessage';
import * as _usr_const from '../../config/usr-constant';
import * as _view_action from '../../helper/viewAction';
import * as _debug from '../../helper/debug';
import axios from 'axios';
import Paper from '@material-ui/core/Paper';
import Loading from '../../components/View/Loading';
import HeadButtonGroups, { HeadButtonGroupBtnActionsProps } from '../../components/View/HeadButtonGroups';
import ZshLogs from '../../components/View/ZshLogs';
import ZshTooltip from '../../components/View/ZshTooltip';
import Memos from '../../components/View/Memos';
import ErrorView from '../../components/View/ErrorView';
import MailLogs from '../../components/View/MailLogs';
import Inquiries from '../../components/View/Inquiries';

type CustomerDataProps = {
  id: number;
  common_parent_id: number;
  primary_number: string;
  customer_number: string;
  customer_status_name: string;
  last_name: string;
  first_name: string;
  last_name_kana: string;
  first_name_kana: string;
  tel: string;
  email: string;
  zipcode_display: string;
  prefecture: string;
  address_1: string;
  address_2: string;
  is_mm_not_send: boolean;
  is_mm_not_send_display: string;
  first_inflow_source_type_name: string;
  first_inflow_source_master_name: string;
  registration_type_display: string;
  registration_source: string;
  common_parent: any;
}
function CustomersView({ history, match }: RouteComponentProps<{ id: string }>) {

  const setOverlayProppress = useSetRecoilState(overlayProppress);
  const setFlashMessageSuccess = useSetRecoilState(flashMessageSuccess);
  const setFlashMessageError = useSetRecoilState(flashMessageError);

  const unmounted = useRef(false);
  const source = useRef(axios.CancelToken.source());

  const [isInit, setInit] = useState(true);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<CustomerDataProps>({
    id: 0,
    common_parent_id: 0,
    primary_number: '',
    customer_number: '',
    customer_status_name: '',
    last_name: '',
    first_name: '',
    last_name_kana: '',
    first_name_kana: '',
    tel: '',
    email: '',
    zipcode_display: '',
    prefecture: '',
    address_1: '',
    address_2: '',
    is_mm_not_send: false,
    is_mm_not_send_display: '',
    first_inflow_source_type_name: '',
    first_inflow_source_master_name: '',
    registration_type_display: '',
    registration_source: '',
    common_parent: {}
  });
  const [dataMounted, setDataMounted] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [errorStatus, setErrorStatus] = useState(0);

  const getCustomerData = useCallback(() => {
    _view_action.getViewData<CustomerDataProps>({
      unmounted,
      source,
      dataKey: 'customer',
      path: _usr_const.ApiUrl + 'customers/view/' + match.params.id + '/index.json',
      setData,
      setDataMounted,
      setErrorStatus,
      setErrorMsg,
      setLoading
    })
    .catch((error) => {
      _debug.debugAxiosError(error);
    });
  }, [unmounted, source, match.params.id]);

  const handleClickMailMagazineResumes = () => {
    setOverlayProppress(true);
    axios
      .post(
        _usr_const.ApiUrl + 'customers/mail-magazine-resumes/' + match.params.id,
        {},
      )
      .then((response: any) => {
        setFlashMessageSuccess('メルマガ配信を再開しました');
        getCustomerData()
      })
      .catch((error) => {
        _debug.debugAxiosError(error);
        if (error.response.data[0] !== undefined) {
          setFlashMessageError(error.response.data[0]);
        } else {
          setFlashMessageError('エラーが発生し変更できませんでした');
        }
      })
      .finally(() => {
        setOverlayProppress(false);
      });
  }

  // clean up
  useEffect(() => {
    const clSource = Object.assign({}, source.current);
    return () => {
      // cancel axios get
      clSource.cancel('cancel customer get');
      unmounted.current = true;
    }
  }, []);

  useEffect(() => {
    if (isInit) {
      setLoading(true);
      getCustomerData();
      setInit(false);
    }
  }, [isInit, match.params.id, getCustomerData]);

  const btnActions: HeadButtonGroupBtnActionsProps[] = [
    {
      type: 'edit',
      func: (): void => {
        history.push('/customers/' + match.params.id + '/edit');
      },
      role: {
        name: 'Customers',
        action: 'edit',
      },
    },
  ];

  return (
    <div className="content-1">
      <Loading loading={loading} />
      {
        dataMounted &&
        <div>
          <HeadButtonGroups
            history={history}
            btnActions={(() => {
              let tmpBtnActions: HeadButtonGroupBtnActionsProps[] = btnActions.slice();
              if (data.is_mm_not_send === true) {
                tmpBtnActions.push({
                  text: 'メルマガ配信再開',
                  func: handleClickMailMagazineResumes,
                  role: {
                    name: 'Customers',
                    action: 'edit',
                  },
                });
              }
              return tmpBtnActions;
            })()}
          />
          <Paper>
            <div className="view-content-in">
              <table className="table-1">
                <tbody>
                  <tr>
                    <th>
                      No
                          <ZshTooltip text="必ず割当てられる通し番号です。" />
                    </th>
                    <td>{data.primary_number}</td>
                  </tr>
                  <tr>
                    <th>
                      顧客番号
                          <ZshTooltip text="お客様との接点が発生した際に¥n割り当てる顧客番号です。" />
                    </th>
                    <td>{data.customer_number}</td>
                  </tr>
                  <tr>
                    <th>ステータス</th>
                    <td>{data.customer_status_name}</td>
                  </tr>
                  <tr>
                    <th>姓</th>
                    <td>{data.last_name}</td>
                  </tr>
                  <tr>
                    <th>名</th>
                    <td>{data.first_name}</td>
                  </tr>
                  <tr>
                    <th>姓 （カナ）</th>
                    <td>{data.last_name_kana}</td>
                  </tr>
                  <tr>
                    <th>名 （カナ）</th>
                    <td>{data.first_name_kana}</td>
                  </tr>
                  <tr>
                    <th>電話番号</th>
                    <td>{data.tel}</td>
                  </tr>
                  <tr>
                    <th>メールアドレス</th>
                    <td>{data.email}</td>
                  </tr>
                  <tr>
                    <th>郵便番号</th>
                    <td>{data.zipcode_display}</td>
                  </tr>
                  <tr>
                    <th>住所</th>
                    <td>{data.prefecture + data.address_1 + data.address_2}</td>
                  </tr>
                  <tr>
                    <th>メルマガ配信可否</th>
                    <td>{data.is_mm_not_send_display}</td>
                  </tr>
                  <tr>
                    <th>
                      流入元区分
                          <ZshTooltip text="お客様と最初に接点が出来た媒体の区分です。" />
                    </th>
                    <td>{data.first_inflow_source_type_name}</td>
                  </tr>
                  <tr>
                    <th>
                      流入元
                          <ZshTooltip text="お客様と最初に接点が出来た媒体の名称です。" />
                    </th>
                    <td>{data.first_inflow_source_master_name}</td>
                  </tr>
                  <tr>
                    <th>
                      登録方法
                          <ZshTooltip text="このデータがどのようにして登録されたかの区分です。" />
                    </th>
                    <td>{data.registration_type_display}</td>
                  </tr>
                  <tr>
                    <th>
                      データソース
                          <ZshTooltip text="このデータがどこから登録されたかの区分です。" />
                    </th>
                    <td>{data.registration_source}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Paper>
          <Memos
            CommonParentId={data.common_parent_id}
            CustomerId={data.id}
          />
          <Inquiries
          objectCommonParentId={data.common_parent_id}
          customerId={data.id}
          findType="customer"
          title="すべての問合せ"
          />
          <MailLogs
          customerId={data.id}
          history={history}
          type="Customers"
          />
          <ZshLogs logs={data.common_parent.logs} />
        </div>
      }
      {
        errorMsg !== '' &&
        <div>
          <ErrorView
            history={history}
            errorStatus={errorStatus}
            errorMsg={errorMsg}
          />
        </div>
      }
    </div>
  )
}

export default CustomersView;