import React, { useRef, useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom'
import * as _usr_const from '../../config/usr-constant';
import * as _filter from '../../helper/filter';
import * as _view_action from '../../helper/viewAction';
import * as _debug from '../../helper/debug';
import axios from 'axios';
import Paper from '@material-ui/core/Paper';
import Loading from '../../components/View/Loading';
import HeadButtonGroups from '../../components/View/HeadButtonGroups';
import ErrorView from '../../components/View/ErrorView';

type LogDataProps = {
  id: number;
  created: string;
  level: string;
  user_name: string;
  message: string;
  name: string;
  action: string;
  param_1: string;
  ip: string;
  url: string;
  referrer: string;
  user_agent: string;
  server_ip: string;
}

function LogsView({ history, location, match }: RouteComponentProps<{ id: string }>) {

  const unmounted = useRef(false);
  const source = useRef(axios.CancelToken.source());

  const [isInit, setInit] = useState(true);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<LogDataProps>({
    id: 0,
    created: '',
    level: '',
    user_name: '',
    message: '',
    name: '',
    action: '',
    param_1: '',
    ip: '',
    url: '',
    referrer: '',
    user_agent: '',
    server_ip: ''
  });
  const [dataMounted, setDataMounted] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [errorStatus, setErrorStatus] = useState(0);

  // clean up
  useEffect(() => {
    const clSource = Object.assign({}, source.current);
    return () => {
      // cancel axios get
      clSource.cancel('cancel logs get');
      unmounted.current = true;
    }
  }, []);

  useEffect(() => {
    if (isInit) {
      setLoading(true);
      _view_action.getViewData<LogDataProps>({
        unmounted,
        source,
        dataKey: 'Log',
        path: _usr_const.ApiUrl + 'system-logs/view/' + match.params.id + '/index.json',
        setData,
        setDataMounted,
        setErrorStatus,
        setErrorMsg,
        setLoading
      })
      .catch((error) => {
        _debug.debugAxiosError(error);
      });
      setInit(false);
    }
  }, [isInit, match.params.id]);

  return (
    <div className="content-1">
      <Loading loading={loading} />
      {
        dataMounted &&
        <div>
          <HeadButtonGroups
            history={history}
          />
          <Paper>
            <div className="view-content-in">
              <table className="table-1">
                <tbody>
                  <tr>
                    <th>ID</th>
                    <td>{data.id}</td>
                  </tr>
                  <tr>
                    <th>日時</th>
                    <td>{_filter.ShFilter(data.created, 'YMDHms')}</td>
                  </tr>
                  <tr>
                    <th>ログレベル</th>
                    <td>{data.level}</td>
                  </tr>
                  <tr>
                    <th>更新者名</th>
                    <td>{data.user_name}</td>
                  </tr>
                  <tr>
                    <th>メッセージ</th>
                    <td>{data.message}</td>
                  </tr>
                  <tr>
                    <th>Name</th>
                    <td>{data.name}</td>
                  </tr>
                  <tr>
                    <th>Action</th>
                    <td>{data.action}</td>
                  </tr>
                  <tr>
                    <th>パラメータ 1</th>
                    <td>{data.param_1}</td>
                  </tr>
                  <tr>
                    <th>操作IPアドレス</th>
                    <td>{data.ip}</td>
                  </tr>
                  <tr>
                    <th>操作時URL</th>
                    <td>{data.url}</td>
                  </tr>
                  <tr>
                    <th>リファラ</th>
                    <td>{data.referrer}</td>
                  </tr>
                  <tr>
                    <th>HTTP_USER_AGENT</th>
                    <td>{data.user_agent}</td>
                  </tr>
                  <tr>
                    <th>サーバーIPアドレス</th>
                    <td>{data.server_ip}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Paper>
        </div>
      }
      {
        errorMsg !== '' &&
        <div>
          <ErrorView
            history={history}
            errorStatus={errorStatus}
            errorMsg={errorMsg}
          />
        </div>
      }
    </div>
  )
}

export default LogsView;