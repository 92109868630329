import * as _usr_const from '../../../config/usr-constant';
import * as _debug from '../../../helper/debug';
import axios from 'axios';

type getMailMagazinesFormParamsProps = {
  unmounted: boolean,
  setFormParams: React.Dispatch<React.SetStateAction<{
    replace_params: {};
    mail_magazine_groups: {};
    left_replace_symbol: string;
    right_replace_symbol: string;
  }>>,
  setFormParamsError: React.Dispatch<React.SetStateAction<boolean>>,
  source: any
}
export async function getMailMagazinesFormParams({
  unmounted,
  setFormParams,
  setFormParamsError,
  source
}: getMailMagazinesFormParamsProps) {
  // get data
  await axios
  .get(
    _usr_const.ApiUrl + 'mail-magazines/get-mail-magazine-form-params/index.json', {
      cancelToken: source.current.token
    }
  )
  .then((response) => {    
    if (typeof response.data !== 'undefined') {
        if (!unmounted) {
          setFormParams(response.data);
        }
    }
  })
  .catch((error) => {
    _debug.debugAxiosError(error);
    if (!unmounted) { 
      setFormParamsError(true);
    }
  })
  .finally(() => {
    return Promise.resolve(1);
  });
}