import React, { useState, useEffect, useContext } from 'react';
import { useSetRecoilState } from 'recoil';
import { overlayProppress } from '../../atoms/OverlayProgress';
import { flashMessageError } from '../../atoms/FlashMessage';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import * as _usr_const from '../../config/usr-constant';
import * as _form from '../../helper/form';
import * as _debug from '../../helper/debug';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import axios from 'axios';
import OverlayProgress from '../../components/OverlayProgress';
import { LoginPathContext } from '../../dashboard/Parent';
import ZshTextField from '../../components/Form/ZshTextField';
import queryString from 'query-string';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '@global': {
      body: {
        backgroundColor: '#eaeff1',
      },
    },
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    forgotPassword: {
      textAlign: 'center',
      paddingTop: '15px',
    },
    body1: {
      fontWeight: 'bold',
      margin: '20px 0',
    },
    sendedBody1: {
      fontWeight: 'bold',
      margin: '20px 0',
      textAlign: 'center',
    }
  })
);

type UsersPasswordResetProps = {
  setPasswordReset: React.Dispatch<React.SetStateAction<boolean>>;
}

type Props = RouteComponentProps & UsersPasswordResetProps;

const UsersPasswordReset: React.FC<Props> = ({ setPasswordReset, history, location }) => {

  const setOverlayProppress = useSetRecoilState(overlayProppress);
  const setFlashMessageError = useSetRecoilState(flashMessageError);

  const loginPath = useContext(LoginPathContext);

  const [formData, setFormData] = useState({
    email: '',
    new_password: '',
    new_password_confirm: ''
  });
  const [validateErrors, setValidateErrors] = useState<{[key: string]: string[]}>({});
  const [step, setStep] = useState('');
  const [uuid, setUuid] = useState('');

  const handleChange = (event: any) => {
    const inputName = event.target.name;
    const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    setFormData({ ...formData, [inputName]: value });
  };

  const handleInitSubmit = (event: any) => {
    event.preventDefault();
    setOverlayProppress(true);
    axios
      .post(
        _usr_const.ApiUrl + 'users/password-reset/',
        {
          email: formData.email,
          client_host: window.location.origin
        }
      )
      .then((response: any) => {
        setStep('sended');
        setValidateErrors({});
      })
      .catch((error) => {
        _debug.debugAxiosError(error);
        let errorMessage: {[key: string]: string[]} = {
          email: []
        };
        if (error.response) {
          // set error message
          if (error.response.data !== undefined) {
            errorMessage.email = error.response.data;
            setValidateErrors(errorMessage);
          }
        }
        // set flash message
        if (errorMessage.email.length === 0) {
          setFlashMessageError('エラーが発生しました');
        }
      })
      .finally(() => {
        setOverlayProppress(false);
      });
  }

  const handleAllowUuidSubmit = (event: any) => {
    event.preventDefault();
    let errors: {[key: string]: string[]} = {
      new_password: [],
      new_password_confirm: []
    };
    // check local
    if (formData.new_password === '') {
      errors.new_password.push('新しいパスワードを入力して下さい');
    }
    if (formData.new_password_confirm === '') {
      errors.new_password_confirm.push('パスワード（確認）を入力して下さい');
    } else {
      if (formData.new_password !== formData.new_password_confirm) {
        errors.new_password_confirm.push('パスワードを確認して下さい');
      }
    }
    // post
    if (errors.new_password.length === 0 && errors.new_password_confirm.length === 0) {
      setOverlayProppress(true);
      axios.post(
        _usr_const.ApiUrl + 'users/password-reset/' + uuid,
        {
          new_password: formData.new_password,
          new_password_confirm: formData.new_password_confirm
        }
      )
      .then((response: any) => {
        setStep('finished');
      })
      .catch((error) => {
        _debug.debugAxiosError(error);
        if (error.response) {
          // validate error
          if (error.response.status === 400) {
            setValidateErrors(_form.formatValidateError(error));
          }
        }
        setFlashMessageError('パスワードを変更出来ませんでした');
      })
      .finally(() => {
        setOverlayProppress(false);
      });
    } else {
      setValidateErrors(errors);
    }
  }

  const handleBackLogin = () => {
    history.push(loginPath);
    setPasswordReset(false);
  }

  useEffect(() => {
    return () => {
      setFormData({
        email: '',
        new_password: '',
        new_password_confirm: ''
      });
    }
  }, []);

  useEffect(() => {
    const getParams = queryString.parse(location.search);
    if (typeof getParams.uuid === 'string') {
      setUuid(getParams.uuid);
      setOverlayProppress(true);
      axios.get(
        _usr_const.ApiUrl + 'users/password-reset/' + getParams.uuid
      )
      .then((response: any) => {
        setStep('allowUuid');
      })
      .catch((error) => {
        _debug.debugAxiosError(error);
        setStep('failUuid');
      })
      .finally(() => {
        setOverlayProppress(false);
      });
    } else {
      setStep('init');
    }
  }, [location.search, setOverlayProppress]);

  const classes = useStyles();

  return (
    <div>
      <OverlayProgress />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          {
            step === 'init' &&
            <div>
              <Typography component="h1" variant="h5" className="login-h">パスワード再設定</Typography>
              <Typography variant="body1" gutterBottom className={classes.body1}>
                パスワードを忘れた場合、こちらでパスワードの再設定を行う事ができます。
              </Typography>
              <Typography variant="body2" gutterBottom>
                ご登録いただいているメールアドレスを入力の上、送信ボタンを押してください。<br />パスワード再設定用URLが記載されたメールを送信します。
              </Typography>
              <form className={classes.form} onSubmit={handleInitSubmit}>
                <ZshTextField
                  margin="normal"
                  required
                  fullWidth
                  label="メールアドレス"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={formData.email}
                  onChange={handleChange}
                  validateErrors={validateErrors}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  送信する
                </Button>
                <Grid container>
                  <Grid item xs className={classes.forgotPassword}>
                    <Link variant="body2" onClick={() => { setPasswordReset(false) }} className="password-reset-a">戻る</Link>
                  </Grid>
                </Grid>
              </form>
            </div>
          }
          {
            step === 'sended' &&
            <div id="password-reset-sended">
              <Typography component="h1" variant="h5" className="login-h">パスワード再設定</Typography>
              <Typography variant="body1" gutterBottom className={classes.sendedBody1}>メール送信完了</Typography>
              <Typography variant="body2" gutterBottom className="prs-p1">
                {formData.email}宛にメールを送信しました。
              </Typography>
              <Typography variant="body2" gutterBottom className="prs-p1">
                メールに記載されているパスワード再設定用URLをクリックして、パスワードの再設定をしください。
              </Typography>
              <Typography variant="body2" gutterBottom className="prs-p1">
                送信したパスワード再設定用URLは<span className="red">24時間後まで有効です。</span><br />24時間以上経過してしまったり、メールが届かない場合は、再度パスワード再設定画面で操作を行ってください。
              </Typography>
              <Grid container>
                <Grid item xs className={classes.forgotPassword}>
                  <Link variant="body2" onClick={handleBackLogin} className="password-reset-a">トップページに戻る</Link>
                </Grid>
              </Grid>
            </div>
          }
          {
            step === 'allowUuid' &&
            <div>
              <Typography component="h1" variant="h5" className="login-h">パスワード再設定</Typography>
              <Typography variant="body1" gutterBottom className={classes.sendedBody1}>パスワード変更</Typography>
              <Typography variant="body2" gutterBottom>新しいパスワードを入力してください。</Typography>
              <form className={classes.form} onSubmit={handleAllowUuidSubmit}>
                <ZshTextField
                  margin="normal"
                  required
                  fullWidth
                  label="新しいパスワード"
                  name="new_password"
                  type="password"
                  autoFocus
                  value={formData.new_password}
                  onChange={handleChange}
                  validateErrors={validateErrors}
                />
                <ZshTextField
                  margin="normal"
                  required
                  fullWidth
                  label="パスワードの確認"
                  name="new_password_confirm"
                  type="password"
                  value={formData.new_password_confirm}
                  onChange={handleChange}
                  validateErrors={validateErrors}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  送信する
                </Button>
              </form>
            </div>
          }
          {
            step === 'failUuid' &&
            <div>
              <Typography component="h1" variant="h5" className="login-h">パスワード再設定</Typography>
              <Typography variant="body1" gutterBottom className={classes.sendedBody1}>無効なURLです</Typography>
              <Grid container>
                <Grid item xs className={classes.forgotPassword}>
                  <Link variant="body2" onClick={handleBackLogin} className="password-reset-a">トップページに戻る</Link>
                </Grid>
              </Grid>
            </div>
          }
          {
            step === 'finished' &&
            <div>
              <Typography component="h1" variant="h5" className="login-h">パスワード再設定</Typography>
              <Typography variant="body1" gutterBottom className={classes.sendedBody1}>パスワードを変更しました。</Typography>
              <Grid container>
                <Grid item xs className={classes.forgotPassword}>
                  <Link variant="body2" onClick={handleBackLogin} className="password-reset-a">ログイン画面へ</Link>
                </Grid>
              </Grid>
            </div>
          }
        </div>
      </Container>
    </div>
  )
}

export default withRouter(UsersPasswordReset);