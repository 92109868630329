import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import DataTable, { DataTableRowsProps, DataTableSearchParamsProps } from '../../components/View/DataTable';
import { searchFilterProps } from '../../components/View/SearchFilter';

function ShMailLogsIndex({ history, location }: RouteComponentProps) {

  const clickCell = (callbackParams: any) => {
    history.push(location.pathname + '/' + callbackParams.data.id);
  }

  const rows: DataTableRowsProps[] = [
    {
      label: 'ID',
      value: 'id',
      sortField: 'ShMailLogs.id',
      align: 'left',
    },
    {
      label: '日時',
      value: 'created',
      filter: 'YMDHm',
      sortField: 'ShMailLogs.created',
      align: 'left',
    },
    {
      label: '状況',
      value: 'sent_status',
      align: 'left',
    },
    {
      label: '対象',
      value: 'target_name',
      align: 'left',
    },
    {
      label: '顧客名',
      value: 'customer_name',
      align: 'left',
    },
  ];

  const searchParams: DataTableSearchParamsProps[] = [
    {
      title: 'To',
      text: '部分一致',
    },
  ];

  const searchProps: searchFilterProps[] = [
    {
      key: 'search'
    },
    {
      key: 'status',
      title: '状況',
      type: 'select',
      options: [
        {
          text: '配信済み',
          value: 'succeed',
        },
        {
          text: '配信エラー',
          value: 'error',
        },
        {
          text: '対象外',
          value: 'excluded',
        },
      ]
    },
  ];

  return (
    <div className="content-1">
      <DataTable
        rows={rows}
        checkbox={true}
        dataKey='shMailLogs'
        jsonPath='sh-mail-logs/index/index.json'
        urlQuery={true}
        parentProps={{ history, location }}
        handleClickCell={clickCell}
        searchParams={searchParams}
        searchProps={searchProps}
      />
    </div>
  )
}

export default ShMailLogsIndex;