import React from 'react';
import { useRecoilValue } from 'recoil';
import { overlayProppress } from '../atoms/OverlayProgress';
import CircularProgress from '@material-ui/core/CircularProgress';

function OverlayProgress() {
  const show = useRecoilValue(overlayProppress);
  return(
    <div id="overlay-progress-wr">
      {
        show &&
        <div id="overlay-progress">
          <div id="overlay-progress-in">
            <CircularProgress />
          </div>
        </div>
      }
    </div>
  );
}

export default OverlayProgress;