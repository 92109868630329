import React from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { zshDialog } from '../atoms/ZshDialog';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function ZshDialog() {

  const zshDialogValue = useRecoilValue(zshDialog);
  const resetZshDialog = useResetRecoilState(zshDialog);

  const handleClose = () => {
    resetZshDialog();
  };

  const handleAgree = () => {
    if (zshDialogValue.funcAgree !== undefined && typeof zshDialogValue.funcAgree === 'function') {
      zshDialogValue.funcAgree();
    }
    handleClose();
  }

  let showMessage: any = '';

  if (zshDialogValue.message !== undefined) {
    if (zshDialogValue.message.indexOf('¥n') !== -1) {
      showMessage = zshDialogValue.message.split('¥n').map((line: string, index: number) => (
        <span key={index}>
          {line}<br />
        </span>
      ));
    } else {
      showMessage = zshDialogValue.message;
    }
  }

  const open: boolean = zshDialogValue.open === undefined ? false : zshDialogValue.open;
  const title: string = zshDialogValue.title === undefined ? '' : zshDialogValue.title;
  const closeText: string = zshDialogValue.closeText === undefined ? '' : zshDialogValue.closeText;
  const agreeText: string = zshDialogValue.agreeText === undefined ? '' : zshDialogValue.agreeText;

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <React.Fragment>
              {showMessage}
            </React.Fragment>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {closeText}
          </Button>
          <Button onClick={handleAgree} color="primary" autoFocus>
            {agreeText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ZshDialog;