import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { overlayProppress } from '../../atoms/OverlayProgress';
import { flashMessageSuccess, flashMessageError } from '../../atoms/FlashMessage';
import { zshDialog } from '../../atoms/ZshDialog';
import * as _usr_const from '../../config/usr-constant';
import * as _filter from '../../helper/filter';
import * as _form from '../../helper/form';
import * as _data_actions from '../../helper/dataActions';
import Paper from '@material-ui/core/Paper';
import DataTable, { DataTableBtnGroupProps } from '../../components/View/DataTable';
import { searchFilterProps } from '../../components/View/SearchFilter';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Notifications from '@material-ui/icons/Notifications';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import axios from 'axios';
import jaLocale from "date-fns/locale/ja";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import moment from 'moment';

type Props = {
  CommonParentId: number;
  CustomerId: number;
}

const initialData = {
  id: '',
  object_common_parent_id: '',
  customer_id: '',
  model_display: '',
  status_display: '',
  status: '',
  user_id: '',
  user_name: '',
  content: '',
  notice_datetime: null,
};


const initialFormDara = {
  id: '',
  object_common_parent_id: '',
  customer_id: '',
  status: '',
  user_id: '',
  content: '',
  notice_datetime: null,
  pickers: {
    notice_datetime: null,
  },
};

function Memos({ CommonParentId, CustomerId }: Props) {

  const setOverlayProppress = useSetRecoilState(overlayProppress);
  const setFlashMessageSuccess = useSetRecoilState(flashMessageSuccess);
  const setFlashMessageError = useSetRecoilState(flashMessageError);
  const setZshDialog = useSetRecoilState(zshDialog);

  const source = useRef(axios.CancelToken.source());
  const unmounted = useRef(false);

  const [data, setData] = useState(initialData);
  const [formData, setFormData] = useState(initialFormDara);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const [dataTableCallbackParams, setDataTableCallbackParams] = useState({
    getData: () => {},
    query: {},
  });
  const [userList, setUserList] = useState<{ [s: string]: string }>({});
  const [showNoticeDate, setShowNoticeDate] = useState(false);
  const [isInit, setInit] = useState(true);

  const clickCell = (callbackParams: any): void => {    
    setData(callbackParams.data);
    setDialogType('view');
    setDataTableCallbackParams({
      ...dataTableCallbackParams,
      ...callbackParams
    });
    setShowDialog(true);
  }

  const handleDelete = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, params: any): any => {
    event.stopPropagation();
    _data_actions.showConfirmDialog({
      message: '削除してよろしいですか？¥nこの操作は元に戻せません。',
      closeText: 'キャンセル',
      agreeText: '削除する',
      funcAgree: () => {
        handleDeletePost(params);
      },
      setZshDialog
    });
  }

  const handleDeletePost = (params: any) => {
    if (params.data !== undefined && params.data.id) {
      const selected = [params.data.id];
      _data_actions.deleteData({
        deleteUrl: 'memos/delete/',
        selected: selected,
        getData: params.getData,
        setOverlayProppress,
        setFlashMessageSuccess,
        setFlashMessageError
      });
    } else {
      setFlashMessageError('削除対象を取得できませんでした');
    }
  }

  const handleViewClose = (): void => {
    setShowDialog(false);
    setDialogType('');
    setFormData(initialFormDara);
    setShowNoticeDate(false);
    setData(initialData);
  }

  const handleEdit = (): void => {
    const showNoticeDate = data.notice_datetime ? true : false;
    const tmpformData = _form.setFormData(formData, data);
    setDialogType('edit');
    setShowNoticeDate(showNoticeDate);
    setFormData(tmpformData);
  }

  const handleAdd = (params: any): void => {
    let tmpData: any = Object.assign({}, data);
    tmpData['object_common_parent_id'] = CommonParentId;
    tmpData['customer_id'] = CustomerId;
    tmpData['status'] = 1;
    const tmpformData = _form.setFormData(formData, tmpData);
    setDialogType('add');
    setDataTableCallbackParams(params);
    setData(tmpData);
    setFormData(tmpformData);
    setShowDialog(true);
  }

  const handleShowNoticeDateToggle = (): void => {
    let tmpformData: any = Object.assign({}, formData);
    if (showNoticeDate) {
      tmpformData['notice_datetime'] = null;
      tmpformData['pickers']['notice_datetime'] = null;
      setShowNoticeDate(false);
      setFormData(tmpformData);
    } else {
      let setNoticeDatatime = moment().hour(0).minutes(0).second(0);
      tmpformData['notice_datetime'] = setNoticeDatatime.toISOString(true);
      tmpformData['pickers']['notice_datetime'] = setNoticeDatatime.toISOString(true);
      setShowNoticeDate(true);
      setFormData(tmpformData);
    }
  }

  const handleChange = (name: any) => (event: any): void => {
    const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    setFormData({ ...formData, [name]: value });
  };

  const handleDatePickerChange = (date: any, name: string): void => {
    const tmpFormData = _form.handleDatePickerChange(formData, date, name);
    setFormData(tmpFormData);
  }

  const formFinished = () => {
    setOverlayProppress(false);
    dataTableCallbackParams.getData();
    handleViewClose();
  }

  const handleEditSubmit = () => {
    _form.handleSubmit(
      {
        action: 'memos/edit/' + data.id,
        formData,
        callbackSuccess: formFinished,
        setOverlayProppress,
        setFlashMessageSuccess,
        setFlashMessageError
      },
    );
  }

  const handleAddSubmit = () => {
    _form.handleSubmit(
      {
        action: 'memos/add',
        formData,
        callbackSuccess: formFinished,
        setOverlayProppress,
        setFlashMessageSuccess,
        setFlashMessageError
      },
    );
  }

  const showTbodyRow = (params: any): any => {
    return (
      <div className="data-table-inline-content">
        <div className="data-table-inline-content-text">
          <div>【{params.data.model_display}メモ】</div>
          <div>{params.data.trim_content}</div>
          <div>&lt; {params.data.status_display} &gt;</div>
          {
            params.data.notice_datetime !== null &&
            <div>
              <span style={{ paddingRight: '3px' }}>
                <Notifications style={{ fontSize: 18 }} />
              </span>
              {_filter.ShFilter(params.data.notice_datetime, 'YMDHm')}
            </div>
          }
          {
            params.data.user_name !== '' &&
            <div>(担当：{params.data.user_name})</div>
          }
        </div>
        <div className="data-table-inline-btn-group">
          <IconButton aria-label="Delete" onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleDelete(event, params)}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </div>
      </div>
    );
  }

  const getUserLIst = useCallback(() => {
    axios
    .get(_usr_const.ApiUrl + 'memos/get-user-list/index.json', {
      cancelToken: source.current.token
    })
    .then((results: any) => {
      if (!unmounted.current) {
        setUserList(results.data.user_list);
      }
    });
  }, []);

  // clean up
  useEffect(() => {
    const clSource = Object.assign({}, source.current);
    return () => {
      // cancel axios get
      clSource.cancel('cancel memos get user list');
      unmounted.current = true;
    }
  }, []);

  useEffect(() => {
    if (isInit) {
      getUserLIst();
      setInit(false);
    }
  }, [isInit, getUserLIst]);

  const jsonPath: string = 'memos/index/' + CommonParentId + '/index.json';

  const searchProps: searchFilterProps[] = [
    {
      key: 'status',
      title: '状況',
      type: 'select',
      options: [
        {
          text: '未対応',
          value: 1,
        },
        {
          text: '完了',
          value: 10,
        },
      ],
    },
  ];

  const dialogContents: any = {
    'view': {
      title: 'メモ詳細',
      agreeText: '編集',
      agreeFunc: handleEdit,
      type: 'view',
    },
    'edit': {
      title: 'メモ編集',
      agreeText: '保存',
      agreeFunc: handleEditSubmit,
      type: 'form',
    },
    'add': {
      title: 'メモ追加',
      agreeText: '保存',
      agreeFunc: handleAddSubmit,
      type: 'form',
    },
  };
  const viewDialogType: string = dialogContents[dialogType] !== undefined ? dialogContents[dialogType].type : '';
  const dialogTitle: string = dialogContents[dialogType] !== undefined ? dialogContents[dialogType].title : '';
  const dialogAgreeText: string = dialogContents[dialogType] !== undefined ? dialogContents[dialogType].agreeText : '';
  const dialogAgreeFunc: () => void = dialogContents[dialogType] !== undefined ? dialogContents[dialogType].agreeFunc : () => {};

  const regex = /([\n])/g;
  const nl2br = (str: string) => {
    return str.split(regex).map((line: any, i: number) => {
      return line.match(regex) ? <br key={i} /> : line;
    });
  }

  const btnGroup: DataTableBtnGroupProps[] = [
    {
      title: '登録',
      func: handleAdd,
      color: 'primary',
    },
  ];

  const props: any = {
    CommonParentId,
    CustomerId
  };

  return (
    <Paper className="content-2">
      <h2 className="h-2">すべてのメモ</h2>
      <DataTable
        checkbox={false}
        dataKey='memos'
        jsonPath={jsonPath}
        urlQuery={false}
        searchProps={searchProps}
        parentProps={props}
        handleClickCell={clickCell}
        funcTbodyRow={showTbodyRow}
        btnGroup={btnGroup}
        filterGroupsInline={true}
        limit={5}
        size="small"
      />
        <Dialog
          fullWidth={true}
          open={showDialog}
          onClose={handleViewClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
          <DialogContent>
            {
              viewDialogType === 'view' &&
              <table className="table-1">
                <tbody>
                  <tr>
                    <th>種別</th>
                    <td>{data.model_display}</td>
                  </tr>
                  <tr>
                    <th>状況</th>
                    <td>{data.status_display}</td>
                  </tr>
                  <tr>
                    <th>通知日時</th>
                    <td>
                      {
                        data.notice_datetime !== '' &&
                        <span>
                          {_filter.ShFilter(data.notice_datetime, 'YMDHm')}
                        </span>
                      }
                    </td>
                  </tr>
                  <tr>
                    <th>担当</th>
                    <td>{data.user_name}</td>
                  </tr>
                  <tr>
                    <th>内容</th>
                    <td>{nl2br(data.content)}</td>
                  </tr>
                </tbody>
              </table>
            }
            {
              viewDialogType === 'form' &&
              <form className="form-content dialog-form">
                <div className="view-content-in form-content">
                  {
                    dialogType === 'edit' &&
                    <div className="form-input-group">
                      <FormControl>
                        <InputLabel htmlFor="status">状況</InputLabel>
                        <Select
                          value={formData.status}
                          onChange={handleChange('status')}
                          className="form-input prefecture"
                          inputProps={{
                            name: 'status',
                          }}
                        >
                          <MenuItem value=""></MenuItem>
                          <MenuItem value="1">未対応</MenuItem>
                          <MenuItem value="10">完了</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  }
                  <div className="form-input-group">
                    <FormControlLabel
                      control={
                        <Switch
                          checked={showNoticeDate}
                          onChange={handleShowNoticeDateToggle}
                          value="1"
                          color="primary"
                        />
                      }
                      label="通知"
                    />
                  </div>
                  {
                    showNoticeDate &&
                    <div className="form-input-group">
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={jaLocale}>
                        <DateTimePicker
                          label="通知"
                          value={formData.pickers.notice_datetime}
                          onChange={(date: any) => handleDatePickerChange(date, 'notice_datetime')}
                          format="yyyy/MM/dd HH:mm"
                          views={['date', 'hours']}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  }
                  <div className="form-input-group">
                    <FormControl>
                      <InputLabel htmlFor="status">担当</InputLabel>
                      <Select
                        value={formData.user_id}
                        onChange={handleChange('user_id')}
                        className="form-input prefecture"
                        inputProps={{
                          name: 'user_id',
                        }}
                      >
                        <MenuItem value=""></MenuItem>
                        {
                          userList !== {} &&
                          Object.keys(userList).map((key: string) => (
                            <MenuItem value={key} key={key}>{userList[key]}</MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                  </div>
                  <div className="form-input-group">
                    <TextField
                      label="内容"
                      value={formData.content}
                      onChange={handleChange('content')}
                      margin="normal"
                      className="form-input form-textarea"
                      multiline={true}
                      fullWidth
                    />
                  </div>
                </div>
              </form>
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={handleViewClose} color="default">
              閉じる
            </Button>
            <Button onClick={dialogAgreeFunc} color="primary" autoFocus>
              {dialogAgreeText}
            </Button>
          </DialogActions>
        </Dialog>
    </Paper>
  );
}

export default Memos;