import React, { useState, useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom'
import * as _usr_const from '../../config/usr-constant';
import * as _view_action from '../../helper/viewAction';
import axios from 'axios';
import Paper from '@material-ui/core/Paper';
import Loading from '../../components/View/Loading';
import HeadButtonGroups, { HeadButtonGroupBtnActionsProps } from '../../components/View/HeadButtonGroups';
import ZshLogs from '../../components/View/ZshLogs';
import ErrorView from '../../components/View/ErrorView';

type RoleDataProps = {
  id: string;
  name: string;
  role: any;
  common_parent: any;
}
function RolesView({ history, match }: RouteComponentProps<{ id: string }>) {

  const unmounted = useRef(false);
  const source = useRef(axios.CancelToken.source());

  const [isInit, setInit] = useState(true);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<RoleDataProps>({
    id: '',
    name: '',
    role: {},
    common_parent: {},
  });
  const [dataMounted, setDataMounted] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [errorStatus, setErrorStatus] = useState(0);
  const [roleActions, setRoleActions] = useState<{ [key: string]: string; }>({});
  const [roleNames, setRoleNames] = useState<{ [key: string]: string }>({});

  // clean up
  useEffect(() => {
    const clSource = Object.assign({}, source.current);
    return () => {
      // cancel axios get
      clSource.cancel('cancel role get');
      unmounted.current = true;
    }
  }, []);

  useEffect(() => {
    if (isInit) {
      setLoading(true);
      _view_action.getViewData<RoleDataProps>({
        unmounted,
        source,
        dataKey: 'role',
        path: _usr_const.ApiUrl + 'roles/view/' + match.params.id + '/index.json',
        setData,
        setDataMounted,
        setErrorStatus,
        setErrorMsg,
        setLoading,
        successCallbackBeforeSetData: (results) => {
          setRoleActions(results.data.role_actions);
          setRoleNames(results.data.role_names);
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
      setInit(false);
    }
  }, [isInit, match.params.id]);

  const btnActions: HeadButtonGroupBtnActionsProps[] = [
    {
      type: 'edit',
      func: (): void => {
        history.push('/roles/' + match.params.id + '/edit');
      }
    },
  ];

  let dataRole: any = {};
  if (data.role !== undefined) {
    dataRole = data.role;
  }
  const roleView: any = Object.keys(dataRole).map((role: string) => {
    let showRole = true;
    if (roleNames[role] === undefined) {
      showRole = false;
    }
    const RoleName: string = roleNames[role] !== undefined ? roleNames[role] : '';
    const RoleActions: any = dataRole[role].map((action: string) => {
      const ActionName: string = roleActions[action] ? roleActions[action] : '';
      return (
        <li key={role + action} className="mb5">{ActionName}</li>
      );
    });
    return (
      <div key={role}>
        {
          showRole &&
          <div>
            <div className="mb15 bold">{RoleName}</div>
            <ul className="mb10">
              {RoleActions}
            </ul>
          </div>
        }
      </div>
    );
  });

  return (
    <div className="content-1">
      <Loading loading={loading} />
      {
        dataMounted &&
        <div>
          <HeadButtonGroups
            history={history}
            btnActions={btnActions}
          />
          <Paper>
            <div className="view-content-in">
              <table className="table-1">
                <tbody>
                  <tr>
                    <th>ID</th>
                    <td>{data.id}</td>
                  </tr>
                  <tr>
                    <th>権限グループ名</th>
                    <td>{data.name}</td>
                  </tr>
                  <tr>
                    <th>権限設定</th>
                    <td>{roleView}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Paper>
          <ZshLogs logs={data.common_parent.logs} />
        </div>
      }
      {
        errorMsg !== '' &&
        <div>
          <ErrorView
            history={history}
            errorStatus={errorStatus}
            errorMsg={errorMsg}
          />
        </div>
      }
    </div>
  )
}

export default RolesView;