import axios from 'axios';
import * as _usr_const from '../config/usr-constant';
import * as _debug from '../helper/debug';

/**
 * deleteData
 * 
 */
type deleteDataProps = {
  deleteUrl: string;
  selected: any;
  getData: any;
  setOverlayProppress: any;
  setFlashMessageSuccess: any;
  setFlashMessageError: any;
}
export function deleteData({
  deleteUrl,
  selected,
  getData,
  setOverlayProppress,
  setFlashMessageSuccess,
  setFlashMessageError
}: deleteDataProps): void {
  if (selected.length > 0) {
    setOverlayProppress(true);
    axios
      .post(
        _usr_const.ApiUrl + deleteUrl,
        {
          ids: selected,
        },
      )
      .then(() => {
        getData();
        setFlashMessageSuccess('削除しました');
      })
      .catch((error) => {
        _debug.debugAxiosError(error);
        let setErrorMessage: boolean = false;
        if (error.response) {
          if (error.response.data.message !== undefined) {
            const errors: string[] = error.response.data.message;
            if (errors.length > 0) {
              setErrorMessage = true;
            }
            errors.forEach((er: string) => {
              setFlashMessageError(er);
            });
          }
        }
        if (setErrorMessage === false) {
          setFlashMessageError('削除できませんでした');
        }
      })
      .finally(() => {
        setOverlayProppress(false);
      });
  } else {
    setFlashMessageError('項目を選択してください');
  }
}

/**
 * downloadCsv
 */
type downloadCsvProps = {
  downloadUrl: string;
  fileName: any;
  source: any;
  setOverlayProppress: any;
  setFlashMessageError: any;
  queryParams?: any;
}
export function downloadCsv({
  downloadUrl,
  fileName,
  source,
  setOverlayProppress,
  setFlashMessageError,
  queryParams = {}
}: downloadCsvProps): void {
  setOverlayProppress(true);
  axios
    .get(_usr_const.ApiUrl + downloadUrl,
      {
      responseType: 'arraybuffer',
      cancelToken: source.current.token,
      params: queryParams,
      }
    )
    .then((results: any) => {
      const date = new Date();
      const y: string = String(date.getFullYear());
      const m: string = String(('0' + (date.getMonth() + 1)).slice(-2));
      const d: string = String(('0' + date.getDate()).slice(-2));
      const h: string = String(('0' + date.getHours()).slice(-2));
      const mi: string = String(('0' + date.getMinutes()).slice(-2));
      const s: string = String(('0' + date.getSeconds()).slice(-2));
      const dateValue: string = y + m + d + h + mi + s;
      var downLoadLink = document.createElement("a");
      downLoadLink.download = fileName + '_' + dateValue + '.csv';
      downLoadLink.href = URL.createObjectURL(new Blob([results.data], { type: "text/csv;charset=Shift_JIS;" }));
      downLoadLink.dataset.downloadurl = ["text/csv;charset=Shift_JIS;", downLoadLink.download, downLoadLink.href].join(":");
      downLoadLink.click();
    })
    .catch((error) => {
      _debug.debugAxiosError(error);
      setFlashMessageError('CSV出力できませんでした');
    })
    .finally(() => {
      setOverlayProppress(false);
    });
}

/**
 * showConfirmDialog
 */
type showConfirmDialogProps = {
  title?: string;
  dialogType?: string;
  message?: string;
  closeText?: string;
  agreeText?: string;
  funcAgree?: (() => void);
  setZshDialog: any;
}
export function showConfirmDialog({
  title,
  dialogType,
  message,
  closeText,
  agreeText,
  funcAgree,
  setZshDialog
}: showConfirmDialogProps) {
  if (dialogType !== undefined) {
    switch (dialogType) {
      case 'delete':
        if (message === undefined) {
          message = '選択した項目を削除します。¥n（関連するデータも全て削除されます）¥n¥nこの操作は元に戻せません。よろしいですか？';
        }
        closeText = 'キャンセル';
        agreeText = '削除する';
        break;
      default:
        break;
    }
  }
  setZshDialog({
    open: true,
    title,
    message,
    closeText,
    agreeText,
    funcAgree,
  });
}