import React, { useState, useEffect, useCallback, useRef, createRef } from 'react';
import { useSelector } from 'react-redux';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as _usr_const from '../../config/usr-constant';
import * as _filter from '../../helper/filter';
import * as _formatter from '../../helper/formatter';
import * as _debug from '../../helper/debug';
import axios from 'axios';
import queryString from 'query-string';
import SearchInput from './SearchInput';
import SearchFilters, { searchFilterProps } from './SearchFilter';
import Button from '@material-ui/core/Button';
import * as _roleHelper from '../../helper/role';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
    },
    table: {
      width: '100%',
    },
    tableWrapper: {
      overflowX: 'auto',
    },
    row: {
      '&:nth-of-type(odd)': {
        // backgroundColor: theme.palette.background.default,
      },
      cursor: 'pointer',
    },
  })
);

type HeadProps = {
  rows: any;
  checkbox?: boolean;
  numSelected?: any;
  rowCount?: any;
  onCheckAll?: any;
  order: string;
  orderBy: string;
  loading: boolean;
  onRequestSort: any
}

function DataTableHead({
  rows,
  checkbox,
  numSelected,
  rowCount,
  onCheckAll,
  order,
  orderBy,
  loading,
  onRequestSort
}: HeadProps) {

  const createSortHandler = (property: any) => (event: any) => {
    onRequestSort(event, property);
  };

  let rowsLength: number = 1;
  if (rows !== undefined) {
    rowsLength = checkbox ? rows.length + 1 : rows.length;
  }

  const loadingCellStyle: any = {
    borderBottom: 'none',
    padding: '0px',
  };

  let setOrder: any = order;

  return (
    <TableHead>
      {
        rows !== undefined &&
        <TableRow>
          {
            checkbox &&
            <TableCell padding="checkbox" className="data-table-checkAll">
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onCheckAll}
              />
            </TableCell>
          }
          {
            rows.map((row: DataTableRowsProps, index: number) => (
              <TableCell
                key={'thead-' + index}
                align={row.align}
                style={{ padding: '4px 15px' }}
              >
                {
                  row.sortField !== undefined &&
                  <TableSortLabel
                    active={orderBy === row.sortField}
                    direction={setOrder}
                    onClick={createSortHandler(row.sortField)}
                  >
                    {row.label}
                  </TableSortLabel>
                }
                {
                  row.sortField === undefined &&
                  <div>{row.label}</div>
                }
              </TableCell>
            ))
          }
        </TableRow>
      }
      {
        loading &&
        <TableRow className="data-table-head-loading-row">
          <TableCell
            colSpan={rowsLength}
            padding='default'
            style={loadingCellStyle}
            className="data-table-head-loading">
            <LinearProgress />
          </TableCell>
        </TableRow>
      }
    </TableHead>
  )
}

type handleClickCellProps = {
  data: any;
  getData: (() => void);
  query: any;
}

type funcTbodyRowProps = {
  data: any;
  getData: (() => void);
  query: any;
}

type DataTableBtnGroupFuncProps = {
  selected: any;
  getData: (() => void);
  query: any;
}

export type DataTableRowsProps = {
  label: string;
  value?: string;
  filter?: 'YMDHms' | 'YMDHm' | 'YMD' | 'YM' | 'number_format';
  sortField?: string;
  align?: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined;
  maxLength?: number;
  html?: boolean;
  node?: (({
    data,
    getData,
    query
  }: funcTbodyRowProps) => React.ReactNode) | undefined
}

export type DataTableBtnGroupProps = {
  title: string;
  func: ({
    selected,
    getData,
    query
  }: DataTableBtnGroupFuncProps) => void;
  color: 'inherit' | 'default' | 'primary' | 'secondary';
  role?: {
    name: string;
    action: 'view' | 'add' | 'edit' | 'delete' | 'csv_download' | 'csv_import';
  }
}

export type DataTableSearchParamsProps = {
  title: string;
  text: string;
}

type DataTableProps = {
  init?: boolean;
  rows?: DataTableRowsProps[];
  dataKey?: string;
  jsonPath?: string;
  urlQuery?: boolean;
  parentProps?: any;
  checkbox?: boolean;
  handleClickCell?: ({
    data,
    getData,
    query
  }: handleClickCellProps) => void;
  searchParams?: DataTableSearchParamsProps[];
  searchProps?: searchFilterProps[];
  btnGroup?: DataTableBtnGroupProps[];
  funcTbodyRow?: ({
    data,
    getData,
    query
  }: funcTbodyRowProps) => React.ReactNode;
  filterGroupsInline?: boolean;
  limit?: number;
  size?: 'small' | 'medium' | undefined;
  defaultQueryValues?: {[key: string]: any} | undefined;
}

function DataTable({
  init = true,
  rows,
  dataKey,
  jsonPath,
  urlQuery,
  parentProps,
  checkbox,
  handleClickCell,
  searchParams,
  searchProps,
  btnGroup,
  funcTbodyRow,
  filterGroupsInline,
  limit,
  size,
  defaultQueryValues = {}
}: DataTableProps) {

  const defaultLimit = limit === undefined ? 10 : limit;
  const isSize = size === undefined ? 'medium' : size;

  const unmounted = useRef(false);
  const source = useRef(axios.CancelToken.source());
  const refParentProps = useRef(parentProps);
  const refSearchProps = useRef(searchProps);

  const AuthRoles: any = useSelector(state => state.AuthRoles);

  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState<any>([]);
  const [paginate, setPaginate] = useState({
    mounted: false,
    count: 0,
    prevPage: false,
    nextPage: true,
    start: 0,
    end: 0,
  });
  const [domRectTop, setDomRectTop] = useState<number>(0);
  const [parentDomRect, setParentDomRect] = useState<any>([]);
  const [searchValues, setSearchValues] = useState<any>({});
  const [searchInput, setSearchInput] = useState<boolean>(false);
  const [searchFilters, setSearchFilters] = useState<any>([]);
  const [isInit, setInit] = useState(init);
  const [dataTableBtnGroup, setDataTableBtnGroup] = useState([]);
  const [queries, setQueries] = useState({
    limit: 10,
    page: 0,
    sort: '',
    direction: 'asc'
  });

  const dataTableRef: any = createRef();

  const setUrlQuery = useCallback((query: any) => {
    if (urlQuery === true) {
      let tmpQuery = Object.assign({}, query);
      if (tmpQuery.page !== undefined) {
        tmpQuery.page += 1;
      }
      const history = refParentProps.current.history === undefined ? '' : refParentProps.current.history;
      const pathname = refParentProps.current.location === undefined ? '' : refParentProps.current.location.pathname;
      history.push({
        pathname: pathname,
        search: '?' + queryString.stringify(tmpQuery)
      });
    }
  }, [urlQuery]);

  const moveDomTop = useCallback(() => {
    if (window.scrollY > domRectTop) {
      window.scrollTo(0, parentDomRect.top)
    }
  }, [domRectTop, parentDomRect.top]);

  const getData = useCallback((setParams?: any) => {
    if (!unmounted.current) {
      setLoading(true);
    }
    let params = setParams === undefined ? Object.assign({}, queries) : setParams;
    const apiUrl: string = _usr_const.ApiUrl === undefined ? '' : _usr_const.ApiUrl;
    const dataKeySt: string = dataKey === undefined ? '' : dataKey;
    if (params.page !== undefined) {
      params.page = params.page + 1;
    }
    axios
      .get(apiUrl + jsonPath, {
        params: params,
        cancelToken: source.current.token
      })
      .then((results: any) => {
        const data = results.data[dataKeySt];
        if (data !== undefined && !unmounted.current) {
          setData(data);
          setPaginate({
            ...results.data.paginate,
            mounted: true
          });
        }
      })
      .catch((error) => {
        _debug.debugAxiosError(error);
      })
      .finally(() => {
        if (!unmounted.current) {
          setSelected([]);
          setLoading(false);
        }
      });
  }, [
    dataKey,
    jsonPath,
    unmounted,
    source,
    queries
  ]);

  const handleRequestSort = (event: any, property: any) => {
    if (unmounted.current) {
      return;
    }
    const sort = property;
    let direction: string = 'asc';
    if (queries.direction === 'asc') {
      direction = 'desc';
    }
    setQueries({
      ...queries,
      sort,
      direction
    });
  };

  const handleCheckAll = (event: any) => {
    if (unmounted.current) {
      return;
    }
    if (event.target.checked) {
      let newSelected: any = data.map((n: any) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleCheck = (id: any) => {
    if (unmounted.current) {
      return;
    }
    const selectedIndex = selected.indexOf(id);
    let newSelected: any = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: any, page: number) => {
    if (unmounted.current) {
      return;
    }
    if (event !== null) {
      setQueries({ ...queries, page });
    }
  };

  const handleChangeRowsPerPage = (event: any) => {
    if (unmounted.current) {
      return;
    }
    setQueries({
      ...queries,
      page: 0,
      limit: event.target.value
    });
  };

  const roothandleClickCell = (data: any) => {
    if (unmounted.current) {
      return;
    }
    if (handleClickCell) {
      handleClickCell({
        data,
        getData,
        query: queries,
      });
    }
  }

  const setSearch = useCallback((key: any, value: any) => {
    if (unmounted.current) {
      return;
    }
    setSearchValues({
      ...searchValues,
      [key]: value
    });
  }, [searchValues, unmounted, setSearchValues]);

  const onSearchSubmit = (event: any) => {
    if (unmounted.current) {
      return;
    }
    event.preventDefault();
    setQueries({
      ...queries,
      ...searchValues,
      page: 0
    });
  }

  const getPaginationPageNum = (): number => {
    if (paginate.mounted) {
      return queries.page;
    }
    return 0;
  }

  // set search input toggle and other search filters
  const initSetSearchInput = useCallback(() => {
    if (unmounted.current) {
      return;
    }
    let tmpSearchInput: boolean = false;
    let searchFilters = [];
    if (searchProps !== undefined) {
      for (let spIndex = 0; spIndex < searchProps.length; spIndex++) {
        if (searchProps[spIndex].key === 'search') {
          tmpSearchInput = true;
        } else {
          searchFilters.push(searchProps[spIndex]);
        }
      }
      setSearchInput(tmpSearchInput);
      setSearchFilters(searchFilters);
    }
  }, [searchProps, unmounted]);

  const getParentQueries = useCallback(() => {
    if (unmounted.current) {
      return {};
    }
    let params: any = {};
    if (urlQuery === true) {
      if (refParentProps.current.location.search !== undefined) {
        const parentDataParams = queryString.parse(refParentProps.current.location.search);
        if (parentDataParams.page !== undefined) {
          params['page'] = Number(parentDataParams.page) - 1;
        }
        if (parentDataParams.limit !== undefined) {
          params['limit'] = Number(parentDataParams.limit);
        }
        if (parentDataParams.direction !== undefined) {
          params['direction'] = parentDataParams.direction;
        }
        if (parentDataParams.sort !== undefined) {
          params['sort'] = parentDataParams.sort;
        }
        // set search values
        if (refSearchProps.current) {
          let parentSearchValues: any = {};
          refSearchProps.current.forEach((sp: any) => {
            if (parentDataParams[sp.key] !== undefined) {
              params[sp.key] = parentDataParams[sp.key];
              parentSearchValues[sp.key] = parentDataParams[sp.key];
            }
          });
          if (Object.keys(parentSearchValues).length > 0) {
            setSearchValues({
              ...searchValues,
              ...parentSearchValues
            });
          }
        }
        // set defaultLimit
        if (parentDataParams.limit === undefined) {
          params['limit'] = defaultLimit;
        }
      }
    }
    return params;
  }, [urlQuery, unmounted, searchValues, defaultLimit, setSearchValues]);

  const setDataTableBtnGroupFunc = useCallback(() => {
    let dataTableBtnGroup: any = [];
    if (btnGroup !== undefined) {
      btnGroup.forEach((element: any) => {
        if (element.role === undefined) {
          dataTableBtnGroup.push(element);
        } else {
          if (_roleHelper.checkRole(element.role, AuthRoles)) {
            dataTableBtnGroup.push(element);
          }
        }
      });
    }
    setDataTableBtnGroup(dataTableBtnGroup);
  }, [btnGroup, AuthRoles]);

  // clean up
  useEffect(() => {
    const clSource = Object.assign({}, source.current);
    return () => {
      // cancel axios get
      clSource.cancel('cancel dataTable get');
      unmounted.current = true;
    }
  }, []);

  // init
  useEffect(() => {
    if (isInit) {
      // set dom rect
      const el: any = dataTableRef.current;
      const domRect: any = el.getBoundingClientRect();

      // set parent dom rect
      const parentEl: any = el.parentNode;
      const parentDomRect: any = parentEl.getBoundingClientRect();

      // set search input toggle and other search filters
      initSetSearchInput();

      // set dafaulr queries
      let tmpQueries: any = {
        limit: defaultLimit,
        page: 0,
        sort: '',
        direction: 'asc'
      };
      if (refSearchProps.current !== undefined) {
        refSearchProps.current.forEach((sp: any) => {
          tmpQueries[sp.key] = '';
          if (searchValues[sp.key] !== undefined) {
            tmpQueries[sp.key] = searchValues[sp.key];
          }
        });
      }

      const parentQueries = getParentQueries();
      const tmpDefaultQueryValues = defaultQueryValues === undefined ? {} : defaultQueryValues;
      
      if (!unmounted.current) {
        setInit(false);
        setParentDomRect(parentDomRect);
        setDomRectTop(domRect.top);
        setDataTableBtnGroupFunc();
        setQueries({
          ...tmpQueries,
          ...tmpDefaultQueryValues,
          ...parentQueries
        });
        if (Object.keys(tmpDefaultQueryValues).length > 0) {
          setSearchValues({ ...tmpDefaultQueryValues });
        }
      }
    }
  },
    [
      isInit,
      dataTableRef,
      searchValues,
      parentProps,
      urlQuery,
      defaultLimit,
      defaultQueryValues,
      getData,
      initSetSearchInput,
      getParentQueries,
      setDataTableBtnGroupFunc
    ]);

  // get data when change queries
  useEffect(() => {
    if (isInit === false) {
      moveDomTop();
      getData();
      setUrlQuery(queries);
    }
  }, [
    isInit,
    queries,
    moveDomTop,
    getData,
    setUrlQuery
  ]);

  const classes = useStyles();

  const isSelected = (id: number) => {
    return selected.indexOf(id) !== -1;
  }

  const perPageOptions = [5, 10, 25, 50, 100];
  const filterGroupsClassName = filterGroupsInline ? 'filter-groups-inline' : 'filter-groups';

  // set show data type
  let showDataType: string = '';
  if (rows !== undefined) {
    showDataType = 'rows';
  }
  if (rows === undefined && funcTbodyRow !== undefined) {
    showDataType = 'func';
  }

  const cellData = (row: DataTableRowsProps, data: any) => {
    let value = _filter.ShFilter(data, row.filter);
    if (row.maxLength !== undefined) {
      value = value.substr(0, row.maxLength) + '...';
    }
    if (row.html) {
      return (
        <span dangerouslySetInnerHTML={{__html: value}}></span>
      );
    }
    return (
      <span>{value}</span>
    );
  }

  // set body data
  const bodyData = data.map((n: any) => {
    const dataId: number = n.id;
    const selected = isSelected(dataId);
    let trStyle = {};
    if (n.color_style !== undefined) {
      trStyle = _formatter.formatCssStyleToReactStyle(n.color_style);
    }
    return (
      <TableRow
        hover
        key={n.id}
        aria-checked={selected}
        tabIndex={-1}
        selected={selected}
        role="checkbox"
        className={classes.row}
      >
        {
          checkbox &&
          <TableCell padding="checkbox" style={trStyle}>
            <Checkbox
              checked={selected}
              onClick={event => handleCheck(n.id)}
            />
          </TableCell>
        }
        {
          showDataType === 'rows' && rows !== undefined &&
          rows.map((row: DataTableRowsProps, index: number) => (
            <TableCell
              align={row.align}
              key={'tbody-' + index}
              padding="default"
              onClick={(event: any) => roothandleClickCell(n)}
              style={trStyle}
            >
              <span className="data-table-sp-label">{row.label}</span>
              {
                row.value !== undefined &&
                <span>
                  {cellData(row, n[row.value])}
                </span>
              }
              {
                row.node !== undefined && row.value === undefined &&
                <span>
                  {row.node({
                    data: n,
                    getData: getData,
                    query: queries,
                  })}
                </span>
              }
            </TableCell>
          ))
        }
        {
          showDataType === 'func' && funcTbodyRow !== undefined &&
          <TableCell
            padding="default"
            onClick={(event: any) => roothandleClickCell(n)}
            style={trStyle}
          >
            {funcTbodyRow({
              data: n,
              getData: getData,
              query: queries,
            })}
          </TableCell>
        }
      </TableRow>
    )
  });

  return (
    <div className="data-table" ref={dataTableRef}>
      <form
        className={filterGroupsClassName}
        onSubmit={(event) => onSearchSubmit(event)}
        autoComplete="off"
      >
        {
          dataTableBtnGroup !== [] &&
          <div className="index-btn-group">
            {
              dataTableBtnGroup.map((element: any, index: number) => (
                <Button
                  variant="contained"
                  className="btn"
                  color={element.color}
                  onClick={() => {
                    element.func({
                      selected: selected,
                      getData: getData,
                      query: queries,
                    })
                  }}
                  key={"btnGroup-" + index}
                >
                  {element.title}
                </Button>
              ))
            }
          </div>
        }
        {
          searchInput &&
          <SearchInput
            infoParams={searchParams}
            setSearch={setSearch}
            searchValues={searchValues}
            onSearchSubmit={onSearchSubmit}
          />
        }
        {
          searchFilters.length > 0 &&
          <SearchFilters
            searchFilters={searchFilters}
            setSearch={setSearch}
            searchValues={searchValues}
            onSearchSubmit={onSearchSubmit}
            queries={queries}
          />
        }
      </form>
      <div className="data-table-head-info">
        <span>件数：</span>
        <span>{paginate.count}件</span>
        <span className="dt-hi-start">{paginate.start}</span>
        <span>-</span>
        <span className="dt-hi-end">{paginate.end}</span>
      </div>
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle" size={isSize}>
            <DataTableHead
              rows={rows}
              checkbox={checkbox}
              numSelected={selected.length}
              order={queries.direction}
              orderBy={queries.sort}
              onCheckAll={handleCheckAll}
              rowCount={data.length}
              loading={loading}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {bodyData}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={perPageOptions}
          component="div"
          count={paginate.count}
          rowsPerPage={queries.limit}
          page={getPaginationPageNum()}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="表示数"
        />
      </Paper>
    </div>
  )
}

export default DataTable;