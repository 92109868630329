import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import * as _roleHelper from '../../helper/role';

type Buttons = {
  type: string;
  func?: (() => void);
  role?: { [key: string]: string };
  btnType: string;
  text: string;
  color: string;
}

type HeadButtonGroupBtnActionRoleProps = {
  name: string;
  action: string;
}

export type HeadButtonGroupBtnActionsProps = {
  type?: string;
  text?: string;
  color?: string;
  func?: any;
  role?: HeadButtonGroupBtnActionRoleProps;
}

type Props = {
  history: any;
  btnActions?: HeadButtonGroupBtnActionsProps[];
  hideBack?: boolean;
}

function HeadButtonGroups({ history, btnActions, hideBack = false }: Props) {

  const AuthRoles = useSelector(state => state.AuthRoles);

  const [buttons, setButtons] = useState<Buttons[]>([]);

  const historyBack = () => {
    history.goBack();
  }

  useEffect(() => {
    let buttons: Buttons[] = [];
    if (btnActions !== undefined) {
      btnActions.forEach((element: any) => {
        // set text and color and btnType
        element.btnType = 'default';
        if (element.type !== undefined) {
          switch (element.type) {

            case 'add':
              element.text = '追加';
              element.color = 'primary';
              break;

            case 'edit':
              element.text = '編集';
              element.color = 'primary';
              break;

            case 'save':
              element.text = '保存';
              element.color = 'primary';
              element.btnType = 'submit';
              break;

            default:
              break;
          }
        }
        if (element.color === undefined) {
          element.color = 'default';
        }
        if (element.text === undefined) {
          element.text = '';
        }
        // check role
        if (element.role === undefined) {
          buttons.push(element);
        } else {
          if (_roleHelper.checkRole(element.role, AuthRoles)) {
            buttons.push(element);
          }
        }
      });
    }
    setButtons(buttons);
  }, [AuthRoles, btnActions]);

  return (
    <div className="btn-group">
      {
        hideBack === false &&
        <span>
          <Button variant="contained" className="btn" onClick={historyBack}>戻る</Button>
        </span>
      }
      {
        buttons.map((action: any, index: number) => (
          <span key={index}>
            {
              action.btnType === 'default' &&
              <Button
                variant="contained"
                color={action.color}
                className="btn"
                onClick={action.func}
              >
                {action.text}
              </Button>
            }
            {
              action.btnType === 'submit' &&
              <Button
                type="submit"
                variant="contained"
                color={action.color}
                className="btn"
              >
                {action.text}
              </Button>
            }
          </span>
        ))
      }
    </div>
  )
}

export default HeadButtonGroups;