/**
 * checkRole
 * 
 * [string] role.name
 * [string] role.action
 * AuthRoles is reducer AuthRoles
 */
export function checkRole(role: { name: string; action: string }, AuthRoles: any): boolean {
  if (role.name === undefined || role.action === undefined) {
    return false;
  }
  if (
    AuthRoles === undefined ||
    AuthRoles === '' ||
    AuthRoles === null ||
    AuthRoles.length === 0
    ) {
    return false;
  }
  let result: boolean = false;
  let roles: any = {};
  if (AuthRoles.length > 0) {
    AuthRoles.forEach((element: any) => {
      if (element.isLoded) {
        roles = element.roles;
      }
    });
    if (roles === undefined) {
      return result;
    }
    if (typeof roles[role.name] !== 'undefined') {
      if (roles[role.name].indexOf(role.action) !== -1) {
        result = true;
      }
    }
  }
  return result;
}